import React, { Component } from 'react';
import PropTypes from 'prop-types';
import zipObject from 'lodash/zipObject';
import RegionFlag from 'common/pocket-casts-components/RegionFlag';
import Button from 'common/pocket-casts-components/Button';
import Checkbox from 'common/pocket-casts-components/Checkbox';
import { REGION_CODE, REGIONS } from 'app/state/constants';
import Link from 'common/pocket-casts-components/Link';
import {
    Overlay,
    VerticallyCenter,
    DialogWrapper,
    ButtonsWrapper,
    RegionGrid,
    RegionWrapper,
    CheckboxWrapper,
    SelectionLinkWrapper,
} from './styled';

export class ListPublishDialog extends Component {
    static propTypes = {
        regionsPrePublish: PropTypes.any.isRequired,

        onCancel: PropTypes.func.isRequired,
        onPublish: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        // The dreaded tri-state:
        // true => Selected
        // false => Unselected
        // null => Whatever the prop `regionsPrePublish` indicates
        // (i.e. true if regionsPrePublish contains the region, false if it does not).
        const allRegions = zipObject(REGIONS, REGIONS.map(() => null));

        this.state = {
            selectionOverrides: {
                ...allRegions,
            },
        };
    }

    isChecked = region => {
        const { regionsPrePublish } = this.props;
        const { selectionOverrides } = this.state;

        return selectionOverrides[region] !== null
            ? selectionOverrides[region]
            : regionsPrePublish.includes(region);
    };

    uncheck = region => {
        this.setState({
            selectionOverrides: {
                ...this.state.selectionOverrides,
                [`${region}`]: false,
            },
        });
    };

    check = region => {
        this.setState({
            selectionOverrides: {
                ...this.state.selectionOverrides,
                [`${region}`]: true,
            },
        });
    };

    toggle = region => {
        if (this.isChecked(region)) {
            this.uncheck(region);
        } else {
            this.check(region);
        }
    };

    selectAll = () => {
        this.setState({
            selectionOverrides: {
                ...zipObject(REGIONS, REGIONS.map(() => true)),
            },
        });
    };

    unselectAll = () => {
        this.setState({
            selectionOverrides: {
                ...zipObject(REGIONS, REGIONS.map(() => false)),
            },
        });
    };

    resetSelection = () => {
        this.setState({
            selectionOverrides: {
                ...zipObject(REGIONS, REGIONS.map(() => null)),
            },
        });
    };

    getSelectedRegions = () => {
        const { regionsPrePublish } = this.props;

        const regionsToAdd = Object.keys(this.state.selectionOverrides).filter(
            region => this.state.selectionOverrides[region] === true,
        );

        const regionsToRemove = Object.keys(this.state.selectionOverrides).filter(
            region => this.state.selectionOverrides[region] === false,
        );

        return Array.from(
            new Set(
                regionsPrePublish
                    .concat(regionsToAdd)
                    .filter(region => !regionsToRemove.includes(region)),
            ),
        ).sort((a, b) => {
            if (a === REGION_CODE.global) {
                return 1;
            }
            if (b === REGION_CODE.global) {
                return -1;
            }
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }

            return 0;
        });
    };

    publishFlow = () => {
        this.props.onPublish(this.getSelectedRegions());
    };

    render() {
        return (
            <Overlay>
                <VerticallyCenter>
                    <DialogWrapper>
                        <ButtonsWrapper>
                            <Button onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={this.publishFlow}>Publish</Button>
                        </ButtonsWrapper>
                        <RegionGrid>
                            {REGIONS.map(region => (
                                <RegionWrapper key={region}>
                                    <CheckboxWrapper>
                                        <Checkbox
                                            checked={this.isChecked(region)}
                                            onChange={() => this.toggle(region)}
                                        />
                                    </CheckboxWrapper>
                                    <RegionFlag
                                        key={`${region}-flag`}
                                        region={region}
                                        onClick={() => this.toggle(region)}
                                    />
                                </RegionWrapper>
                            ))}
                            <SelectionLinkWrapper>
                                <Link onClick={this.selectAll}>Publish All</Link>
                            </SelectionLinkWrapper>
                            <SelectionLinkWrapper>
                                <Link onClick={this.unselectAll}>Unpublish All</Link>
                            </SelectionLinkWrapper>
                            <SelectionLinkWrapper>
                                <Link onClick={this.resetSelection}>Reset</Link>
                            </SelectionLinkWrapper>
                        </RegionGrid>
                    </DialogWrapper>
                </VerticallyCenter>
            </Overlay>
        );
    }
}

export default ListPublishDialog;
