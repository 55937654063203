import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SINGLE_REGION_MODE } from 'common/feature-flags/index';
import { ListItemCount } from 'common/format';
import Link from 'common/pocket-casts-components/Link/index';
import RegionRow from './RegionRow/index';
import {
    PodcastListTable,
    BasicCellText,
    GreyCellText,
    OrangeCellText,
    Th,
    ThText,
    Tr,
    TrHeader,
    Td,
    LastTd,
    ArchivedArea,
    ActionWrapper,
    ArchiveUnarchiveWrapper,
} from './styled';
import ArchiveIcon from './archive-icon.svg';
import UnarchiveIcon from './unarchive-icon.svg';
import { MONTH_MAP } from '../../../../../../../state/constants';

export class Table extends Component {
    static propTypes = {
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        sortedLists: PropTypes.arrayOf(PropTypes.object).isRequired,
        // sortedByField: PropTypes.string.isRequired,
        // sortedAscending: PropTypes.bool.isRequired,
        showsArchived: PropTypes.bool.isRequired,
        toggleShowArchive: PropTypes.func.isRequired,
    };

    archive = list => {
        this.props.archiveList(list.id);
    };

    unarchive = list => {
        this.props.unarchiveList(list.id);
    };

    edit = id => {
        this.props.history.push(`${this.props.match.url}/edit/${id}`);
    };

    render() {
        const { filter, sortedLists, showsArchived, toggleShowArchive } = this.props;

        const showHidePrefix = showsArchived ? 'Hide' : 'Show';

        return (
            <div>
                <PodcastListTable>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        <col />
                        {!SINGLE_REGION_MODE && <col />}
                        <col />
                        <col />
                    </colgroup>
                    <tbody>
                        <TrHeader>
                            <Th>
                                <ThText>List Name</ThText>
                            </Th>
                            <Th>
                                <ThText>Items</ThText>
                            </Th>
                            <Th>
                                <ThText>Sponsored</ThText>
                            </Th>
                            <Th>
                                <ThText>Created</ThText>
                            </Th>
                            <Th>
                                <ThText>Published</ThText>
                            </Th>
                            <Th>
                                <ThText>Until</ThText>
                            </Th>
                            <Th>
                                <ThText>Creator</ThText>
                            </Th>
                            {!SINGLE_REGION_MODE && (
                                <Th>
                                    <ThText>Regions</ThText>
                                </Th>
                            )}
                            <Th />
                        </TrHeader>
                        {sortedLists.map((list, index) => {
                            if (!showsArchived && list.archived) {
                                return null;
                            }

                            if (filter) {
                                const lcFilter = filter.toLowerCase();
                                if (
                                    !list.title.toLowerCase().includes(lcFilter) &&
                                    !list.creator.toLowerCase().includes(lcFilter)
                                ) {
                                    return null;
                                }
                            }

                            const createdDate = new Date(list.createdDate);

                            // eslint-disable-next-line
                            const createdDateString = `${createdDate.getDate()} ${
                                MONTH_MAP[createdDate.getMonth()]
                            } ${createdDate.getFullYear()}`;

                            const publishDate = list.publishDate && new Date(list.publishDate);

                            // eslint-disable-next-line
                            const publishDateString =
                                publishDate &&
                                `${publishDate.getDate() || '.'} ${MONTH_MAP[
                                    publishDate.getMonth()
                                ] || '.'} ${publishDate.getFullYear() || '.'}`;

                            const unpublishDate =
                                list.unpublishDate && new Date(list.unpublishDate);

                            // eslint-disable-next-line
                            const unpublishDateString =
                                unpublishDate &&
                                `${unpublishDate.getDate() || '.'} ${MONTH_MAP[
                                    unpublishDate.getMonth()
                                ] || '.'} ${unpublishDate.getFullYear() || '.'}`;

                            return (
                                <Tr key={`tr-${index}`}>
                                    <Td
                                        key={`podcast-name-${index}`}
                                        onClick={() => this.edit(list.id)}
                                    >
                                        <BasicCellText>{list.title}</BasicCellText>
                                    </Td>
                                    <Td
                                        key={`podcast-count-${index}`}
                                        onClick={() => this.edit(list.id)}
                                    >
                                        <BasicCellText>
                                            <ListItemCount
                                                count={list.itemCount || list.podcastCount}
                                                listType={list.listType}
                                            />
                                        </BasicCellText>
                                    </Td>
                                    <Td
                                        key={`sponsored-${index}`}
                                        onClick={() => this.edit(list.id)}
                                    >
                                        {list.sponsored ? (
                                            <OrangeCellText>Yes</OrangeCellText>
                                        ) : (
                                            <GreyCellText>No</GreyCellText>
                                        )}
                                    </Td>
                                    <Td key={`created-${index}`} onClick={() => this.edit(list.id)}>
                                        {createdDateString ? (
                                            <BasicCellText>{createdDateString}</BasicCellText>
                                        ) : (
                                            <GreyCellText>N/A</GreyCellText>
                                        )}
                                    </Td>
                                    <Td
                                        key={`published-${index}`}
                                        onClick={() => this.edit(list.id)}
                                    >
                                        {publishDateString ? (
                                            <BasicCellText>{publishDateString}</BasicCellText>
                                        ) : (
                                            <GreyCellText>Unpublished</GreyCellText>
                                        )}
                                    </Td>
                                    <Td key={`until-${index}`} onClick={() => this.edit(list.id)}>
                                        {unpublishDateString ? (
                                            <BasicCellText>{unpublishDateString}</BasicCellText>
                                        ) : (
                                            <GreyCellText>N/A</GreyCellText>
                                        )}
                                    </Td>
                                    <Td key={`creator-${index}`} onClick={() => this.edit(list.id)}>
                                        <BasicCellText>{list.creator}</BasicCellText>
                                    </Td>
                                    {!SINGLE_REGION_MODE && (
                                        <Td key={`td7-${index}`} onClick={() => this.edit(list.id)}>
                                            <RegionRow regions={list.regions} />
                                        </Td>
                                    )}
                                    <LastTd key={`td8-${index}`} onClick={() => {}}>
                                        <ActionWrapper>
                                            <ArchiveUnarchiveWrapper
                                                title={list.archived ? 'Unarchive' : 'Archive'}
                                                onClick={
                                                    list.archived
                                                        ? () => this.unarchive(list)
                                                        : () => this.archive(list)
                                                }
                                            >
                                                <img
                                                    width="14px"
                                                    height="14px"
                                                    src={
                                                        list.archived ? UnarchiveIcon : ArchiveIcon
                                                    }
                                                />
                                            </ArchiveUnarchiveWrapper>
                                        </ActionWrapper>
                                    </LastTd>
                                </Tr>
                            );
                        })}
                    </tbody>
                </PodcastListTable>
                <ArchivedArea>
                    <Link onClick={toggleShowArchive}>
                        {`${showHidePrefix} archived lists (${
                            sortedLists.filter(list => list.archived).length
                        })`}
                    </Link>
                </ArchivedArea>
            </div>
        );
    }
}

export default withRouter(Table);
