import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';
import { DetailWrapper } from './styled';
import RegionLayouts from './RegionLayouts';
import Lists from './Lists';

export class Detail extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    render() {
        const { match } = this.props;
        return (
            <DetailWrapper id="Discover-Curator-Detail-Wrapper">
                <Switch>
                    <Route path={`${match.url}/lists`} component={Lists} />
                    <Route path={`${match.url}/layouts`} component={RegionLayouts} />

                    <Route
                        path={`${match.url}`}
                        render={() => <Redirect to={`${match.url}/layouts`} />}
                    />
                </Switch>
            </DetailWrapper>
        );
    }
}

export default Detail;
