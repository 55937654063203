import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ContentBlockWrapper,
    LockIconWrapper,
    GrabbyIconWrapper,
    ChevronIconWrapper,
    DetailsWrapper,
    InfoWrapper,
    TitleWrapper,
    SponsoredWrapper,
    ListIconWrapper,
    ActionsWrapper,
    PinButtonWrapper,
    DeleteButtonWrapper,
} from './styled';
import FeaturedIcon from './FeaturedIcon';
import JustYourAverageListIcon from './ListIcon';
import PopularIcon from './PopularIcon';
import TrendingIcon from './TrendingIcon';
import AddedIcon from './AddedIcon';
import DeletedIcon from './DeletedIcon';
import DeleteIcon from './DeleteIcon';
import LockIcon from './lock.svg';
import GrabbyIcon from './grabby.svg';
import PinEmptyIcon from './pin-empty.svg';
import PinBlueIcon from './pin-blue.svg';
import ChevronIcon from './chevron-right.svg';
import {
    DIFF_ATTRIBUTES,
    FEATURED_LIST_ID,
    POPULAR_LIST_ID,
    RESERVED_LIST_IDS,
    TRENDING_LIST_ID,
} from '../../../../../../../state/constants';
import { NavigationItems } from '../../../../../Navigation/model';

export class ContentBlock extends Component {
    static propTypes = {
        listId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        editable: PropTypes.bool.isRequired,
        locked: PropTypes.bool.isRequired,
        pinned: PropTypes.bool,
        diff: PropTypes.string,

        removeContentBlock: PropTypes.func.isRequired,
        pinUnpinContentBlock: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            hovering: false,
        };
    }

    onMouseEnter = () => {
        this.setState({
            hovering: true,
        });
    };

    onMouseLeave = () => {
        this.setState({
            hovering: false,
        });
    };

    onChevronClick = () => {
        const { listId } = this.props;
        this.props.history.push(`${NavigationItems.LISTS.path}/manage/stats/${listId}`);
    };

    renderListIcon() {
        const { listId, diff } = this.props;

        if (diff === DIFF_ATTRIBUTES.added) {
            return <AddedIcon />;
        }

        if (diff === DIFF_ATTRIBUTES.deleted) {
            return <DeletedIcon />;
        }

        if (listId === FEATURED_LIST_ID) {
            return <FeaturedIcon />;
        }

        if (listId === POPULAR_LIST_ID) {
            return <PopularIcon />;
        }

        if (listId === TRENDING_LIST_ID) {
            return <TrendingIcon />;
        }

        return <JustYourAverageListIcon />;
    }

    renderPinIfNecessary() {
        const { listId, pinned, locked, diff } = this.props;

        if (locked || diff === DIFF_ATTRIBUTES.deleted) {
            return null;
        }

        if (pinned) {
            return (
                <PinButtonWrapper onClick={() => this.props.pinUnpinContentBlock(listId, false)}>
                    <img width={24} height={24} src={PinBlueIcon} />
                </PinButtonWrapper>
            );
        }

        return (
            <PinButtonWrapper onClick={() => this.props.pinUnpinContentBlock(listId, true)}>
                <img width={24} height={24} src={PinEmptyIcon} />
            </PinButtonWrapper>
        );
    }

    renderChevronIfNecessary() {
        const { listId } = this.props;

        if (!RESERVED_LIST_IDS.includes(listId)) {
            return (
                <ChevronIconWrapper onClick={this.onChevronClick}>
                    <img width={24} height={24} src={ChevronIcon} />
                </ChevronIconWrapper>
            );
        }

        return null;
    }

    renderDeleteOrRestore() {
        const { listId, locked, editable, diff } = this.props;

        if (!locked && editable) {
            if (diff === DIFF_ATTRIBUTES.deleted) {
                return <div />;
            }

            return (
                <DeleteButtonWrapper onClick={() => this.props.removeContentBlock(listId)}>
                    <DeleteIcon />
                </DeleteButtonWrapper>
            );
        }

        return null;
    }

    render() {
        const { title, locked, editable, pinned, sponsored, diff } = this.props;

        return (
            <ContentBlockWrapper
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                locked={locked}
                pinned={pinned}
                editable={editable}
                diff={diff}
            >
                {locked && (
                    <LockIconWrapper>
                        <img width={9} height={18} src={LockIcon} />
                    </LockIconWrapper>
                )}
                {!locked && diff !== DIFF_ATTRIBUTES.deleted && (
                    <GrabbyIconWrapper>
                        <img width={9} height={27} src={GrabbyIcon} />
                    </GrabbyIconWrapper>
                )}
                {!locked && diff === DIFF_ATTRIBUTES.deleted && <GrabbyIconWrapper />}
                <DetailsWrapper locked={locked} pinned={pinned}>
                    <InfoWrapper>
                        <ListIconWrapper faded={locked || pinned || !editable}>
                            {this.renderListIcon()}
                        </ListIconWrapper>
                        <TitleWrapper>{title}</TitleWrapper>
                    </InfoWrapper>
                    <ActionsWrapper>
                        {sponsored && <SponsoredWrapper>Sponsored</SponsoredWrapper>}
                        {this.renderPinIfNecessary()}
                        {this.renderDeleteOrRestore()}
                        {this.renderChevronIfNecessary()}
                    </ActionsWrapper>
                </DetailsWrapper>
            </ContentBlockWrapper>
        );
    }
}

export default ContentBlock;
