import { red50, orange50, yellow50, green50, blue50, purple60, pink50, coolGrey50 } from './colors';
import { setCurrentFilterColor, setCurrentPodcastColor } from './internal';
import { LIGHT_THEME } from './theme-light';
import { DARK_THEME } from './theme-dark';

/*
 * These IDs must align with the server-side ID expected for each theme.
 *
 * Add more themes here as they are implemented.
 */
export const THEME = {
    light: 0,
    dark: 1,
    extraDark: 2,
    classicLight: 3,
    classicDark: 4,
    electricity: 5,
    indigo: 6,
};

/*
 * This function provides direct access to theme objects outside of a styled component.
 * It is required for populating the top-level ThemeProvider object, but other than that
 * there should not be many reasons to depend on this function. Currently the only other
 * valid use case is adjustments to podcast colors based on theme.
 *
 * Add more themes here as they are implemented.
 */
export const getThemeFromId = id => {
    switch (id) {
        default:
        case THEME.light:
            return LIGHT_THEME;
        case THEME.dark:
            return DARK_THEME;
    }
};

export const setFilterIdForCurrentTheme = id => {
    switch (id) {
        default:
        case 1:
            setCurrentFilterColor(red50);
            break;
        case 2:
            setCurrentFilterColor(orange50);
            break;
        case 3:
            setCurrentFilterColor(yellow50);
            break;
        case 4:
            setCurrentFilterColor(green50);
            break;
        case 5:
            setCurrentFilterColor(blue50);
            break;
        case 6:
            setCurrentFilterColor(purple60);
            break;
        case 7:
            setCurrentFilterColor(pink50);
            break;
        case 8:
            setCurrentFilterColor(coolGrey50);
            break;
    }
};

export const setPodcastColorForCurrentTheme = color => {
    setCurrentPodcastColor(color);
};
