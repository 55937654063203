import React from 'react';

export const layoutsIcon = size => (
    <svg width={size} height={size} viewBox="0 1 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.5759 17.4792 14.0302 16.6004 15.2001C16.6376 15.2281 16.6733 15.259 16.7071 15.2929L20.2478 18.8336C20.6383 19.2241 20.6383 19.8573 20.2478 20.2478C19.8573 20.6383 19.2241 20.6383 18.8336 20.2478L15.2929 16.7071C15.259 16.6733 15.2281 16.6376 15.2001 16.6004C14.0302 17.4792 12.5759 18 11 18C7.13401 18 4 14.866 4 11ZM11 6C13.7614 6 16 8.23858 16 11C16 13.7614 13.7614 16 11 16C8.23858 16 6 13.7614 6 11C6 8.23858 8.23858 6 11 6Z"
            fill="currentColor"
        />
        <path
            opacity="0.3"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11Z"
            fill="currentColor"
        />
    </svg>
);

export const featuredIcon = size => (
    <svg width={size} height={size} viewBox="0 2 22 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5831 17.4776L8.07384 19.2933C7.09413 19.8002 6.44681 19.3197 6.62466 18.2404L7.26707 14.3418L4.45581 11.5654C3.67097 10.7903 3.92791 10.0262 5.00935 9.86177L8.91565 9.26801L10.6875 5.73638C11.1821 4.75042 11.9882 4.75867 12.4788 5.73638L14.2506 9.26801L18.1569 9.86177C19.2474 10.0275 19.4887 10.7968 18.7104 11.5654L15.8992 14.3418L16.5416 18.2404C16.7209 19.3288 16.0639 19.796 15.0924 19.2933L11.5831 17.4776Z"
            fill="currentColor"
        />
    </svg>
);

export const podcastListsIcon = size => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <rect x="12" y="4" width="8" height="2" rx="1" fill="currentColor" />
        <rect opacity="0.6" x="12" y="8" width="6" height="2" rx="1" fill="currentColor" />
        <rect x="12" y="14" width="8" height="2" rx="1" fill="currentColor" />
        <rect opacity="0.6" x="12" y="18" width="6" height="2" rx="1" fill="currentColor" />
        <rect x="4" y="4" width="6" height="6" rx="1" fill="currentColor" />
        <rect x="4" y="14" width="6" height="6" rx="1" fill="currentColor" />
    </svg>
);

export const filterIcon = size => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 4H20C20.5523 4 21 4.44772 21 5C21 5.55228 20.5523 6 20 6H4C3.44772 6 3 5.55228 3 5C3 4.44772 3.44772 4 4 4ZM7 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11H7C6.44772 11 6 10.5523 6 10C6 9.44772 6.44772 9 7 9ZM10 14H14C14.5523 14 15 14.4477 15 15C15 15.5523 14.5523 16 14 16H10C9.44772 16 9 15.5523 9 15C9 14.4477 9.44772 14 10 14Z"
            fill="currentColor"
        />
    </svg>
);

export const notchIcon = color => (
    <svg width="8" height="46" viewBox="1 0 8 54">
        <path
            fill={color}
            d="M0,0 C0,0 4.14113188e-13,2 3,2 C4.75181535,2 5,2 5,2 L5.00860596,2 C6.66070727,2 8,3.34205759 8,4.99139404 L8,5 L8,49 L8,49.008606 C8,50.6607073 6.65794241,52 5.00860596,52 L5,52 C5,52 5,52 3,52 C1,52 0,54 0,54 L0,0 Z"
        />
    </svg>
);
