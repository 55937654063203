import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        name: '',
        value: '',
        checked: false,
        disabled: false,
        onChange: () => {},
    };

    render() {
        const { name, value, checked, disabled, onChange } = this.props;

        return (
            <input
                type="checkbox"
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
        );
    }
}
