import { freeze, chain } from 'icepick';

import {
    LAYOUT_REQUEST,
    LAYOUT_REQUEST_SUCCESS,
    LAYOUT_REQUEST_FAILURE,
    EDIT_LAYOUT_SET_LISTS,
    EDIT_LAYOUT_OVERRIDE_NAME_FOR_LIST,
    EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST,
    EDIT_LAYOUT_OVERRIDE_FULLPAGE_STYLE_FOR_LIST,
    EDIT_LAYOUT_SET_PIN_FOR_LIST,
    EDIT_LAYOUT_ADD_LIST,
    LAYOUT_UPDATE_REQUEST,
    LAYOUT_UPDATE_REQUEST_FAILURE,
    LAYOUT_UPDATE_REQUEST_SUCCESS,
} from 'app/state/actions/editingLayout';
import { LAYOUT_WORKING_STATE_MESSAGES } from 'app/state/constants';
import {
    COPY_LAYOUT_REQUEST,
    COPY_LAYOUT_REQUEST_FAILURE,
    COPY_LAYOUT_REQUEST_SUCCESS,
    PUBLISH_EDIT_SCHEDULE_PUBLISH_DATE,
    PUBLISH_REQUEST,
    PUBLISH_REQUEST_FAILURE,
    PUBLISH_REQUEST_SUCCESS,
    PUBLISH_SCHEDULE_REQUEST,
    PUBLISH_SCHEDULE_REQUEST_FAILURE,
    PUBLISH_SCHEDULE_REQUEST_SUCCESS,
    PUBLISH_CANCEL_SCHEDULE_REQUEST,
    PUBLISH_CANCEL_SCHEDULE_REQUEST_FAILURE,
    PUBLISH_CANCEL_SCHEDULE_REQUEST_SUCCESS,
} from 'app/state/actions/publish';

export const INITIAL_STATE = freeze({
    isLoading: true,
    error: '',
    region: '',
    layout: [],
    lastPublishedDate: '',
    scheduledPublishDate: '',
    isPublishScheduled: false,
    workingState: {},
});

// editingLayout reducer
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case LAYOUT_REQUEST:
            return freeze({
                isLoading: true,
                error: '',
                region: payload.region,
                layout: [],
                lastPublishedDate: '',
                scheduledPublishDate: '',
                isPublishScheduled: false,
                workingState: LAYOUT_WORKING_STATE_MESSAGES.loading,
            });
        case LAYOUT_REQUEST_FAILURE:
            return freeze({
                isLoading: false,
                error: '',
                region: payload.region,
                layout: [],
                lastPublishedDate: '',
                scheduledPublishDate: '',
                isPublishScheduled: false,
                workingState: LAYOUT_WORKING_STATE_MESSAGES.loadFailed,
            });
        case LAYOUT_REQUEST_SUCCESS: {
            const { layout } = payload;

            const hasDiff = layout.lists.reduce(
                (result, current) => result || !!current.diff,
                false,
            );

            return freeze({
                isLoading: false,
                error: '',
                region: state.region,
                layout: payload.layout.lists,
                lastPublishedDate: payload.layout.publishedDate,
                scheduledPublishDate: payload.layout.scheduledPublishDate,
                isPublishScheduled: !!payload.layout.scheduledPublishDate,
                workingState: hasDiff
                    ? LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges
                    : LAYOUT_WORKING_STATE_MESSAGES.upToDate,
            });
        }
        case PUBLISH_EDIT_SCHEDULE_PUBLISH_DATE: {
            return chain(state)
                .setIn(['scheduledPublishDate'], payload.scheduledPublishDate)
                .value();
        }
        case EDIT_LAYOUT_SET_LISTS: {
            return chain(state)
                .setIn(['layout'], JSON.parse(JSON.stringify(payload.lists)))
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case EDIT_LAYOUT_ADD_LIST: {
            const { list } = payload;
            const existingLists = state.layout;
            const withNewList = existingLists.concat(list);

            return chain(state)
                .setIn(['layout'], withNewList)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case EDIT_LAYOUT_OVERRIDE_NAME_FOR_LIST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case EDIT_LAYOUT_OVERRIDE_FULLPAGE_STYLE_FOR_LIST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case EDIT_LAYOUT_SET_PIN_FOR_LIST: {
            const { listId, pinned } = payload;
            const lists = state.layout;

            // Restructure the list of lists so that they are in the correct visual order.

            const copy = JSON.parse(JSON.stringify(lists));

            const theListIndex = copy.findIndex(list => list.id === listId);

            if (theListIndex === -1) {
                return state;
            }

            const theListArr = copy.splice(theListIndex, 1);
            const theList = theListArr[0];

            theList.pinned = pinned;

            const undraggableLists = copy.filter(list => list.locked || list.pinned);
            const draggableLists = copy.filter(list => !list.locked && !list.pinned);

            if (theList.pinned) {
                undraggableLists.push(theList);
            } else {
                draggableLists.unshift(theList);
            }

            return chain(state)
                .setIn(['layout'], undraggableLists.concat(draggableLists))
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.unpublishedChanges)
                .value();
        }
        case LAYOUT_UPDATE_REQUEST:
        case LAYOUT_UPDATE_REQUEST_SUCCESS: {
            // Not "saving" or "save successful" as we want this to be invisible except on failure
            return state;
        }
        case LAYOUT_UPDATE_REQUEST_FAILURE: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.saveFailed)
                .value();
        }
        case PUBLISH_REQUEST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.publishing)
                .value();
        }
        case PUBLISH_REQUEST_FAILURE: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.publishFailed)
                .value();
        }
        case PUBLISH_REQUEST_SUCCESS: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.published)
                .value();
        }
        case PUBLISH_SCHEDULE_REQUEST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.scheduling)
                .value();
        }
        case PUBLISH_SCHEDULE_REQUEST_FAILURE: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.scheduleFailed)
                .value();
        }
        case PUBLISH_SCHEDULE_REQUEST_SUCCESS: {
            return chain(state)
                .setIn(['isPublishScheduled'], true)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.scheduled)
                .value();
        }
        case PUBLISH_CANCEL_SCHEDULE_REQUEST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.cancellingSchedule)
                .value();
        }
        case PUBLISH_CANCEL_SCHEDULE_REQUEST_FAILURE: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.cancellingScheduleFailed)
                .value();
        }
        case PUBLISH_CANCEL_SCHEDULE_REQUEST_SUCCESS: {
            return chain(state)
                .setIn(['isPublishScheduled'], false)
                .setIn(['scheduledPublishDate'], null)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.cancelledSchedule)
                .value();
        }
        case COPY_LAYOUT_REQUEST: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.copying)
                .value();
        }
        case COPY_LAYOUT_REQUEST_FAILURE: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.copyFailed)
                .value();
        }
        case COPY_LAYOUT_REQUEST_SUCCESS: {
            return chain(state)
                .setIn(['workingState'], LAYOUT_WORKING_STATE_MESSAGES.copied)
                .value();
        }
        default:
            return state;
    }
};
