import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    SuggestionWrapper,
    SuggestionRow,
    ImageWrapper,
    TitleAuthorWrapper,
    PodcastTitle,
    PodcastAuthor,
    ActionText,
} from './styled';
import BackArrow from '../../../../RegionLayouts/LayoutEditor/Header/back-arrow.svg';
import ChevronRight from '../../../../RegionLayouts/LayoutEditor/Layout/ContentBlock/chevron-right.svg';
import PodcastPlaceholderImage from './podcast-placeholder.png';

export class PodcastSuggestion extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,

        onMouseDown: PropTypes.func.isRequired,
    };

    render() {
        const { title, author, imageUrl, onMouseDown, selectText, showBackButton } = this.props;

        const shortTitle = title.substring(0, 100);
        const displayTitle = shortTitle === title ? title : `${shortTitle}...`;

        const shortAuthor = author.substring(0, 100);
        const displayAuthor = shortAuthor === author ? author : `${shortAuthor}...`;

        return (
            <SuggestionWrapper onMouseDown={onMouseDown}>
                <SuggestionRow>
                    {showBackButton && (
                        <ActionText>
                            <img src={BackArrow} height="20" width="20" />
                        </ActionText>
                    )}
                    <ImageWrapper>
                        <img width="40px" height="40px" src={imageUrl || PodcastPlaceholderImage} />
                    </ImageWrapper>
                    <TitleAuthorWrapper>
                        <PodcastTitle>{displayTitle}</PodcastTitle>
                        <PodcastAuthor>{displayAuthor}</PodcastAuthor>
                    </TitleAuthorWrapper>
                    {selectText && (
                        <ActionText>
                            {selectText}
                            <img src={ChevronRight} height="20" width="20" />
                        </ActionText>
                    )}
                </SuggestionRow>
            </SuggestionWrapper>
        );
    }
}

export default PodcastSuggestion;
