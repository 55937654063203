import styled, { css } from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;

    height: ${11 * gridSize}px;

    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    margin-bottom: ${3 * gridSize}px;
`;

export const Left = styled.div`
    flex-grow: 1;
`;

export const Right = styled.div`
    flex-grow: 0;
    display: flex;
`;

const RefreshDetails = css`
    display: flex;
    flex-direction: row;

    p {
        font-size: 14px;
        margin-top: 10px;
        color: #8f97a4;
    }
    .refresh-duration {
        color: #78d549;
        margin-left: 16px;
        margin-right: 16px;
    }
`;
export const RefreshDetailsAvailable = styled.div`
    ${RefreshDetails}
`;

export const RefreshDetailsUnavailable = styled.div`
    ${RefreshDetails}
    .refresh-duration {
        color: #e4583e;
    }
`;
