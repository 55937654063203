import styled from 'styled-components';

export const fontFamilyRetina = "'Retina', helvetica, arial, sans-serif";
export const fontFamilyRetinaNarrow = "'RetinaNarrow', helvetica, arial, sans-serif";

export const H10 = styled.h1`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 42px;
    font-weight: bold;
    line-height: 144%;
    letter-spacing: -0.8px;
    padding: 0;
    margin: 0;
`;

export const H20 = styled.h2`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 36px;
    font-weight: bold;
    line-height: 144%;
    letter-spacing: -0.8px;
    padding: 0;
    margin: 0;
`;

export const H30 = styled.h3`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 28px;
    font-weight: 600;
    line-height: 144%;
    letter-spacing: -0.8px;
    padding: 0;
    margin: 0;
`;

export const H40 = styled.h4`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 24px;
    font-weight: 600;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H50 = styled.h5`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 20px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H60 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 18px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H65 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 16px;
    font-weight: 400;
    line-height: 144%;
    letter-spacing: -0.01em;
    padding: 0;
    margin: 0;
`;

export const H70 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    font-weight: 400;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const H80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    padding: 0;
    margin: 0;
`;

export const B50 = styled.h5`
    font-family: ${fontFamilyRetinaNarrow};
    font-size: 20px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B60 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 18px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B65 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B70 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 14px;
    font-weight: 500;
    line-height: 144%;
    padding: 0;
    margin: 0;
`;

export const B80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    padding: 0;
    margin: 0;
`;

export const C80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
`;

export const BC80 = styled.p`
    font-family: ${fontFamilyRetina};
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 17px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
`;
