/* eslint-disable */

export const VERSION = '2.0.0';

export const API_URL = process.env.API_URL;
export const PODCAST_URL = process.env.PODCAST_URL;
export const PODCAST_CACHE_URL = process.env.PODCAST_CACHE_URL;
export const STATIC_URL = process.env.STATIC_URL;
export const LISTS_URL = process.env.PODCAST_LISTS_URL;

export const DEPLOYMENT_ENV = process.env.DEPLOYMENT_ENV;
