import styled from 'styled-components';
import {
    gridSize,
    coolGrey05,
    coolGrey40,
    coolGrey60,
    H50,
    white,
    coolGrey50,
    red50,
    hoverStateTransitionMixin,
    green50,
    H70,
    orange50,
} from 'common/pocket-casts-styles';
import { DIFF_ATTRIBUTES } from '../../../../../../../state/constants';

export const ContentBlockWrapper = styled.div`
    display: flex;
    width: 100%;

    align-items: center;

    background-color: ${props => (props.editable ? white : coolGrey05)};

    border: 1px solid
        ${props =>
            props.diff === DIFF_ATTRIBUTES.added
                ? green50
                : props.diff === DIFF_ATTRIBUTES.deleted
                ? red50
                : coolGrey40};

    border-radius: 10px;

    ${props =>
        !props.locked &&
        !props.pinned &&
        props.diff !== DIFF_ATTRIBUTES.deleted &&
        'box-shadow: 0px 2px 3px rgba(26, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.05)'};
`;

export const LockIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9px;
    height: 18px;

    margin-left: ${2 * gridSize}px;
    margin-right: ${2 * gridSize}px;
`;

export const GrabbyIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9px;
    height: 27px;

    margin-left: ${2 * gridSize}px;
    margin-right: ${2 * gridSize}px;
`;

export const ChevronIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${3 * gridSize}px;
    height: ${3 * gridSize}px;

    cursor: pointer;
    margin-left: ${2 * gridSize}px;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    height: ${7 * gridSize - 2}px;
    width: 100%;

    padding-left: ${gridSize * 2}px;
    padding-right: ${gridSize * 2}px;

    align-items: center;
    justify-content: space-between;
`;

export const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const TitleWrapper = styled(H50)`
    color: ${coolGrey60};
`;

export const SponsoredWrapper = styled(H70)`
    color: ${orange50};
    margin-right: ${2 * gridSize}px;
`;

export const ListIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    color: ${props => (props.faded ? coolGrey60 : props.theme.support06())};
    margin-right: ${2 * gridSize}px;
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const PinButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer !important;
`;

export const DeleteButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer !important;
    margin-left: ${2 * gridSize}px;

    color: ${coolGrey50};

    &:hover {
        color: ${red50};
    }

    ${hoverStateTransitionMixin};
`;
