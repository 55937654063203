import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const ListSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${gridSize}px;
`;

export const ListSearchBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    height: 34px;
    width: 34px;

    align-items: center;
    justify-content: center;

    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
`;

export const ListSearchBox = styled.input`
    width: 100%;
    height: 32px;

    border: 0;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;

    font-size: ${2 * gridSize}px;
    font-weight: 300px;

    &:focus {
        outline: none;
    }
`;

export const ListSuggestionBoxMarker = styled.div``;

export const SearchSuggestionsBox = styled.div`
    ${props => (props.visible ? 'display: block' : 'display: none')};
    position: absolute;
    width: 1000px;
    max-height: 400px;

    z-index: 2;

    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
`;

export const NoResultsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 50px;

    justify-content: center;
    align-items: center;
`;
