import React, { Component } from 'react';
import {
    ModalWrapper,
    ModalDialog,
    ModalCoverBackground,
    Pad,
    CrossWrapper,
    Cross,
    ChildrenWrapper,
} from './styled';

export class Modal extends Component {
    componentDidMount() {
        window.addEventListener('keydown', this.escFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.escFunction);
    }

    escFunction = event => {
        /* Key code for Escape key */
        if (event.keyCode === 27) {
            this.onCloseWrapper();
        }
    };

    onCloseWrapper = () => this.props.onClose();

    renderCross() {
        return (
            <Cross onClick={this.onCloseWrapper}>
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.364 6.63604C18.9734 6.24552 18.3403 6.24552 17.9497 6.63604L13 11.5858L8.05025 6.63604C7.65973 6.24552 7.02656 6.24552 6.63604 6.63604C6.24551 7.02656 6.24552 7.65973 6.63604 8.05025L11.5858 13L6.63604 17.9497C6.24552 18.3403 6.24551 18.9734 6.63604 19.364C7.02656 19.7545 7.65973 19.7545 8.05025 19.364L13 14.4142L17.9497 19.364C18.3403 19.7545 18.9734 19.7545 19.364 19.364C19.7545 18.9734 19.7545 18.3403 19.364 17.9497L14.4142 13L19.364 8.05025C19.7545 7.65973 19.7545 7.02656 19.364 6.63604Z"
                        fill="currentColor"
                    />
                </svg>
            </Cross>
        );
    }

    render() {
        const { horizontalInsetPx, blurred } = this.props;

        return (
            <ModalWrapper>
                <ModalDialog blurred={blurred}>
                    <Pad />
                    <CrossWrapper>{this.renderCross()}</CrossWrapper>
                    <ChildrenWrapper horizontalInset={horizontalInsetPx || 0}>
                        {this.props.children}
                    </ChildrenWrapper>
                </ModalDialog>
                <ModalCoverBackground onClick={this.props.onClose} />
            </ModalWrapper>
        );
    }
}

export default Modal;
