import { map } from 'rxjs/operators';
import fetch from '../../rest/podcastSearch';
import { getBaseUrl } from '../../state/selectors/config';

export const transformer = data => data;

export default (state, searchTerm) => {
    const baseUrl = getBaseUrl(state);
    return fetch(baseUrl, searchTerm).pipe(map(transformer));
};
