import PropTypes from 'prop-types';
import Button from './view';

// FIXME: Button needs to link into the theme token system.

Button.propTypes = {
    kind: PropTypes.string, // 'primary', 'primary-red', 'secondary' or 'secondary-muted'
    type: PropTypes.string, // Same as <button> attribute.
    width: PropTypes.number,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    kind: 'primary',
    small: false,
    loading: false,
    onClick: () => {},
};

export default Button;
