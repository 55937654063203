import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getPodcastListsSortField,
    getPodcastListsSortOrder,
    getPodcastLists,
    showArchivedPodcastListsSelector,
} from 'app/state/selectors/lists/index';
import { requestArchiveList, toggleShowArchivedLists } from 'app/state/actions/lists/index';
import Table from './view';

Table.propTypes = {
    filter: PropTypes.string,
};

const mapStateToProps = state => {
    const listCopy = JSON.parse(JSON.stringify(getPodcastLists(state)));
    const sortedByField = getPodcastListsSortField(state);
    const sortedAscending = getPodcastListsSortOrder(state) === 'ascending';
    const showsArchived = showArchivedPodcastListsSelector(state);

    listCopy.sort((a, b) => {
        const aField = a[sortedByField];
        const bField = b[sortedByField];

        if (typeof aField === 'number' || typeof aField === 'boolean') {
            if (sortedAscending) {
                return aField - bField;
            }
            return bField - aField;
        }

        if (sortedAscending) {
            return aField < bField ? -1 : 1;
        }

        return aField < bField ? 1 : -1;
    });

    return {
        sortedLists: listCopy,
        sortedByField,
        sortedAscending,
        showsArchived,
    };
};

const mapDispatchToProps = dispatch => ({
    toggleShowArchive: () => dispatch(toggleShowArchivedLists()),
    archiveList: id => dispatch(requestArchiveList(id, true)),
    unarchiveList: id => dispatch(requestArchiveList(id, false)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Table);
