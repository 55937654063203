import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegionFlag from 'common/pocket-casts-components/RegionFlag';
import {
    RegionDisplayWrapper,
    InnerWrapper,
    RegionHeaderWithEdit,
    SmooshedH4,
    Regions,
    RegionWrapper,
    EmptyStateWrapper,
} from './styled';

export class RegionDisplay extends Component {
    static propTypes = {
        regions: PropTypes.any,
    };

    renderRegions() {
        const { regions } = this.props;

        if (regions.length === 0) {
            return <EmptyStateWrapper>Not published to any regions.</EmptyStateWrapper>;
        }

        return regions.map(regionCode => (
            <RegionWrapper key={`rw-${regionCode}`}>
                <RegionFlag key={regionCode} region={regionCode} disabledClick />
            </RegionWrapper>
        ));
    }

    render() {
        const { regions } = this.props;

        return (
            <RegionDisplayWrapper>
                <InnerWrapper>
                    <RegionHeaderWithEdit>
                        <div>
                            <SmooshedH4>Published Regions ({regions.length})</SmooshedH4>
                        </div>
                    </RegionHeaderWithEdit>
                    <Regions>{this.renderRegions()}</Regions>
                </InnerWrapper>
            </RegionDisplayWrapper>
        );
    }
}

export default RegionDisplay;
