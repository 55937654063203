import styled from 'styled-components';
import { gridSize, H70, userSelectNoneMixin } from 'common/pocket-casts-styles';

const tiny = gridSize / 8;

export const SwitcherWrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    height: ${props => props.height}px;

    border: 1px solid ${props => props.theme.primaryUi05()};
    border-radius: ${1.25 * gridSize}px;
    box-sizing: border-box;

    padding-top: ${tiny}px;
    padding-bottom: ${tiny}px;
`;

export const Item = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    padding-left: ${3 * gridSize}px;
    padding-right: ${3 * gridSize}px;

    margin-left: ${tiny}px;
    margin-right: ${tiny}px;

    border-radius: ${gridSize}px;
    overflow: hidden;
    ${userSelectNoneMixin};

    ${props => !props.selected && 'cursor: pointer'};

    color: ${props =>
        props.selected ? props.theme.primaryUi01() : props.theme.primaryInteractive01()};

    background-color: ${props =>
        props.selected ? props.theme.primaryInteractive01() : props.theme.primaryUi01()};

    outline: none;

    // Remove the unselected options when printing
    @media print {
        &[tabIndex='0'] {
            display: none;
        }
        color: ${props =>
            props.selected ? props.theme.primaryInteractive01() : props.theme.primaryUi01()};

        background-color: transparent;
    }
`;

export const ItemText = styled(H70)`
    white-space: nowrap;
`;

/* Designs want B70 but that visibly changes container width on selection */
export const SelectedItemText = styled(H70)`
    white-space: nowrap;
`;
