import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const BulkAnalyticsPageWrapper = styled.div``;

export const LiveActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${3 * gridSize}px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;

    flex-grow: 1;

    height: ${5 * gridSize}px;
`;

export const RightWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LiveTableWrapper = styled.div`
    margin-bottom: ${5 * gridSize}px;
`;

export const OtherActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${3 * gridSize}px;
`;

export const OtherTableWrapper = styled.div`
    margin-bottom: ${5 * gridSize}px;
`;

export const ShowSponsoredCheckboxWrapper = styled.div`
    margin-right: 15px;
`;
