import React, { Component } from 'react';
import { H20 } from 'common/pocket-casts-styles';
import Button from 'common/pocket-casts-components/Button';
import Spacer from 'common/pocket-casts-components/Spacer';
import Switcher from 'common/pocket-casts-components/Switcher';
import { LISTS_URL } from 'settings';
import LoadingIndicator from 'common/pocket-casts-components/LoadingIndicator';
import { getDateRanges, getFilterFunctions } from 'common/DateRangeSwitcher/model';
import DateRangeInputs from 'common/DateRangeSwitcher/DateRangeInputs';
import { ListItemCount } from 'common/format';
import Meatball from 'common/pocket-casts-components/Meatball/view';
import Header from './Header';
import ListAnalyticsTable from './ListAnalyticsTable';
import {
    ListAnalyticsPageWrapper,
    HeroRowWrapper,
    ListNameWrapper,
    ListActionsWrapper,
    MetadataWrapper,
    NumberWrapper,
    LinkWrapper,
    DatePickerWrapper,
    TableWrapper,
    MeatballWrapper,
    MeatballPopoverTriangle,
    MeatballButtonWrapper,
    MeatballPopoverWrapper,
    CopyListLinkItem,
    PopoverItemTextWrapper,
    PopoverItemImageWrapper,
    PopoverInset,
} from './styled';
import { NavigationItems } from '../../../Navigation/model';
import { EPISODE_LIST, REGIONS } from '../../../../../state/constants';
import CopyIcon from './copy-icon.png';

class ListAnalyticsPage extends Component {
    constructor(props) {
        super(props);

        this.props.requestList(this.props.match.params.listId);
        this.props.requestListAnalytics(this.props.match.params.listId);

        this.state = {
            filterFunctionIndex: getDateRanges().length - 1,
            fromDate: null,
            toDate: null,

            meatballMenuVisible: false,
            copied: false,
        };
    }

    componentDidMount() {
        document
            .getElementById('Discover-Curator-Detail-Wrapper')
            .addEventListener('scroll', this.handleScroll);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document
            .getElementById('Discover-Curator-Detail-Wrapper')
            .removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setMeatballWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleScroll = () => {
        if (this.state.meatballMenuVisible) {
            this.setState({
                meatballMenuVisible: false,
                copied: false,
            });
        }
    };

    handleClickOutside = event => {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.meatballMenuVisible
        ) {
            this.setState({
                meatballMenuVisible: false,
                copied: false,
            });
        }
    };

    onMeatballClick = () => {
        this.setState({
            meatballMenuVisible: !this.state.meatballMenuVisible,
            copied: false,
        });
    };

    selectedSwitcherItem = index => {
        this.setState({
            filterFunctionIndex: index,
        });
    };

    updateFromDate = fromDate => {
        this.setState({
            fromDate,
        });
    };

    updateToDate = toDate => {
        this.setState({
            toDate,
        });
    };

    editList = () => {
        const { list } = this.props;
        this.props.history.push(`${NavigationItems.LISTS.path}/manage/edit/${list.id}`);
    };

    addToDiscover = () => {
        const { list } = this.props;
        this.props.publishListToRegions(list.id, REGIONS);
    };

    // https://tinyurl.com/y35tccaq
    copyListLinkToClipboard = () => {
        const { list } = this.props;

        const url = `${LISTS_URL}/${list.urlPath || list.id}`;

        // Create new element
        const el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = url;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        this.setState({
            copied: true,
        });
    };

    renderDiscoverInteractionButton() {
        const { list } = this.props;

        if (!list.publishDate || list.unpublishDate) {
            return (
                <Button kind="secondary" width={148} onClick={this.addToDiscover}>
                    Add to Discover
                </Button>
            );
        }

        return null;
    }

    render() {
        const { isLoading, list, listType, analytics } = this.props;
        const { filterFunctionIndex, fromDate, toDate, meatballMenuVisible, copied } = this.state;

        const dayFilterFunction = getFilterFunctions(fromDate, toDate)[filterFunctionIndex];

        const listItems = listType === EPISODE_LIST ? list.episodes : list.podcasts;

        if (isLoading) {
            return <LoadingIndicator size={100} />;
        }

        return (
            <ListAnalyticsPageWrapper>
                <Header list={list} />
                <HeroRowWrapper>
                    <ListNameWrapper>
                        <H20>{list && list.title}</H20>
                    </ListNameWrapper>
                    <ListActionsWrapper>
                        <MeatballButtonWrapper ref={this.setMeatballWrapperRef}>
                            <Button kind="secondary" onClick={this.onMeatballClick}>
                                <MeatballWrapper>
                                    <Meatball width={24} height={24} />
                                </MeatballWrapper>
                            </Button>
                            {meatballMenuVisible && (
                                <MeatballPopoverTriangle
                                    discoverButtonExists={!list.publishDate || list.unpublishDate}
                                />
                            )}
                            {meatballMenuVisible && (
                                <MeatballPopoverWrapper
                                    discoverButtonExists={!list.publishDate || list.unpublishDate}
                                >
                                    <PopoverInset>
                                        <CopyListLinkItem onClick={this.copyListLinkToClipboard}>
                                            <PopoverItemImageWrapper>
                                                <img width="24px" height="24px" src={CopyIcon} />
                                            </PopoverItemImageWrapper>
                                            <PopoverItemTextWrapper>
                                                {copied ? 'Link copied!' : 'Copy list link'}
                                            </PopoverItemTextWrapper>
                                        </CopyListLinkItem>
                                    </PopoverInset>
                                </MeatballPopoverWrapper>
                            )}
                        </MeatballButtonWrapper>
                        {(!list.publishDate || list.unpublishDate) && <Spacer n={2} />}
                        {this.renderDiscoverInteractionButton()}
                        <Spacer n={2} />
                        <Button onClick={this.editList} width={102}>
                            Edit List
                        </Button>
                    </ListActionsWrapper>
                </HeroRowWrapper>
                <MetadataWrapper>
                    <NumberWrapper>
                        <ListItemCount count={listItems.length} listType={listType} />
                    </NumberWrapper>
                    <LinkWrapper target="_blank" href={`${LISTS_URL}/${list.urlPath || list.id}`}>
                        {list && `/${list.urlPath || list.id}`}
                    </LinkWrapper>
                </MetadataWrapper>
                <DatePickerWrapper>
                    {/* Only show Date Switcher if list is published */}
                    {!list.unpublishDate && filterFunctionIndex === 0 && (
                        <DateRangeInputs
                            fromDateChanged={this.updateFromDate}
                            toDateChanged={this.updateToDate}
                        />
                    )}
                    {!list.unpublishDate && filterFunctionIndex === 0 && <Spacer />}
                    {!list.unpublishDate && (
                        <Switcher
                            items={getDateRanges()}
                            defaultSelectedIndex={getDateRanges().length - 1}
                            selectedItemAtIndex={this.selectedSwitcherItem}
                        />
                    )}
                </DatePickerWrapper>
                <TableWrapper>
                    <ListAnalyticsTable
                        listType={listType}
                        items={listItems}
                        analytics={analytics || {}}
                        dayFilterFunction={dayFilterFunction}
                    />
                </TableWrapper>
            </ListAnalyticsPageWrapper>
        );
    }
}

export default ListAnalyticsPage;
