import React, { Component } from 'react';
import { ManageListsPageWrapper } from './styled';
import Table from './Table';
import Actions from './Actions';

class ManageListsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
        };
    }

    searchTermChanged = searchTerm => {
        this.setState({ searchTerm });
    };

    render() {
        const { searchTerm } = this.state;

        return (
            <ManageListsPageWrapper>
                <Actions onSearchTermChange={this.searchTermChanged} />
                <Table filter={searchTerm} />
            </ManageListsPageWrapper>
        );
    }
}

export default ManageListsPage;
