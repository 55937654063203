import { API } from 'aws-amplify';
import { from } from 'rxjs';

export const fetchRegionLayout = region =>
    from(API.get('contentcurator', `/layouts/${region}`, {}));

export const saveRegionLayoutListOrder = (region, listsWithOverrides) =>
    from(
        API.post('contentcurator', `/layouts/${region}`, {
            body: listsWithOverrides,
        }),
    );
