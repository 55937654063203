import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import DiscoverCurator from './DiscoverCurator';

export class TopLevelRoutes extends Component {
    render() {
        return (
            <Switch>
                <Route path="/discover" component={DiscoverCurator} />
                <Route render={() => <Redirect to={`/discover`} />} />
            </Switch>
        );
    }
}

export default TopLevelRoutes;
