import styled from 'styled-components';
import { H70, gridSize } from 'common/pocket-casts-styles';

export const BackButtonStyled = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    @keyframes see-all-arrow {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(4px, 0);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }

    &:hover {
        > div {
            animation: see-all-arrow 1 400ms ease-in-out;
            animation-direction: alternate;
        }
    }
`;

export const BackButtonText = styled(H70)`
    color: ${props => props.theme.primaryInteractive01()};
    vertical-align: middle;
    margin-top: 2px;
`;

export const Arrow = styled.div`
    width: 24px;
    height: 24px;

    margin-right: ${gridSize}px;

    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eicon%2Farrow%3C%2Ftitle%3E%3Cdefs%3E%3Cpath%20d%3D%22M16.172%2011H4.592c-.555%200-1.006.444-1.006%201%200%20.552.45%201%201.007%201h11.579l-5.293%205.293c-.39.39-.39%201.024%200%201.414.39.39%201.023.39%201.414%200L20%2012l-7.707-7.707c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414L16.172%2011z%22%20id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cuse%20fill%3D%22%2303A9F4%22%20transform%3D%22matrix(-1%200%200%201%2023.586%200)%22%20xlink%3Ahref%3D%22%23a%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: baseline;
`;
