import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LoadingIndicatorWrapper } from './styled';
import LoadingIndicatorSvg from './loading-indicator.svg';

export default class LoadingIndicator extends Component {
    static propTypes = {
        size: PropTypes.number,
    };

    static defaultProps = {
        size: 24,
    };

    render() {
        const { size } = this.props;
        return (
            <LoadingIndicatorWrapper>
                <img width={`${size}px`} height={`${size}px`} src={LoadingIndicatorSvg} />
            </LoadingIndicatorWrapper>
        );
    }
}
