import React, { Component } from 'react';
import { NavigationItems } from './model';
import NavigationOption from './NavigationOption';
import RoundelIcon from './roundel.svg';
import {
    NavigationWrapper,
    LogoWrapper,
    EnvText,
    LogoEnvWrapper,
    RoundelLink,
    Spaces,
} from './styled';

export class Navigation extends Component {
    renderLogo(intl, navigationItems, size) {
        return (
            <LogoWrapper size={size}>
                <RoundelLink to={navigationItems.LAYOUTS.path}>
                    <img width={size} height={size} src={RoundelIcon} alt="Content Curator" />
                </RoundelLink>
            </LogoWrapper>
        );
    }

    renderEnvironmentText() {
        const env = process.env.DEPLOYMENT_ENV;
        if (env === 'production') {
            return <EnvText></EnvText>;
        }
        const envCamel = env.charAt(0).toUpperCase() + env.slice(1);
        return <EnvText>{envCamel}</EnvText>;
    }

    render() {
        const { intl } = this.props;

        return (
            <NavigationWrapper>
                <LogoEnvWrapper>
                    {this.renderLogo(intl, NavigationItems, 40)}
                    {this.renderEnvironmentText()}
                </LogoEnvWrapper>
                <Spaces n={4} />
                <NavigationOption navigationItem={NavigationItems.LAYOUTS} />
                <NavigationOption navigationItem={NavigationItems.FEATURED} disabled />
                <NavigationOption navigationItem={NavigationItems.LISTS} />
            </NavigationWrapper>
        );
    }
}

export default Navigation;
