import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerWrapper } from './styled';

class DatetimeInput extends Component {
    onChange = date => {
        if (this.props.onDatetimeChange) {
            this.props.onDatetimeChange(date);
        }
    };

    render() {
        const { selected, disabled } = this.props;
        return (
            <DatePickerWrapper>
                <DatePicker
                    disabled={disabled}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={15}
                    dateFormat="Pp"
                    onChange={this.onChange}
                    selected={selected}
                    placeholderText={disabled ? '' : 'Click to schedule date'}
                />
            </DatePickerWrapper>
        );
    }
}

export default DatetimeInput;
