/*
 * This file contains a basic set of colors that align with the Pocket Casts Design library.
 * These should only be used in cases where a theme token is not available.
 */

export const black = '#000000';
export const white = '#ffffff';

export const red10 = '#FEE5E2';
export const red20 = '#FDCBC5';
export const red30 = '#FC978C';
export const red40 = '#FB5146';
export const red50 = '#F43E37';
export const red60 = '#D9201C';
export const red70 = '#AA0A08';
export const red80 = '#550504';
export const red90 = '#2A0202';

export const blue10 = '#E1FCFF';
export const blue20 = '#C4F4FF';
export const blue30 = '#8AE9FF';
export const blue40 = '#50D0F1';
export const blue50 = '#03A9F4';
export const blue60 = '#187ADC';
export const blue70 = '#0059B3';
export const blue80 = '#002D59';
export const blue90 = '#00162C';

export const green10 = '#F1FBE5';
export const green20 = '#E3F7CA';
export const green30 = '#C8EF9A';
export const green40 = '#9BE45E';
export const green50 = '#78D549';
export const green60 = '#58BE37';
export const green70 = '#3B862D';
export const green80 = '#1E4316';
export const green90 = '#0F210B';

export const yellow10 = '#FEFCE1';
export const yellow20 = '#FEFAC3';
export const yellow30 = '#FDF58D';
export const yellow40 = '#FADB47';
export const yellow50 = '#FEC635';
export const yellow60 = '#EC9209';
export const yellow70 = '#B35900';
export const yellow80 = '#592D00';
export const yellow90 = '#2C1600';

export const orange10 = '#FFF7E1';
export const orange20 = '#FFEFC4';
export const orange30 = '#FFE08A';
export const orange40 = '#FEC143';
export const orange50 = '#FF9D3B';
export const orange60 = '#E16914';
export const orange70 = '#B03C03';
export const orange80 = '#581E01';
export const orange90 = '#2C0F00';

export const purple10 = '#F1E4FB';
export const purple20 = '#E4C9F8';
export const purple30 = '#CC98F0';
export const purple40 = '#A866DB';
export const purple50 = '#7642D1';
export const purple60 = '#5D31C4';
export const purple70 = '#3612A1';
export const purple80 = '#1B0950';
export const purple90 = '#0D0428';

export const pink10 = '#FEE2F2';
export const pink20 = '#FDC6E6';
export const pink30 = '#FB8ECE';
export const pink40 = '#F250A8';
export const pink50 = '#E93673';
export const pink60 = '#D61F59';
export const pink70 = '#AD060E';
export const pink80 = '#560307';
export const pink90 = '#2B0103';

export const coolGrey05 = '#FBFCFC';
export const coolGrey10 = '#FDFDFD';
export const coolGrey20 = '#FBFCFC';
export const coolGrey30 = '#F7F9FA';
export const coolGrey40 = '#E0E6EA';
export const coolGrey50 = '#B8C3C9';
export const coolGrey60 = '#8F97A4';
export const coolGrey70 = '#686C74';
export const coolGrey80 = '#292B2E';
export const coolGrey90 = '#161718';

export const warmGrey10 = '#FAFAF9';

export const darkGrey10 = '#686C74';
export const darkGrey20 = '#4F4F4F';
export const darkGrey30 = '#3A3A3B';
export const darkGrey40 = '#2F2F2F';
export const darkGrey50 = '#242425';
export const darkGrey60 = '#1C1C1C';
export const darkGrey70 = '#101010';
export const darkGrey80 = '#080808';
export const darkGrey90 = '#030303';

export const red = red50;
export const blue = blue50;
export const green = green50;
export const yellow = yellow50;
export const orange = orange50;
export const purple = purple50;
export const pink = pink50;
export const coolGrey = coolGrey50;
export const warmGrey = warmGrey10;
export const darkGrey = darkGrey50;
