import PropTypes from 'prop-types';
import Switcher from './view';

Switcher.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultSelectedIndex: PropTypes.number,
    height: PropTypes.number,

    selectedItemAtIndex: PropTypes.func,
};

Switcher.defaultProps = {
    defaultSelectedIndex: 0,
    height: 40,
};

export default Switcher;
