import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { MARKER_STRINGS } from 'app/state/constants';
import Header from './Header';
import { ListBrowserWrapper } from './styled';
import ManageListsPage from './ManageListsPage';
import BulkAnalyticsPage from './BulkAnalyticsPage';

export class ListBrowser extends Component {
    constructor(props) {
        super(props);

        this.props.resetCreatingFlag();
        this.props.loadLists();
        this.props.loadLiveAnalytics();
        this.props.loadSummaryAnalytics();
    }

    componentDidUpdate() {
        const newFlag = this.props.creatingFlag;

        if (!!newFlag && newFlag !== MARKER_STRINGS.failed && newFlag !== MARKER_STRINGS.loading) {
            this.props.history.push(`${this.props.match.url}/edit/${this.props.creatingFlag}`);
        }
    }

    managePageSelected = () => {
        this.props.changeListTab('manage');
    };

    analyticsPageSelected = () => {
        this.props.changeListTab('analytics');
    };

    render() {
        const { listTab } = this.props;

        return (
            <ListBrowserWrapper>
                <Header
                    selectedListTab={listTab}
                    managePageSelected={this.managePageSelected}
                    analyticsPageSelected={this.analyticsPageSelected}
                />
                {listTab === 'analytics' ? <BulkAnalyticsPage /> : <ManageListsPage />}
            </ListBrowserWrapper>
        );
    }
}

export default withRouter(ListBrowser);
