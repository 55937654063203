import { connect } from 'react-redux';
import BulkAnalyticsPage from './view';
import { getPodcastLists } from '../../../../../../state/selectors/lists';
import {
    getLiveAnalyticsData,
    getSummaryAnalyticsData,
} from '../../../../../../state/selectors/analytics';

const mapStateToProps = state => {
    const lists = getPodcastLists(state);
    const liveAnalytics = getLiveAnalyticsData(state);
    const summaryAnalytics = getSummaryAnalyticsData(state);

    let liveData = lists.reduce((result, current) => {
        const { publishDate, unpublishDate, archived } = current;

        if (archived || !publishDate || unpublishDate) {
            return result;
        }

        return result.concat(current);
    }, []);

    let nonLiveData = lists.reduce((result, current) => {
        const { publishDate, unpublishDate, archived } = current;

        if (archived || (publishDate && !unpublishDate)) {
            return result;
        }

        return result.concat(current);
    }, []);

    liveData = liveData.map(list => {
        const stats = liveAnalytics && liveAnalytics.find(statList => statList.id === list.id);

        const analytics = {
            days: (stats && stats.days) || [],
            podcasts: (stats && stats.podcasts) || [],
        };

        return {
            ...list,
            ...analytics,
        };
    });

    nonLiveData = nonLiveData.map(list => {
        const stats =
            summaryAnalytics && summaryAnalytics.find(statList => statList.id === list.id);

        const analytics = {
            impressions: (stats && stats.impressions) || 0,
            uniqueImpressions: (stats && stats.uniqueImpressions) || 0,
            engagements: (stats && stats.engagements) || 0,
            plays: (stats && stats.plays) || 0,
            subscribes: (stats && stats.subscribes) || 0,
        };

        return {
            ...list,
            ...analytics,
        };
    });

    const sortByPublishAndCreateDate = (a, b) => {
        if (!a.publishDate && !b.publishDate) {
            return b.createdDate.localeCompare(a.createdDate);
        }
        if (a.publishDate && !b.publishDate) {
            return -1;
        }
        if (!a.publishDate && b.publishDate) {
            return 1;
        }
        return b.publishDate.localeCompare(a.publishDate);
    };

    liveData.sort(sortByPublishAndCreateDate);
    nonLiveData.sort(sortByPublishAndCreateDate);

    return {
        liveData,
        nonLiveData,
    };
};

export default connect(mapStateToProps)(BulkAnalyticsPage);
