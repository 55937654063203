import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Actions from './view';
import {
    getCreatingFlag,
    getPodcastListsAreLoading,
} from '../../../../../../../state/selectors/lists';
import { requestCreateList, requestImportList } from '../../../../../../../state/actions/lists';
import { MARKER_STRINGS } from '../../../../../../../state/constants';

Actions.propTypes = {
    onSearchTermChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    listsAreLoading: getPodcastListsAreLoading(state),
    isCreatingList: getCreatingFlag(state) === MARKER_STRINGS.loading,
});

const mapDispatchToProps = dispatch => ({
    createNewList: listType => dispatch(requestCreateList(listType)),
    importList: bundleUuid => dispatch(requestImportList(bundleUuid)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Actions);
