export const ANALYTICS_LIVE_FETCH = 'ANALYTICS_LIVE_FETCH';
export const ANALYTICS_LIVE_FETCH_SUCCESS = 'ANALYTICS_LIVE_FETCH_SUCCESS';
export const ANALYTICS_LIVE_FETCH_FAILURE = 'ANALYTICS_LIVE_FETCH_FAILURE';

export const ANALYTICS_SUMMARY_FETCH = 'ANALYTICS_SUMMARY_FETCH';
export const ANALYTICS_SUMMARY_FETCH_SUCCESS = 'ANALYTICS_SUMMARY_FETCH_SUCCESS';
export const ANALYTICS_SUMMARY_FETCH_FAILURE = 'ANALYTICS_SUMMARY_FETCH_FAILURE';

export const ANALYTICS_LIST_FETCH = 'ANALYTICS_LIST_FETCH';
export const ANALYTICS_LIST_FETCH_SUCCESS = 'ANALYTICS_LIST_FETCH_SUCCESS';
export const ANALYTICS_LIST_FETCH_FAILURE = 'ANALYTICS_LIST_FETCH_FAILURE';

export const fetchLiveAnalytics = () => ({
    type: ANALYTICS_LIVE_FETCH,
});

export const liveAnalyticsFetchSuccess = payload => ({
    type: ANALYTICS_LIVE_FETCH_SUCCESS,
    payload,
});

export const liveAnalyticsFetchFailure = error => ({
    type: ANALYTICS_LIVE_FETCH_FAILURE,
    payload: {
        error,
    },
});

export const fetchSummaryAnalytics = () => ({
    type: ANALYTICS_SUMMARY_FETCH,
});

export const summaryAnalyticsFetchSuccess = payload => ({
    type: ANALYTICS_SUMMARY_FETCH_SUCCESS,
    payload,
});

export const summaryAnalyticsFetchFailure = error => ({
    type: ANALYTICS_SUMMARY_FETCH_FAILURE,
    payload: {
        error,
    },
});

export const fetchListAnalytics = listId => ({
    type: ANALYTICS_LIST_FETCH,
    payload: {
        listId,
    },
});

export const listAnalyticsFetchSuccess = payload => ({
    type: ANALYTICS_LIST_FETCH_SUCCESS,
    payload,
});

export const listAnalyticsFetchFailure = error => ({
    type: ANALYTICS_LIST_FETCH_FAILURE,
    payload: {
        error,
    },
});
