import styled from 'styled-components';
import { gridSize } from '../../pocket-casts-styles';

export const Wrapper = styled.div`
    display: flex;
    width: inherit;

    float: left;
    align-items: center;

    ${props => (props.disabled || props.disabledClick ? '' : 'cursor: pointer;')}
    ${props => (props.disabled ? 'filter: grayscale(100%);' : '')}
`;

export const FlagWrapperSmall = styled.div`
    width: 24px;
    height: 24px;

    border-radius: 50%;
    background-color: #eeeeee; /* Chris' suggestion */

    border: 1px solid black;
    ${props => (props.active ? 'border: 2px solid lightgreen;' : '')}
`;

export const FlagWrapper = styled.div`
    width: 48px;
    height: 48px;

    border-radius: 50%;
    background-color: #eeeeee; /* Chris' suggestion */

    ${props => (props.active ? 'border: 2px solid lightgreen;' : '')}
`;

export const TextWrapper = styled.div`
    padding-left: ${gridSize}px;

    ${props => (props.active ? 'color: lightgreen;' : '')}
`;

export const HeadingWrapper = styled.h1`
    padding-left: ${2 * gridSize}px;

    ${props => (props.active ? 'color: lightgreen;' : '')}
`;
