import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import RegionFlag from 'common/pocket-casts-components/RegionFlag';
import { REGIONS } from 'app/state/constants';
import { RegionGrid, RegionWrapper } from './styled';

export class RegionSelector extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    onRegionSelect = region => {
        const { match } = this.props;
        this.props.history.push(`${match.url}/${region}`);
    };

    render() {
        return (
            <div>
                <h1>Region Layouts</h1>
                <RegionGrid>
                    {REGIONS.map(region => (
                        <RegionWrapper key={region}>
                            <RegionFlag
                                key={`${region}-flag`}
                                region={region}
                                onClick={() => this.onRegionSelect(region)}
                            />
                        </RegionWrapper>
                    ))}
                </RegionGrid>
            </div>
        );
    }
}

export default withRouter(RegionSelector);
