import { createGlobalStyle } from 'styled-components';
import RetinaBlackWoff from './fonts/Retina-Black.woff';
import RetinaBlackWoff2 from './fonts/Retina-Black.woff2';
import RetinaBlackItalicWoff from './fonts/Retina-BlackItalic.woff';
import RetinaBlackItalicWoff2 from './fonts/Retina-BlackItalic.woff2';
import RetinaBoldWoff from './fonts/Retina-Bold.woff';
import RetinaBoldWoff2 from './fonts/Retina-Bold.woff2';
import RetinaBoldItalicWoff from './fonts/Retina-BoldItalic.woff';
import RetinaBoldItalicWoff2 from './fonts/Retina-BoldItalic.woff2';
import RetinaBookWoff from './fonts/Retina-Book.woff';
import RetinaBookWoff2 from './fonts/Retina-Book.woff2';
import RetinaBookItalicWoff from './fonts/Retina-BookItalic.woff';
import RetinaBookItalicWoff2 from './fonts/Retina-BookItalic.woff2';
import RetinaExLightWoff from './fonts/Retina-ExLight.woff';
import RetinaExLightWoff2 from './fonts/Retina-ExLight.woff2';
import RetinaExLightItalicWoff from './fonts/Retina-ExLightItalic.woff';
import RetinaExLightItalicWoff2 from './fonts/Retina-ExLightItalic.woff2';
import RetinaLightWoff from './fonts/Retina-Light.woff';
import RetinaLightWoff2 from './fonts/Retina-Light.woff2';
import RetinaLightItalicWoff from './fonts/Retina-LightItalic.woff';
import RetinaLightItalicWoff2 from './fonts/Retina-LightItalic.woff2';
import RetinaMediumWoff from './fonts/Retina-Medium.woff';
import RetinaMediumWoff2 from './fonts/Retina-Medium.woff2';
import RetinaMediumItalicWoff from './fonts/Retina-MediumItalic.woff';
import RetinaMediumItalicWoff2 from './fonts/Retina-MediumItalic.woff2';
import RetinaThinWoff from './fonts/Retina-Thin.woff';
import RetinaThinWoff2 from './fonts/Retina-Thin.woff2';
import RetinaThinItalicWoff from './fonts/Retina-ThinItalic.woff';
import RetinaNarrBlackWoff from './fonts/RetinaNarr-Black.woff';
import RetinaNarrBlackWoff2 from './fonts/RetinaNarr-Black.woff2';
import RetinaNarrBlackItalicWoff from './fonts/RetinaNarr-BlackItalic.woff';
import RetinaNarrBlackItalicWoff2 from './fonts/RetinaNarr-BlackItalic.woff2';
import RetinaNarrBoldWoff from './fonts/RetinaNarr-Bold.woff';
import RetinaNarrBoldWoff2 from './fonts/RetinaNarr-Bold.woff2';
import RetinaNarrBoldItalicWoff from './fonts/RetinaNarr-BoldItalic.woff';
import RetinaNarrBoldItalicWoff2 from './fonts/RetinaNarr-BoldItalic.woff2';
import RetinaNarrBookWoff from './fonts/RetinaNarr-Book.woff';
import RetinaNarrBookWoff2 from './fonts/RetinaNarr-Book.woff2';
import RetinaNarrBookItalicWoff from './fonts/RetinaNarr-BookItalic.woff';
import RetinaNarrBookItalicWoff2 from './fonts/RetinaNarr-BookItalic.woff2';
import RetinaNarrExLightWoff from './fonts/RetinaNarr-ExLight.woff';
import RetinaNarrExLightWoff2 from './fonts/RetinaNarr-ExLight.woff2';
import RetinaNarrExLightItalicWoff from './fonts/RetinaNarr-ExLightItalic.woff';
import RetinaNarrExLightItalicWoff2 from './fonts/RetinaNarr-ExLightItalic.woff2';
import RetinaNarrLightWoff from './fonts/RetinaNarr-Light.woff';
import RetinaNarrLightWoff2 from './fonts/RetinaNarr-Light.woff2';
import RetinaNarrLightItalicWoff from './fonts/RetinaNarr-LightItalic.woff';
import RetinaNarrLightItalicWoff2 from './fonts/RetinaNarr-LightItalic.woff2';
import RetinaNarrMediumWoff from './fonts/RetinaNarr-Medium.woff';
import RetinaNarrMediumWoff2 from './fonts/RetinaNarr-Medium.woff2';
import RetinaNarrMediumItalicWoff from './fonts/RetinaNarr-MediumItalic.woff';
import RetinaNarrMediumItalicWoff2 from './fonts/RetinaNarr-MediumItalic.woff2';
import RetinaNarrThinWoff from './fonts/RetinaNarr-Thin.woff';
import RetinaNarrThinWoff2 from './fonts/RetinaNarr-Thin.woff2';
import RetinaNarrThinItalicWoff from './fonts/RetinaNarr-ThinItalic.woff';
import RetinaNarrThinItalicWoff2 from './fonts/RetinaNarr-ThinItalic.woff2';

// Weight order (thin to thick):
// Extra light, light, thin, book, medium, bold, black.

export default createGlobalStyle`
    input, select, option, textarea, button {
        font-family: inherit;
    }

    body {
        /* Defaults for page */
        margin: 0;
        padding: 0;

        font-family: "Retina";
        font-weight: 400;
        
        /* Firefox */
        -moz-osx-font-smoothing: grayscale;

        /* WebKit */
        -webkit-font-smoothing: antialiased;

        /* Retina Narrow */

        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBlackWoff2}') format('woff2'),
                 url('${RetinaNarrBlackWoff}') format('woff');
            font-weight: 800;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBlackItalicWoff2}') format('woff2'),
                 url('${RetinaNarrBlackItalicWoff}') format('woff');
            font-weight: 800;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBoldWoff2}') format('woff2'),
                 url('${RetinaNarrBoldWoff}') format('woff');
            font-weight: 700;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBoldItalicWoff2}') format('woff2'),
                 url('${RetinaNarrBoldItalicWoff}') format('woff');
            font-weight: 700;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrMediumWoff2}') format('woff2'),
                 url('${RetinaNarrMediumWoff}') format('woff');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrMediumItalicWoff2}') format('woff2'),
                 url('${RetinaNarrMediumItalicWoff}') format('woff');
            font-weight: 500;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBookWoff2}') format('woff2'),
                 url('${RetinaNarrBookWoff}') format('woff');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrBookItalicWoff2}') format('woff2'),
                 url('${RetinaNarrBookItalicWoff}') format('woff');
            font-weight: 400;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrThinWoff2}') format('woff2'),
                 url('${RetinaNarrThinWoff}') format('woff');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrThinItalicWoff2}') format('woff2'),
                 url('${RetinaNarrThinItalicWoff}') format('woff');
            font-weight: 300;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrLightWoff2}') format('woff2'),
                 url('${RetinaNarrLightWoff}') format('woff');
            font-weight: 200;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrLightItalicWoff2}') format('woff2'),
                 url('${RetinaNarrLightItalicWoff}') format('woff');
            font-weight: 200;
            font-style: italic;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrExLightWoff2}') format('woff2'),
                 url('${RetinaNarrExLightWoff}') format('woff');
            font-weight: 100;
            font-style: normal;
        }
        @font-face {
            font-family: 'RetinaNarrow';
            src: url('${RetinaNarrExLightItalicWoff2}') format('woff2'),
                 url('${RetinaNarrExLightItalicWoff}') format('woff');
            font-weight: 100;
            font-style: italic;
        }
        
        /* Retina */
        
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBlackWoff2}') format('woff2'),
                 url('${RetinaBlackWoff}') format('woff');
            font-weight: 800;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBlackItalicWoff2}') format('woff2'),
                 url('${RetinaBlackItalicWoff}') format('woff');
            font-weight: 800;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBoldWoff2}') format('woff2'),
                 url('${RetinaBoldWoff}') format('woff');
            font-weight: 700;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBoldItalicWoff2}') format('woff2'),
                 url('${RetinaBoldItalicWoff}') format('woff');
            font-weight: 700;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaMediumWoff2}') format('woff2'),
                 url('${RetinaMediumWoff}') format('woff');
            font-weight: 500;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaMediumItalicWoff2}') format('woff2'),
                 url('${RetinaMediumItalicWoff}') format('woff');
            font-weight: 500;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBookWoff2}') format('woff2'),
                 url('${RetinaBookWoff}') format('woff');
            font-weight: 400;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaBookItalicWoff2}') format('woff2'),
                 url('${RetinaBookItalicWoff}') format('woff');
            font-weight: 400;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaThinWoff2}') format('woff2'),
                 url('${RetinaThinWoff}') format('woff');
            font-weight: 300;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaThinItalicWoff}') format('woff'); /* No woff2 for this one */
            font-weight: 300;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaLightWoff2}') format('woff2'),
                 url('${RetinaLightWoff}') format('woff');
            font-weight: 200;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaLightItalicWoff2}') format('woff2'),
                 url('${RetinaLightItalicWoff}') format('woff');
            font-weight: 200;
            font-style: italic;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaExLightWoff2}') format('woff2'),
                 url('${RetinaExLightWoff}') format('woff');
            font-weight: 100;
            font-style: normal;
        }
        @font-face {
            font-family: 'Retina';
            src: url('${RetinaExLightItalicWoff2}') format('woff2'),
                 url('${RetinaExLightItalicWoff}') format('woff');
            font-weight: 100;
            font-style: italic;
        }
    }
`;
