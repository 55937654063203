export const getPodcastLists = state => state.entities.lists.podcastLists;

export const getPodcastListWithIdCurried = state => id =>
    state.entities.lists.podcastLists.find(list => list.id === id);

export const getPodcastListsAreLoading = state => state.entities.lists.isLoading;
export const getPodcastListsIsError = state => state.entities.lists.isError;
export const getPodcastListsSortField = state => state.entities.lists.sortField;
export const getPodcastListsSortOrder = state => state.entities.lists.sortOrder;

export const showArchivedPodcastListsSelector = state => state.ui.lists.showArchivedLists;

export const getCreatingFlag = state => state.ui.lists.creatingFlag;

export const listTabSelector = state => state.ui.lists.listTab;
