import { connect } from 'react-redux';
import { requestLayout } from 'app/state/actions/editingLayout';
import {
    getLayoutRegion,
    getLayoutWorkingState,
    isLayoutLoading,
} from 'app/state/selectors/editingLayout';
import {
    requestCopyLayout,
    requestPublish,
    requestPublishSchedule,
    requestCancelPublishSchedule,
} from 'app/state/actions/publish';
import LayoutEditor from './view';

const mapStateToProps = state => ({
    isLayoutLoading: isLayoutLoading(state),
    layoutRegion: getLayoutRegion(state),
    layoutWorkingState: getLayoutWorkingState(state),
});

const mapDispatchToProps = dispatch => ({
    fetchLayout: region => dispatch(requestLayout(region)),
    publishLayout: () => dispatch(requestPublish()),
    publishSchedule: date => dispatch(requestPublishSchedule(date)),
    cancelPublishSchedule: () => dispatch(requestCancelPublishSchedule()),
    copyLayout: (regionToCopy, toRegions) => dispatch(requestCopyLayout(regionToCopy, toRegions)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LayoutEditor);
