export const LAYOUT_REQUEST = 'LAYOUT_REQUEST';
export const requestLayout = region => ({
    type: LAYOUT_REQUEST,
    payload: {
        region,
    },
});

export const LAYOUT_REQUEST_SUCCESS = 'LAYOUT_REQUEST_SUCCESS';
export const layoutRequestSuccess = (region, layout) => ({
    type: LAYOUT_REQUEST_SUCCESS,
    payload: {
        region,
        layout,
    },
});

export const LAYOUT_REQUEST_FAILURE = 'LAYOUT_REQUEST_FAILURE';
export const layoutRequestFailure = (region, error) => ({
    type: LAYOUT_REQUEST_FAILURE,
    payload: {
        region,
        error,
    },
});

//

export const EDIT_LAYOUT_SET_LISTS = 'EDIT_LAYOUT_SET_LISTS';
export const editLayoutSetLists = lists => ({
    type: EDIT_LAYOUT_SET_LISTS,
    payload: {
        lists,
    },
});

export const EDIT_LAYOUT_ADD_LIST = 'EDIT_LAYOUT_ADD_LIST';
export const editLayoutAddList = list => ({
    type: EDIT_LAYOUT_ADD_LIST,
    payload: {
        list,
    },
});

export const EDIT_LAYOUT_OVERRIDE_NAME_FOR_LIST = 'EDIT_LAYOUT_OVERRIDE_NAME_FOR_LIST';
export const editLayoutOverrideNameForList = (listId, name) => ({
    type: EDIT_LAYOUT_OVERRIDE_NAME_FOR_LIST,
    payload: {
        listId,
        name,
    },
});

export const EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST =
    'EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST';
export const editLayoutOverrideDiscoverStyleForList = (listId, discoverStyle) => ({
    type: EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST,
    payload: {
        listId,
        discoverStyle,
    },
});

export const EDIT_LAYOUT_OVERRIDE_FULLPAGE_STYLE_FOR_LIST =
    'EDIT_LAYOUT_OVERRIDE_FULLPAGE_STYLE_FOR_LIST';
export const editLayoutOverrideFullPageStyleForList = (listId, fullPageStyle) => ({
    type: EDIT_LAYOUT_OVERRIDE_DISCOVER_STYLE_FOR_LIST,
    payload: {
        listId,
        fullPageStyle,
    },
});

export const EDIT_LAYOUT_SET_PIN_FOR_LIST = 'EDIT_LAYOUT_SET_PIN_FOR_LIST';
export const editLayoutSetPinForList = (listId, pinned) => ({
    type: EDIT_LAYOUT_SET_PIN_FOR_LIST,
    payload: {
        listId,
        pinned,
    },
});

//

export const LAYOUT_UPDATE_REQUEST = 'LAYOUT_UPDATE_REQUEST';
export const requestUpdateLayout = region => ({
    type: LAYOUT_UPDATE_REQUEST,
    payload: {
        region,
    },
});

export const LAYOUT_UPDATE_REQUEST_SUCCESS = 'LAYOUT_UPDATE_REQUEST_SUCCESS';
export const layoutUpdateRequestSuccess = region => ({
    type: LAYOUT_UPDATE_REQUEST_SUCCESS,
    payload: {
        region,
    },
});

export const LAYOUT_UPDATE_REQUEST_FAILURE = 'LAYOUT_UPDATE_REQUEST_FAILURE';
export const layoutUpdateRequestFailure = region => ({
    type: LAYOUT_UPDATE_REQUEST_FAILURE,
    payload: {
        region,
    },
});
