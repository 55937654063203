import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const CreateListPageLayout = styled.div`
    display: flex;
    max-width: 1000px;
    flex-direction: column;
`;

export const PodcastListAndMetadataLayout = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: ${2 * gridSize}px;
`;

export const LowerLeftWrapper = styled.div`
    width: 800px;
    flex-grow: 0;
    flex-shrink: 0;
`;

export const LowerRightWrapper = styled.div`
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
`;

export const Side = styled.div`
    display: flex;
    flex-direction: column;
`;
