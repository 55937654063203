import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const RegionWrapper = styled.div`
    padding-right: ${gridSize * 2}px;
    padding-bottom: ${gridSize * 2}px;
`;

export const RegionGrid = styled.div`
    display: flex;
    width: 100%;
    height: ${gridSize * 60}px;

    flex-direction: column;
    flex-wrap: wrap;

    align-items: stretch;
`;
