import { blue50, green50, orange50, red50 } from '../../common/pocket-casts-styles';

export const ALLOWED_REGIONS = {
    worldwide: {
        key: '0',
        humanReadable: 'Worldwide',
    },
    outside_uk: {
        key: '1',
        humanReadable: 'Outside the UK',
    },
};

export const REGIONS = [
    'au',
    'be',
    'br',
    'ca',
    'cn',
    'ch',
    'de',
    'dk',
    'es',
    'fr',
    'ie',
    'it',
    'jp',
    'kr',
    'nl',
    'no',
    'pl',
    'ru',
    'se',
    'gb',
    'us',
    'global',
];

export const REGION_CODE = {
    global: 'global',
    australia: 'au',
    belgium: 'be',
    brazil: 'br',
    canada: 'ca',
    china: 'cn',
    switzerland: 'ch',
    germany: 'de',
    denmark: 'dk',
    spain: 'es',
    france: 'fr',
    ireland: 'ie',
    italy: 'it',
    japan: 'jp',
    southKorea: 'kr',
    netherlands: 'nl',
    norway: 'no',
    poland: 'pl',
    russia: 'ru',
    sweden: 'se',
    unitedKingdom: 'gb',
    unitedStatesOfAmerica: 'us',
};

export const EPISODE_LIST = 'episode_list';
export const PODCAST_LIST = 'podcast_list';

export const DISCOVER_STYLES_EPISODES = {
    singleEpisode: {
        key: 'single_episode',
        humanReadable: 'Single Episode',
    },
    collection: {
        key: 'collection',
        humanReadable: 'Collection',
    },
};

export const DISCOVER_STYLES_PODCASTS = {
    carousel: {
        key: 'carousel',
        humanReadable: 'Carousel',
        restricted: true,
    },
    smallList: {
        key: 'small_list',
        humanReadable: 'Small List',
    },
    largeList: {
        key: 'large_list',
        humanReadable: 'Large List',
    },
    singlePodcast: {
        key: 'single_podcast',
        humanReadable: 'Single Podcast',
    },
    collection: {
        key: 'collection',
        humanReadable: 'Collection',
    },
};

export const DISCOVER_STYLES_SPECIAL = {
    networks: {
        key: 'network',
        humanReadable: 'Network',
    },
    categories: {
        key: 'category',
        humanReadable: 'Category',
    },
};

export const FULL_PAGE_STYLES_EPISODES = {
    plainList: {
        key: 'plain_list',
        humanReadable: 'Plain List',
    },
};

export const FULL_PAGE_STYLES_PODCASTS = {
    plainList: {
        key: 'plain_list',
        humanReadable: 'Plain List',
    },
    rankedList: {
        key: 'ranked_list',
        humanReadable: 'Ranked List',
    },
    descriptiveList: {
        key: 'descriptive_list',
        humanReadable: 'Descriptive List',
    },
    grid: {
        key: 'grid',
        humanReadable: 'Grid',
    },
};

export const FULL_PAGE_STYLES_SPECIAL = {
    networkList: {
        key: 'network_list',
        humanReadable: 'Network List',
    },
};

export const DIFF_ATTRIBUTES = {
    added: 'added',
    deleted: 'deleted',
};

export const MAXIMUM_TITLE_LENGTH_FOR_SPONSORED = 45;

export const MARKER_STRINGS = {
    failed: 'failed',
    loading: 'loading',
};

export const FEATURED_LIST_ID = 'featured';
export const POPULAR_LIST_ID = 'popular';
export const TRENDING_LIST_ID = 'trending';

export const RESERVED_LIST_IDS = [FEATURED_LIST_ID, POPULAR_LIST_ID, TRENDING_LIST_ID];

export const LAYOUT_WORKING_STATE_MESSAGES = {
    loading: {
        message: 'Loading layout...',
        color: blue50,
    },
    loadFailed: {
        message: 'Layout load failed',
        color: red50,
    },
    upToDate: {
        message: 'Up to date',
        color: green50,
    },
    unpublishedChanges: {
        message: 'Unpublished changes',
        color: orange50,
    },
    saving: {
        message: 'Saving...',
        color: blue50,
    },
    saveFailed: {
        message: 'Save failed.',
        color: red50,
    },
    saveSuccessful: {
        // In practice this should be unused as we should immediately publish on save.
        message: 'Layout saved.',
        color: green50,
    },
    publishing: {
        message: 'Publishing layout...',
        color: blue50,
    },
    publishFailed: {
        message: 'Publish failed.',
        color: red50,
    },
    published: {
        message: 'Published!',
        color: green50,
    },
    scheduling: {
        message: 'Scheduling...',
        color: blue50,
    },
    scheduleFailed: {
        message: 'Schedule failed.',
        color: red50,
    },
    scheduled: {
        message: 'Scheduled!',
        color: green50,
    },
    cancellingSchedule: {
        message: 'Cancelling schedule...',
        color: blue50,
    },
    cancellingScheduleFailed: {
        message: 'Cancelling schedule failed.',
        color: red50,
    },
    cancelledSchedule: {
        message: 'Schedule cancelled!',
        color: green50,
    },
    copying: {
        message: 'Copying layout...',
        color: blue50,
    },
    copyFailed: {
        message: 'Layout copy failed',
        color: red50,
    },
    copied: {
        message: 'Layout copied successfully!',
        color: green50,
    },
};

export const MONTH_MAP = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const FUN_LIST_TITLE_PLACEHOLDERS = [
    'Instrumentalist',
    'Governmentalist',
    'Supernaturalist',
    'Photojournalist',
    'Quarterfinalist',
    'Sensationalist',
    'Traditionalist',
    'Sentimentalist',
    'Fundamentalist',
    'Existentialist',
    'Industrialist',
    'Individualist',
    'Televangelist',
    'Conceptualist',
    'Violoncellist',
    'Spiritualist',
    'Semifinalist',
    'Motorcyclist',
    'Hairstylist',
    'Hyperbolist',
    'Imperialist',
    'Specialist',
    'Capitalist',
    'Evangelist',
    'Naturalist',
    'Minimalist',
    'Generalist',
    'Unicyclist',
    'Monopolist',
    'Socialist',
    'Checklist',
    'Shortlist',
    'Blacklist',
    'Herbalist',
    'Mentalist',
    'Globalist',
    'Feudalist',
    'Serialist',
    'Bicyclist',
    'Novelist',
    'Finalist',
    'Vocalist',
    'Idealist',
    'Medalist',
    'Playlist',
    'Waitlist',
    'Nihilist',
    'Fatalist',
    'Realist',
    'Cyclist',
    'Stylist',
    'Violist',
    'Cellist',
];
