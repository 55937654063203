import { connect } from 'react-redux';
import { requestSearchLists } from 'app/state/actions/lists';
import {
    getListSearchError,
    getListSearchResults,
    isListSearchLoading,
} from 'app/state/selectors/listSearch';
import { editLayoutAddList, requestUpdateLayout } from 'app/state/actions/editingLayout';
import { getEditingLayoutLists, getLayoutRegion } from 'app/state/selectors/editingLayout';
import ListSearch from './view';

const mapStateToProps = state => ({
    listSearchResults: getListSearchResults(state),
    listSearchIsLoading: isListSearchLoading(state),
    listSearchError: getListSearchError(state),
    listsInLayout: getEditingLayoutLists(state),

    layoutRegion: getLayoutRegion(state),
});

const mapDispatchToProps = dispatch => ({
    fetchSuggestions: searchTerm => dispatch(requestSearchLists(searchTerm)),
    addListToLayout: (list, region) => {
        dispatch(editLayoutAddList(list));
        dispatch(requestUpdateLayout(region));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListSearch);
