import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const RegionDisplayWrapper = styled.div`
    display: flex;
    flex-direction: column;

    min-height: ${8 * gridSize}px;

    border: 1px solid lightgrey;
    border-radius: 2px;

    margin: ${gridSize}px 0 0 ${gridSize}px;
`;

export const InnerWrapper = styled.div`
    width: calc(100% - ${2 * gridSize}px);
    margin-left: ${gridSize}px;
    margin-top: ${gridSize}px;
`;

export const RegionHeaderWithEdit = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${gridSize}px;
`;

export const SmooshedH4 = styled.h4`
    margin-top: 0;
    margin-bottom: 0;
`;

export const Regions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RegionWrapper = styled.div`
    display: flex;
    align-items: center;

    padding-bottom: ${gridSize}px;
`;

export const EmptyStateWrapper = styled.div`
    text-align: center;
`;
