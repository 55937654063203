import { SINGLE_REGION_MODE } from 'common/feature-flags';
import { EPISODE_LIST, PODCAST_LIST, REGIONS } from '../../constants';

export const getCurrentListIsLoading = state => state.entities.currentList.isLoading;

export const getCurrentList = state => state.entities.currentList;
export const getCurrentListId = state => state.entities.currentList.id;
export const getCurrentListTitle = state => state.entities.currentList.title;
export const getCurrentListSubtitle = state => state.entities.currentList.subtitle;
export const getCurrentListColorOnLightBackground = state =>
    state.entities.currentList.colorOnLightBackground;
export const getCurrentListColorOnDarkBackground = state =>
    state.entities.currentList.colorOnDarkBackground;
export const getCurrentListWebUrl = state => state.entities.currentList.webUrl;
export const getCurrentListWebTitle = state => state.entities.currentList.webTitle;
export const getCurrentListAllowedRegions = state => state.entities.currentList.allowedRegions;
export const getCurrentListCollectionImageUploading = state =>
    state.entities.currentList.collectionImageUploading;
export const getCurrentListHeaderImageUploading = state =>
    state.entities.currentList.headerImageUploading;
export const getCurrentListDescription = state => state.entities.currentList.description;
export const getCurrentListUrlPath = state => state.entities.currentList.urlPath;
export const getCurrentListCollectionImage = state => state.entities.currentList.collectionImage;
export const getCurrentListHeaderImage = state => state.entities.currentList.headerImage;
export const getCurrentListListEditable = state => state.entities.currentList.listEditable;

export const getCurrentListEpisodes = state => state.entities.currentList.episodes;
export const getCurrentListPodcasts = state => state.entities.currentList.podcasts;

export const getCurrentListEpisodesUuidAndPodcastUuid = state =>
    state.entities.currentList.episodes.map(episode => ({
        uuid: episode.uuid,
        podcast: episode.podcast_uuid,
    }));
export const getCurrentListPodcastUuids = state =>
    state.entities.currentList.podcasts.map(podcast => podcast.uuid);

export const getCurrentListRegions = state => state.entities.currentList.regions;
export const getCurrentListIsInDiscover = state => {
    if (!SINGLE_REGION_MODE) {
        return false;
    }

    const { regions } = state.entities.currentList;

    for (const region of REGIONS) {
        if (!regions.includes(region)) {
            return false;
        }
    }

    return true;
};

export const getCurrentListDiscoverStyle = state => state.entities.currentList.discoverStyle;
export const getCurrentListFullPageStyle = state => state.entities.currentList.fullPageStyle;
export const getCurrentListIsSponsored = state => state.entities.currentList.sponsored;
export const getCurrentListPlatform = state => state.entities.currentList.platform;

export const getCurrentListType = state =>
    state.entities.currentList.listType ||
    (getCurrentListEpisodes(state).length > 0 ? EPISODE_LIST : PODCAST_LIST);

export const getCurrentListItems = state =>
    getCurrentListType(state) === EPISODE_LIST
        ? getCurrentListEpisodes(state)
        : getCurrentListPodcasts(state);
