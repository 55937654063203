import { combineReducers } from 'redux';

import listsReducer from './lists/reducer';
import currentListReducer from './currentList/reducer';
import editingLayoutReducer from './editingLayout/reducer';
import podcastSearchReducer from './podcastSearch/reducer';
import listSearchReducer from './listSearch/reducer';
import analyticsReducer from './analytics/reducer';

export default combineReducers({
    analytics: analyticsReducer,
    lists: listsReducer,
    currentList: currentListReducer,
    editingLayout: editingLayoutReducer,
    podcastSearch: podcastSearchReducer,
    listSearch: listSearchReducer,
});
