import styled from 'styled-components';
import { B70, gridSize } from 'common/pocket-casts-styles';

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: ${3 * gridSize}px;
`;

export const SearchBarWrapper = styled.div`
    flex-grow: 1;
`;

export const ButtonText = styled(B70)``;

export const ButtonSeparator = styled.span`
    width: 10px;
`;
