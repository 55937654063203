import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const MetadataWrapper = styled.div`
    display: flex;

    flex-direction: column;
    justify-content: left;

    color: #555555;
    padding-left: 0;
`;

export const NumPodcastsWrapper = styled.div`
    padding-bottom: ${gridSize}px;
    text-transform: uppercase;
`;

export const DescriptionWrapper = styled.div``;

export const DescriptionTextArea = styled.textarea`
    appearance: none;
    resize: none;
    border: none;
    width: 796px;
    height: 60px;

    color: #555555;
    font-family: inherit;
    font-weight: 100;
    font-size: 16px;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;

    margin-bottom: ${gridSize}px;

    &:focus {
        outline: none;
    }
`;

export const DescriptionReadOnly = styled.div`
    padding-bottom: 15px;
    color: #555555;
`;
