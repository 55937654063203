import styled from 'styled-components';
import { gridSize, H70 } from 'common/pocket-casts-styles';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;

    height: ${11 * gridSize}px;

    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    margin-bottom: ${3 * gridSize}px;

    @media print {
        display: none;
    }
`;

export const Left = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    flex-grow: 1;
`;

export const Right = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    flex-grow: 0;
`;

export const StatusText = styled(H70)`
    color: ${props => props.color};
    margin-right: ${2 * gridSize}px;
`;
