import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Wrapper, FlagWrapper, FlagWrapperSmall, TextWrapper, HeadingWrapper } from './styled';

export default class RegionFlag extends PureComponent {
    static propTypes = {
        region: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        disabledClick: PropTypes.bool,
        active: PropTypes.bool,
        asHeading: PropTypes.bool,
        small: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        disabled: false,
        active: false,
        asHeading: false,
        small: false,
        onClick: () => {},
    };

    renderRegionNameLocalisedToCurrentRegion() {
        switch (this.props.region) {
            case 'au':
                return <FormattedMessage id="region-au" defaultMessage="Australia" />;
            case 'be':
                return <FormattedMessage id="region-be" defaultMessage="Belgium" />;
            case 'br':
                return <FormattedMessage id="region-br" defaultMessage="Brazil" />;
            case 'ca':
                return <FormattedMessage id="region-ca" defaultMessage="Canada" />;
            case 'cn':
                return <FormattedMessage id="region-cn" defaultMessage="China" />;
            case 'ch':
                return <FormattedMessage id="region-ch" defaultMessage="Switzerland" />;
            case 'de':
                return <FormattedMessage id="region-de" defaultMessage="Germany" />;
            case 'dk':
                return <FormattedMessage id="region-dk" defaultMessage="Denmark" />;
            case 'es':
                return <FormattedMessage id="region-es" defaultMessage="Spain" />;
            case 'fr':
                return <FormattedMessage id="region-fr" defaultMessage="France" />;
            case 'ie':
                return <FormattedMessage id="region-ie" defaultMessage="Ireland" />;
            case 'it':
                return <FormattedMessage id="region-it" defaultMessage="Italy" />;
            case 'jp':
                return <FormattedMessage id="region-jp" defaultMessage="Japan" />;
            case 'kr':
                return <FormattedMessage id="region-kr" defaultMessage="South Korea" />;
            case 'nl':
                return <FormattedMessage id="region-nl" defaultMessage="Netherlands" />;
            case 'no':
                return <FormattedMessage id="region-no" defaultMessage="Norway" />;
            case 'pl':
                return <FormattedMessage id="region-pl" defaultMessage="Poland" />;
            case 'ru':
                return <FormattedMessage id="region-ru" defaultMessage="Russia" />;
            case 'se':
                return <FormattedMessage id="region-se" defaultMessage="Sweden" />;
            case 'gb':
                return <FormattedMessage id="region-gb" defaultMessage="United Kingdom" />;
            case 'us':
                return <FormattedMessage id="region-us" defaultMessage="United States" />;
            case 'global':
                return <FormattedMessage id="region-global" defaultMessage="Rest of the world" />;
            default:
                return <FormattedMessage id="region-unknown" defaultMessage="Unknown region" />;
        }
    }

    renderFlag() {
        const region = this.props.region || 'global';
        const { small, active } = this.props;

        if (small) {
            return (
                <FlagWrapperSmall active={active}>
                    <img
                        src={`https://static2.pocketcasts.com/discover/flags/${region}.png`}
                        width="24px"
                        height="24px"
                    />
                </FlagWrapperSmall>
            );
        }

        return (
            <FlagWrapper active={active}>
                <img
                    src={`https://static2.pocketcasts.com/discover/flags/${region}.png`}
                    width="48px"
                    height="48px"
                />
            </FlagWrapper>
        );
    }

    renderText() {
        const { small, asHeading, active } = this.props;

        if (small) {
            return null;
        }

        return asHeading ? (
            <HeadingWrapper active={active}>
                {this.renderRegionNameLocalisedToCurrentRegion()}
            </HeadingWrapper>
        ) : (
            <TextWrapper active={active}>
                {this.renderRegionNameLocalisedToCurrentRegion()}
            </TextWrapper>
        );
    }

    render() {
        const { disabled, disabledClick } = this.props;

        return (
            <Wrapper
                disabled={disabled}
                disabledClick={disabledClick}
                onClick={disabled || disabledClick ? () => {} : this.props.onClick}
            >
                {this.renderFlag()}
                {this.renderText()}
            </Wrapper>
        );
    }
}
