import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LISTS_URL } from 'settings';
import {
    FUN_LIST_TITLE_PLACEHOLDERS,
    MAXIMUM_TITLE_LENGTH_FOR_SPONSORED,
} from 'app/state/constants';
import Button from 'common/pocket-casts-components/Button';
import Meatball from 'common/pocket-casts-components/Meatball/view';
import {
    HeaderWrapper,
    Left,
    Right,
    StatusText,
    ButtonText,
    EditableListName,
    LinkWrapper,
    NonEditableLeadingSlash,
    EditableListLink,
    MeatballButtonWrapper,
    MeatballWrapper,
    MeatballPopoverTriangle,
    MeatballPopoverWrapper,
    PopoverInset,
    CopyListLinkItem,
    PopoverItemImageWrapper,
    PopoverItemTextWrapper,
    ListNameReadOnly,
    ListLinkReadOnly,
} from './styled';
import CopyIcon from './copy-icon.png';

const SUPPORTED_LINK_CHARACTERS = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    '-',
    '_',
];

export class Header extends Component {
    static propTypes = {
        listId: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        urlPath: PropTypes.string,
        isSponsored: PropTypes.bool.isRequired,
        updateTitle: PropTypes.func.isRequired,
        updateUrlPath: PropTypes.func.isRequired,
        saveCurrentList: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.funPlaceholder =
            FUN_LIST_TITLE_PLACEHOLDERS[
                Math.floor(Math.random() * FUN_LIST_TITLE_PLACEHOLDERS.length)
            ];

        this.state = {
            meatballMenuVisible: false,
            copied: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setMeatballWrapperRef = node => {
        this.wrapperRef = node;
    };

    handleScroll = () => {
        if (this.state.meatballMenuVisible) {
            this.setState({
                meatballMenuVisible: false,
                copied: false,
            });
        }
    };

    handleClickOutside = event => {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            this.state.meatballMenuVisible
        ) {
            this.setState({
                meatballMenuVisible: false,
                copied: false,
            });
        }
    };

    onMeatballClick = () => {
        this.setState({
            meatballMenuVisible: !this.state.meatballMenuVisible,
            copied: false,
        });
    };

    // https://tinyurl.com/y35tccaq
    copyListLinkToClipboard = () => {
        const { listId, urlPath } = this.props;
        const url = `${LISTS_URL}/${urlPath || listId}`;

        // Create new element
        const el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = url;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        this.setState({
            copied: true,
        });
    };

    isTitleInvalid = () => {
        const { isSponsored, title } = this.props;
        return isSponsored && title.length > MAXIMUM_TITLE_LENGTH_FOR_SPONSORED;
    };

    isSubtitleInvalid = () => {
        const { discoverStyle, subtitle } = this.props;
        if (discoverStyle === 'collection') {
            if (!subtitle || subtitle.trim().length < 1) {
                return true;
            }
        }
        return false;
    };

    isFormValid = () => this.isTitleInvalid() || this.isSubtitleInvalid();

    handleTitleChange = event => {
        this.props.updateTitle(event.target.value);
    };

    handleLinkChange = event => {
        const lowercased = event.target.value.toLowerCase() || '';
        const charArray = [...lowercased];
        const sanitisedString = charArray
            .filter(char => SUPPORTED_LINK_CHARACTERS.includes(char))
            .join('');

        this.props.updateUrlPath(sanitisedString);
    };

    onPrimaryButtonClick = () => {
        this.props.saveCurrentList();
        const { listId } = this.props;
        this.props.history.push(`/discover/lists/manage/stats/${listId}`);
    };

    /*
     * You cannot press the Publish To... button if the list is sponsored and the title
     * is invalid. This will not stop the list saving in the background though, we don't
     * want to lose other changes just because the title is invalid.
     *
     * The meatball menu must be contained in the DOM as a child of the meatball ref in order
     * to properly close if clicked outside.
     */
    render() {
        const { listId, title, urlPath, isInDiscover, listEditable } = this.props;
        const { meatballMenuVisible, copied } = this.state;

        return (
            <HeaderWrapper>
                <Left>
                    {listEditable ? (
                        <EditableListName
                            value={title}
                            placeholder={this.funPlaceholder || 'Name your list...'}
                            onChange={this.handleTitleChange}
                            onKeyPress={this.handleTitleKeyPress}
                        />
                    ) : (
                        <ListNameReadOnly>{title}</ListNameReadOnly>
                    )}
                    <LinkWrapper>
                        <NonEditableLeadingSlash>{'/'}</NonEditableLeadingSlash>
                        {listEditable ? (
                            <EditableListLink
                                value={urlPath}
                                placeholder={listId}
                                onChange={this.handleLinkChange}
                            />
                        ) : (
                            <ListLinkReadOnly>{listId}</ListLinkReadOnly>
                        )}
                    </LinkWrapper>
                </Left>
                <Right>
                    {isInDiscover && <StatusText>Added to Discover</StatusText>}
                    <MeatballButtonWrapper ref={this.setMeatballWrapperRef}>
                        <Button kind="secondary" onClick={this.onMeatballClick}>
                            <MeatballWrapper>
                                <Meatball width={24} height={24} />
                            </MeatballWrapper>
                        </Button>
                        {meatballMenuVisible && <MeatballPopoverTriangle />}
                        {meatballMenuVisible && (
                            <MeatballPopoverWrapper>
                                <PopoverInset>
                                    <CopyListLinkItem onClick={this.copyListLinkToClipboard}>
                                        <PopoverItemImageWrapper>
                                            <img width="24px" height="24px" src={CopyIcon} />
                                        </PopoverItemImageWrapper>
                                        <PopoverItemTextWrapper>
                                            {copied ? 'Link copied!' : 'Copy list link'}
                                        </PopoverItemTextWrapper>
                                    </CopyListLinkItem>
                                </PopoverInset>
                            </MeatballPopoverWrapper>
                        )}
                    </MeatballButtonWrapper>
                    <Button onClick={this.onPrimaryButtonClick} disabled={this.isFormValid()}>
                        <ButtonText>Save</ButtonText>
                    </Button>
                </Right>
            </HeaderWrapper>
        );
    }
}

export default Header;
