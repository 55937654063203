import { setIn } from 'icepick';
import {
    TOGGLE_SHOW_ARCHIVED_LISTS,
    LIST_BROWSER_RESET_CREATING_FLAG,
    LIST_CREATE_REQUEST,
    LIST_CREATE_REQUEST_SUCCESS,
    LIST_CREATE_REQUEST_FAILURE,
    CHANGE_LIST_TAB,
} from 'app/state/actions/lists';
import { MARKER_STRINGS } from 'app/state/constants';

export const INITIAL_STATE = {
    showArchivedLists: false,
    creatingFlag: '',
    listTab: 'analytics',
};

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_SHOW_ARCHIVED_LISTS: {
            return {
                ...state,
                showArchivedLists: !state.showArchivedLists,
            };
        }
        case CHANGE_LIST_TAB:
            return setIn(state, ['listTab'], payload.listTab);
        case LIST_CREATE_REQUEST:
            return setIn(state, ['creatingFlag'], MARKER_STRINGS.loading);
        case LIST_CREATE_REQUEST_SUCCESS:
            return setIn(state, ['creatingFlag'], payload.list.id);
        case LIST_CREATE_REQUEST_FAILURE:
            return setIn(state, ['creatingFlag'], MARKER_STRINGS.failed);
        case LIST_BROWSER_RESET_CREATING_FLAG:
            return setIn(state, ['creatingFlag'], '');
        default:
            return state;
    }
};
