import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RegionFlag from 'common/pocket-casts-components/RegionFlag/index';
import { RegionRowWrapper, RegionWrapper } from './styled';

export class RegionRow extends Component {
    static propTypes = {
        regions: PropTypes.any.isRequired,
    };

    render() {
        const { regions } = this.props;

        return (
            <RegionRowWrapper>
                {regions.map((region, index) => (
                    <RegionWrapper key={`${region}-${index}`}>
                        <RegionFlag region={region} disabledClick small />
                    </RegionWrapper>
                ))}
            </RegionRowWrapper>
        );
    }
}

export default RegionRow;
