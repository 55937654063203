export const isLayoutLoading = state => state.entities.editingLayout.isLoading;
export const getLayoutFetchError = state => state.entities.editingLayout.error;
export const getLayoutRegion = state => state.entities.editingLayout.region;
export const getEditingLayoutLists = state => state.entities.editingLayout.layout;
export const getEditingLayoutIsPublishScheduled = state =>
    state.entities.editingLayout.isPublishScheduled;

export const getEditingLayoutLastPublishedDate = state => {
    const dateString = state.entities.editingLayout.lastPublishedDate;

    if (dateString) {
        return new Date(state.entities.editingLayout.lastPublishedDate);
    }

    return null;
};

export const getEditingLayoutScheduledPublishDate = state => {
    const dateString = state.entities.editingLayout.scheduledPublishDate;

    if (dateString) {
        return new Date(state.entities.editingLayout.scheduledPublishDate);
    }

    return null;
};

export const getLayoutWorkingState = state => state.entities.editingLayout.workingState;
