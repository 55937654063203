import React, { Component } from 'react';
import Modal from 'common/pocket-casts-components/Modal';
import Button from 'common/pocket-casts-components/Button';
import { ImageWrapper, Title, Body, Separator, ButtonsWrapper, Spacer } from './styled';
import PublishImage from './publish.svg';

class PublishConfirmationModal extends Component {
    render() {
        const { onClose, onPublish, buttonText, title, description } = this.props;
        return (
            <Modal onClose={onClose} horizontalInsetPx={32}>
                <ImageWrapper>
                    <img width={32} height={32} src={PublishImage} />
                </ImageWrapper>
                <Title>{title}</Title>
                <Body>{description}</Body>
                <Separator />
                <ButtonsWrapper>
                    <Button kind="secondary" width={120} onClick={onClose}>
                        Cancel
                    </Button>
                    <Spacer px={16} />
                    <Button width={120} onClick={onPublish}>
                        {buttonText}
                    </Button>
                </ButtonsWrapper>
            </Modal>
        );
    }
}

export default PublishConfirmationModal;
