import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const SuggestionWrapper = styled.div`
    width: 100%;
    height: 50px;

    cursor: pointer;
    background-color: white;
`;

export const SuggestionRow = styled.div`
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: left;

    padding: ${gridSize / 2}px;

    &:hover {
        background-color: #eeeeee;
    }
`;

export const ImageWrapper = styled.div`
    display: flex;
    height: 40px;
    width: 40px;

    align-items: center;
    justify-content: center;
    padding-right: 12px;
`;

export const TitleAuthorWrapper = styled.div`
    display: flex;

    flex-direction: row;
`;

export const PodcastTitle = styled.div`
    font-weight: 700;
    padding-right: ${gridSize}px;
`;

export const PodcastAuthor = styled.div`
    font-weight: 100;
    color: gray;
`;
