/* eslint-disable */

import {
    getCurrentFilterColor,
    getCurrentPodcastColor,
    getCommaSeparatedStringOfRGB,
} from './internal';

export const LIGHT_THEME = {
    primaryUi01: () => `rgba(255, 255, 255, 1)`,
    primaryUi01Active: () => `rgba(247, 249, 250, 1)`,
    primaryUi02: () => `rgba(255, 255, 255, 1)`,
    primaryUi02Selected: () => `rgba(3, 169, 244, 1)`,
    primaryUi02Active: () => `rgba(247, 249, 250, 1)`,
    primaryUi03: () => `rgba(250, 250, 250, 1)`,
    primaryUi04: () => `rgba(247, 249, 250, 1)`,
    primaryUi05: () => `rgba(224, 230, 234, 1)`,
    primaryUi05Selected: () => `rgba(143, 151, 164, 1)`,
    primaryUi06: () => `rgba(247, 249, 250, 1)`,
    primaryIcon01: () => `rgba(3, 169, 244, 1)`,
    primaryIcon01Active: () => `rgba(138, 233, 255, 1)`,
    primaryIcon02: () => `rgba(184, 195, 201, 1)`,
    primaryIcon02Selected: () => `rgba(3, 169, 244, 1)`,
    primaryIcon02Active: () => `rgba(143, 151, 164, 1)`,
    primaryIcon03: () => `rgba(143, 151, 164, 1)`,
    primaryIcon03Active: () => `rgba(3, 169, 244, 1)`,
    primaryText01: () => `rgba(41, 43, 46, 1)`,
    primaryText02: () => `rgba(143, 151, 164, 1)`,
    primaryText02Selected: () => `rgba(3, 169, 244, 1)`,
    primaryField01: () => `rgba(239, 243, 245, 1)`,
    primaryField01Active: () => `rgba(224, 230, 234, 1)`,
    primaryField02: () => `rgba(251, 251, 252, 1)`,
    primaryField02Active: () => `rgba(255, 255, 255, 1)`,
    primaryField03: () => `rgba(184, 195, 201, 1)`,
    primaryField03Active: () => `rgba(3, 169, 244, 1)`,
    primaryInteractive01: () => `rgba(3, 169, 244, 1)`,
    primaryInteractive01Hover: () => `rgba(80, 208, 241, 1)`,
    primaryInteractive01Active: () => `rgba(138, 233, 255, 1)`,
    primaryInteractive01Disabled: () => `rgba(3, 169, 244, 0.25)`,
    primaryInteractive02: () => `rgba(255, 255, 255, 1)`,
    primaryInteractive02Hover: () => `rgba(3, 169, 244, 0.05)`,
    primaryInteractive02Active: () => `rgba(3, 169, 244, 0.1)`,

    secondaryUi01: () => `rgba(255, 255, 255, 1)`,
    secondaryUi02: () => `rgba(224, 230, 234, 1)`,
    secondaryIcon01: () => `rgba(3, 169, 244, 1)`,
    secondaryIcon02: () => `rgba(143, 151, 164, 1)`,
    secondaryText01: () => `rgba(41, 43, 46, 1)`,
    secondaryText02: () => `rgba(143, 151, 164, 1)`,
    secondaryField01: () => `rgba(239, 243, 245, 1)`,
    secondaryField01Active: () => `rgba(224, 230, 234, 1)`,
    secondaryInteractive01: () => `rgba(3, 169, 244, 1)`,
    secondaryInteractive01Hover: () => `rgba(80, 208, 241, 1)`,
    secondaryInteractive01Active: () => `rgba(138, 233, 255, 1)`,

    podcastUi01: () => `rgba(255, 255, 255, 1)`,
    podcastUi02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 0.07)`,
    podcastUi03: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 0.3)`,
    podcastUi04: () => `rgba(224, 230, 234, 1)`,
    podcastIcon01: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastIcon02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastIcon03: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastText01: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastText02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastInteractive01: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastInteractive01Active: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 0.6)`,
    podcastInteractive02: () => `rgba(255, 255, 255, 1)`,
    podcastInteractive03: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    podcastInteractive03Active: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 0.6)`,
    podcastInteractive04: () => `rgba(255, 255, 255, 1)`,
    podcastInteractive05: () => `rgba(104, 108, 116, 1)`,

    filterUi01: () => `rgba(255, 255, 255, 1)`,
    filterUi02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 0.07)`,
    filterUi03: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 0.3)`,
    filterUi04: () => `rgba(224, 230, 234, 1)`,
    filterIcon01: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterIcon02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterText01: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterText02: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterInteractive01: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterInteractive01Active: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 0.6)`,
    filterInteractive02: () => `rgba(255, 255, 255, 1)`,
    filterInteractive03: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 1)`,
    filterInteractive03Active: () =>
        `rgba(${getCommaSeparatedStringOfRGB(getCurrentFilterColor())}, 0.6)`,
    filterInteractive04: () => `rgba(255, 255, 255, 1)`,
    filterInteractive05: () => `rgba(104, 108, 116, 1)`,

    support01: () => `rgba(3, 169, 244, 1)`,
    support02: () => `rgba(120, 213, 73, 1)`,
    support03: () => `rgba(3, 169, 244, 1)`,
    support04: () => `rgba(24, 122, 220, 1)`,
    support05: () => `rgba(244, 62, 55, 1)`,
    support06: () => `rgba(76, 80, 82, 1)`,
    support07: () => `rgba(255, 255, 255, 1)`,
    support08: () => `rgba(255, 157, 59, 1)`,
    support09: () => `rgba(8, 8, 8, 1)`,

    playerBackground: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 0.2)`,
    playerHighlight: () => `rgba(${getCommaSeparatedStringOfRGB(getCurrentPodcastColor())}, 1)`,
    playerContrast01: () => `rgba(255, 255, 255, 1)`,
    playerContrast02: () => `rgba(255, 255, 255, 0.5)`,
    playerContrast03: () => `rgba(255, 255, 255, 0.4)`,
    playerContrast04: () => `rgba(255, 255, 255, 0.3)`,
    playerContrast05: () => `rgba(255, 255, 255, 0.2)`,
    playerContrast06: () => `rgba(255, 255, 255, 0.1)`,

    contrast01: () => `rgba(255, 255, 255, 1)`,
    contrast02: () => `rgba(255, 255, 255, 0.7)`,
    contrast03: () => `rgba(255, 255, 255, 0.5)`,
    contrast04: () => `rgba(255, 255, 255, 0.1)`,

    filter01: () => `rgba(244, 62, 55, 1)`,
    filter02: () => `rgba(255, 157, 59, 1)`,
    filter03: () => `rgba(254, 198, 53, 1)`,
    filter04: () => `rgba(120, 213, 73, 1)`,
    filter05: () => `rgba(3, 169, 244, 1)`,
    filter06: () => `rgba(93, 49, 196, 1)`,
    filter07: () => `rgba(233, 54, 115, 1)`,
    filter08: () => `rgba(184, 195, 201, 1)`,

    overlay: () => `rgba(0, 0, 0, 0.3)`,

    gradient01A: () => `rgba(254, 215, 69, 1)`,
    gradient01E: () => `rgba(254, 181, 37, 1)`,
    gradient02A: () => `rgba(3, 169, 244, 1)`,
    gradient02E: () => `rgba(80, 208, 241, 1)`,
    gradient03A: () => `rgba(244, 55, 105, 1)`,
    gradient03E: () => `rgba(251, 82, 70, 1)`,
    gradient04A: () => `rgba(120, 213, 73, 1)`,
    gradient04E: () => `rgba(155, 228, 94, 1)`,
    gradient05A: () => `rgba(231, 75, 138, 1)`,
    gradient05E: () => `rgba(96, 70, 233, 1)`,
};
