/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DroppableContainer, DraggableContainer } from './styled';
import ContentBlock from './ContentBlock';
import { DIFF_ATTRIBUTES } from '../../../../../../state/constants';

// A helper function for reordering the content blocks
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export class Layout extends Component {
    static propTypes = {
        lists: PropTypes.any.isRequired,
        setLists: PropTypes.func.isRequired,
        pinUnpinList: PropTypes.func.isRequired,
    };

    getUndraggableBlocks = () => this.props.lists.filter(list => list.locked || list.pinned);

    getDraggableBlocks = () => this.props.lists.filter(list => !list.locked && !list.pinned);

    saveToServer = () => {
        this.props.saveEditingLayout(this.props.region);
    };

    onDragEnd = result => {
        const undraggableBlocks = this.getUndraggableBlocks();
        const draggableBlocks = this.getDraggableBlocks();

        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedDraggableBlocks = reorder(
            draggableBlocks,
            result.source.index,
            result.destination.index,
        );

        this.props.setLists(undraggableBlocks.slice().concat(reorderedDraggableBlocks));
        this.saveToServer();
    };

    removeContentBlock = listId => {
        const listIndex = this.props.lists.findIndex(list => list.id === listId);
        const copy = JSON.parse(JSON.stringify(this.props.lists));
        copy[listIndex].diff = DIFF_ATTRIBUTES.deleted;

        // Add deleted item to the end of the layout
        const deletedArr = copy.splice(listIndex, 1);
        if (deletedArr && deletedArr.length > 0) {
            copy.push(deletedArr[0]);
        }

        this.props.setLists(copy);
        this.saveToServer();
    };

    pinUnpinContentBlock = (listId, pinned) => {
        this.props.pinUnpinList(listId, pinned);
        this.saveToServer();
    };

    renderStaticBlocks() {
        const undraggableLists = this.getUndraggableBlocks();

        return undraggableLists.map(list => (
            <DraggableContainer key={`u-${list.id}`}>
                <ContentBlock
                    isDragging={false}
                    listId={list.id}
                    title={list.title}
                    editable={list.editable}
                    locked={list.locked}
                    pinned={list.pinned}
                    sponsored={list.sponsored}
                    diff={list.diff}
                    removeContentBlock={this.removeContentBlock}
                    pinUnpinContentBlock={this.pinUnpinContentBlock}
                />
            </DraggableContainer>
        ));
    }

    renderDynamicBlocks() {
        const draggableLists = this.getDraggableBlocks();

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {provided => (
                        <DroppableContainer ref={provided.innerRef}>
                            {draggableLists.map((list, index) => (
                                <Draggable
                                    key={list.id}
                                    draggableId={list.id}
                                    index={index}
                                    isDragDisabled={
                                        list.locked ||
                                        list.pinned ||
                                        list.diff === DIFF_ATTRIBUTES.deleted
                                    }
                                >
                                    {(provided, snapshot) => (
                                        <DraggableContainer
                                            ref={provided.innerRef}
                                            style={provided.draggableProps.style}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ContentBlock
                                                isDragging={snapshot.isDragging}
                                                listId={list.id}
                                                title={list.title}
                                                editable={list.editable}
                                                locked={list.locked}
                                                pinned={list.pinned}
                                                sponsored={list.sponsored}
                                                diff={list.diff}
                                                removeContentBlock={this.removeContentBlock}
                                                pinUnpinContentBlock={this.pinUnpinContentBlock}
                                            />
                                        </DraggableContainer>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </DroppableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    render() {
        return (
            <div>
                {this.renderStaticBlocks()}
                {this.renderDynamicBlocks()}
            </div>
        );
    }
}

export default Layout;
