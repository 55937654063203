import PropTypes from 'prop-types';
import { EPISODE_LIST, PODCAST_LIST } from 'app/state/constants';
import ListAnalyticsTable from './view';

ListAnalyticsTable.propTypes = {
    listType: PropTypes.oneOf([EPISODE_LIST, PODCAST_LIST]).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    analytics: PropTypes.object.isRequired,
    dayFilterFunction: PropTypes.func.isRequired,
};

export default ListAnalyticsTable;
