import styled, { css } from 'styled-components';
import {
    blue,
    blue20,
    blue30,
    blue40,
    coolGrey70,
    red,
    red20,
    red30,
    red40,
    white,
} from 'common/pocket-casts-styles';

export const LoadingIcon = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;

    img {
        @keyframes rotate {
            to {
                transform: rotate(360deg);
            }
        }
        animation: rotate 0.6s linear infinite;
    }
`;

const primaryStandard = blue;
const primaryHover = blue40;
const primaryActive = blue30;
const primaryDisabled = '#bfe7f9';

const primaryMixin = css`
    color: ${white};
    font-weight: 500;

    background-color: ${props => (props.disabled ? primaryDisabled : primaryStandard)};
    border: 1px solid ${props => (props.disabled ? primaryDisabled : primaryStandard)};

    &:hover {
        ${props => (props.disabled ? '' : `background-color: ${primaryHover}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryHover}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${primaryActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryActive}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const primaryRedStandard = red;
const primaryRedHover = red40;
const primaryRedActive = red30;
const primaryRedDisabled = red20;

const primaryRedMixin = css`
    color: ${white};
    font-weight: 500;

    background-color: ${props => (props.disabled ? primaryRedDisabled : primaryRedStandard)};
    border: 1px solid ${props => (props.disabled ? primaryRedDisabled : primaryRedStandard)};

    &:hover {
        ${props => (props.disabled ? '' : `background-color: ${primaryRedHover}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryRedHover}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${primaryRedActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${primaryRedActive}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryLightActive = '#d2f1f9';
const secondaryLightDisabled = blue20;
const secondaryLightDisabledText = '#b3e5fa';
const secondaryLightDisabledBorder = '#bfe7f9';

const secondaryMixinLight = css`
    color: ${props =>
        props.disabled
            ? secondaryLightDisabledText
            : props.kind === 'secondary-muted'
            ? coolGrey70
            : blue};

    font-weight: 400;

    border: 1px solid
        ${props =>
            props.disabled
                ? secondaryLightDisabledBorder
                : props.kind === 'secondary-muted'
                ? coolGrey70
                : blue};

    background-color: ${props => (props.disabled ? secondaryLightDisabled : 'transparent')};

    &:hover {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `background-color: ${secondaryLightActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryDarkActive = 'rgba(80, 208, 241, 0.24)';
const secondaryDarkDisabled = 'rgba(0, 45, 89, 0.24)';
const secondaryDarkDisabledText = 'rgba(3, 169, 244, 0.25)';
const secondaryDarkDisabledBorder = 'rgba(3, 169, 244, 0.24)';

const secondaryMixinDark = css`
    color: ${props =>
        props.disabled
            ? secondaryDarkDisabledText
            : props.kind === 'secondary-muted'
            ? coolGrey70
            : blue};

    font-weight: 400;

    border: 1px solid
        ${props =>
            props.disabled
                ? secondaryDarkDisabledBorder
                : props.kind === 'secondary-muted'
                ? coolGrey70
                : blue};

    background-color: ${props => (props.disabled ? secondaryDarkDisabled : 'transparent')};

    &:hover {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: 0px 1px 2.5px rgba(0, 0, 0, 0.25)')};
    }

    &:active {
        ${props => (props.disabled ? '' : `color: ${blue40}`)};
        ${props => (props.disabled ? '' : `background-color: ${secondaryDarkActive}`)};
        ${props => (props.disabled ? '' : `border: 1px solid ${blue40}`)};
        ${props => (props.disabled ? '' : 'box-shadow: none')};
    }
`;

const secondaryMixin = css`
    ${props => (props.theme.name === 'dark' ? secondaryMixinDark : secondaryMixinLight)}
`;

export const ButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    transition: all 0.1s ease;
    outline: none;

    ${props => (props.height ? `height: ${props.height}px` : '')};
    ${props => (props.height ? `line-height: ${props.height}px` : '')};

    text-align: center;
    font-size: 14.4px;
    font-weight: 500;

    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};

    ${props => (props.width ? `width: ${props.width}px` : '')};
    ${props => (props.width ? `min-width: ${props.width}px` : '')};

    ${props => (props.kind === 'primary' ? primaryMixin : '')};
    ${props => (props.kind === 'primary-red' ? primaryRedMixin : '')};
    ${props => (props.kind === 'secondary' ? secondaryMixin : '')};
    ${props => (props.kind === 'secondary-muted' ? secondaryMixin : '')}
`;
