import React from 'react';
import { BackButtonStyled, BackButtonText, Arrow } from './styled';
import { NavigationItems } from '../../../../../Navigation/model';

class BackButton extends React.Component {
    backToPodcastLists = () => {
        this.props.history.push(`${NavigationItems.LISTS.path}`);
    };

    render() {
        return (
            <BackButtonStyled onClick={this.backToPodcastLists}>
                <Arrow />
                <BackButtonText>All Lists</BackButtonText>
            </BackButtonStyled>
        );
    }
}

export default BackButton;
