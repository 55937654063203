import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SingleSelect from 'common/pocket-casts-components/SingleSelect';
import Checkbox from 'common/pocket-casts-components/Checkbox';
import Input from 'common/pocket-casts-components/Input';
import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import {
    DISCOVER_STYLES_PODCASTS,
    FULL_PAGE_STYLES_PODCASTS,
    ALLOWED_REGIONS,
    MAXIMUM_TITLE_LENGTH_FOR_SPONSORED,
} from 'app/state/constants';
import {
    StyleEditorWrapper,
    FieldWrapper,
    FlattenedH4,
    TitleErrorDiv,
    HintText,
    FileInput,
    CircleImageWrapper,
    FlattenedH5,
    FieldGroupWrapper,
    ImageWrapper,
} from './styled';
import {
    DISCOVER_STYLES_EPISODES,
    EPISODE_LIST,
    FULL_PAGE_STYLES_EPISODES,
} from '../../../../../../state/constants';

export class StyleEditor extends Component {
    static propTypes = {
        listId: PropTypes.string.isRequired,

        selectedDiscoverStyle: PropTypes.string.isRequired,
        selectedFullPageStyle: PropTypes.string.isRequired,
        isSponsored: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        webUrl: PropTypes.string,
        webTitle: PropTypes.string,
        colorOnLightBackground: PropTypes.string,
        colorOnDarkBackground: PropTypes.string,
        collectionImage: PropTypes.string,
        headerImage: PropTypes.string,
        collectionImageUploading: PropTypes.bool,
        headerImageUploading: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        platform: PropTypes.array,
        setCollectionImageUploading: PropTypes.func.isRequired,
        setHeaderImageUploading: PropTypes.func.isRequired,
        saveDiscoverStyle: PropTypes.func.isRequired,
        saveAllowedRegions: PropTypes.func.isRequired,
        saveFullPageStyle: PropTypes.func.isRequired,
        saveSponsored: PropTypes.func.isRequired,
        saveSubtitle: PropTypes.func.isRequired,
        savePlatform: PropTypes.func.isRequired,
    };

    onDiscoverStyleChange = event => {
        this.props.saveDiscoverStyle(event.target.value);
    };

    onFullPageStyleChange = event => {
        this.props.saveFullPageStyle(event.target.value);
    };

    onAllowedRegionsChange = event => {
        this.props.saveAllowedRegions(Number(event.target.value));
    };

    onSponsoredChange = event => {
        this.props.saveSponsored(event.target.value);
    };

    onSubtitleChange = event => {
        this.props.saveSubtitle(event.target.value);
    };

    onWebUrlChange = event => {
        this.props.saveWebUrl(event.target.value);
    };

    onWebTitleChange = event => {
        this.props.saveWebTitle(event.target.value);
    };

    onColorOnLightBackgroundChange = event => {
        this.props.saveColorOnLightBackground(event.target.value);
    };

    onColorOnDarkBackgroundChange = event => {
        this.props.saveColorOnDarkBackground(event.target.value);
    };

    onCollectionImageChange = event => {
        const { setCollectionImageUploading, saveCollectionImage, listId } = this.props;
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();
        const filename = `${listId}-author.${extension}`;
        const filepath = `collections/${filename}`;
        setCollectionImageUploading(true);
        Storage.put(filepath, file, {
            contentType: file.type,
        })
            .then(result => {
                console.log('Collection image upload successful.', result);
                saveCollectionImage(filename);
                setCollectionImageUploading(false);
            })
            .catch(err => {
                console.log(err);
                setCollectionImageUploading(false);
            });
    };

    onHeaderImageChange = event => {
        const { setHeaderImageUploading, saveHeaderImage, listId } = this.props;
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();
        const filename = `${listId}-header.${extension}`;
        const filepath = `headers/${filename}`;
        setHeaderImageUploading(true);
        Storage.put(filepath, file, {
            contentType: file.type,
        })
            .then(result => {
                console.log('Header image upload successful.', result);
                saveHeaderImage(filename);
                setHeaderImageUploading(false);
            })
            .catch(err => {
                console.log(err);
                setHeaderImageUploading(false);
            });
    };

    isTitleInvalid = () => {
        const { isSponsored, title } = this.props;
        return isSponsored && title.length > MAXIMUM_TITLE_LENGTH_FOR_SPONSORED;
    };

    platformOnChange = event => {
        this.props.savePlatform(event.target.value, event.target.checked);
    };

    render() {
        const {
            selectedDiscoverStyle,
            selectedFullPageStyle,
            isSponsored,
            subtitle,
            colorOnLightBackground,
            colorOnDarkBackground,
            webUrl,
            webTitle,
            collectionImageUploading,
            headerImageUploading,
            collectionImage,
            headerImage,
            platform,
            allowedRegions,
            listEditable,
            listType,
        } = this.props;

        const discoverStyles =
            listType === EPISODE_LIST ? DISCOVER_STYLES_EPISODES : DISCOVER_STYLES_PODCASTS;
        const discoverStyleItems = Object.keys(discoverStyles)
            .filter(key => !discoverStyles[key].restricted)
            .map(key => ({
                value: discoverStyles[key].key,
                text: discoverStyles[key].humanReadable,
                selected: discoverStyles[key].key === selectedDiscoverStyle,
            }));

        const fullPageStyles =
            listType === EPISODE_LIST ? FULL_PAGE_STYLES_EPISODES : FULL_PAGE_STYLES_PODCASTS;
        const fullPageStyleItems = Object.keys(fullPageStyles).map(key => ({
            value: fullPageStyles[key].key,
            text: fullPageStyles[key].humanReadable,
            selected: fullPageStyles[key].key === selectedFullPageStyle,
        }));

        const allowedRegionsItems = Object.keys(ALLOWED_REGIONS).map(key => ({
            value: ALLOWED_REGIONS[key].key,
            text: ALLOWED_REGIONS[key].humanReadable,
            selected: ALLOWED_REGIONS[key].key === `${allowedRegions}`,
        }));

        const sponsoredItems = [
            {
                value: 'no',
                text: 'No',
                selected: !isSponsored,
            },
            {
                value: 'yes',
                text: 'Yes',
                selected: isSponsored,
            },
        ];

        const isCollection = selectedDiscoverStyle === 'collection';
        const isSingleEpisode = selectedDiscoverStyle === 'single_episode';
        const platformList = [
            {
                id: 'ios',
                text: 'iOS',
            },
            {
                id: 'android',
                text: 'Android',
            },
            {
                id: 'web',
                text: 'Web',
            },
            {
                id: 'automotive',
                text: 'Automotive',
            },
        ];

        return (
            <div>
                <StyleEditorWrapper>
                    <FieldWrapper>
                        <FlattenedH4>Discover Style</FlattenedH4>
                        <SingleSelect
                            items={discoverStyleItems}
                            onChange={this.onDiscoverStyleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <FlattenedH4>Full-page Style</FlattenedH4>
                        <SingleSelect
                            items={fullPageStyleItems}
                            onChange={this.onFullPageStyleChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <FlattenedH4>Sponsored</FlattenedH4>
                        <SingleSelect items={sponsoredItems} onChange={this.onSponsoredChange} />
                        {this.isTitleInvalid() && (
                            <TitleErrorDiv>
                                Sponsored list titles must be {MAXIMUM_TITLE_LENGTH_FOR_SPONSORED}{' '}
                                characters or less.
                            </TitleErrorDiv>
                        )}
                    </FieldWrapper>
                    <FieldWrapper>
                        <FlattenedH4>Platform</FlattenedH4>
                        {platformList.map(item => (
                            <div key={item.id}>
                                <label>
                                    <Checkbox
                                        onChange={this.platformOnChange}
                                        value={item.id}
                                        checked={platform.includes(item.id)}
                                    ></Checkbox>
                                    {item.text}
                                </label>
                            </div>
                        ))}
                    </FieldWrapper>
                    <FieldWrapper>
                        <FlattenedH4>Regions</FlattenedH4>
                        <SingleSelect
                            items={allowedRegionsItems}
                            onChange={this.onAllowedRegionsChange}
                        />
                    </FieldWrapper>
                </StyleEditorWrapper>
                {(isCollection || isSingleEpisode) && (
                    <StyleEditorWrapper>
                        <FieldGroupWrapper>
                            <FlattenedH4>Colors</FlattenedH4>
                            <FieldWrapper>
                                <FlattenedH5>On light background</FlattenedH5>
                                {listEditable ? (
                                    <Input
                                        value={colorOnLightBackground}
                                        placeholder="#000000"
                                        onChange={this.onColorOnLightBackgroundChange}
                                    />
                                ) : (
                                    colorOnLightBackground || <i>Empty</i>
                                )}
                            </FieldWrapper>
                            <FieldWrapper>
                                <FlattenedH5>On dark background</FlattenedH5>
                                {listEditable ? (
                                    <Input
                                        value={colorOnDarkBackground}
                                        placeholder="#000000"
                                        onChange={this.onColorOnDarkBackgroundChange}
                                    />
                                ) : (
                                    colorOnDarkBackground || <i>Empty</i>
                                )}
                            </FieldWrapper>
                        </FieldGroupWrapper>
                    </StyleEditorWrapper>
                )}
                {isCollection && (
                    <StyleEditorWrapper>
                        <FieldWrapper>
                            <FlattenedH4>
                                Subtitle<span>*</span>
                            </FlattenedH4>
                            {listEditable ? (
                                <Input
                                    placeholder="Guest List"
                                    width={172}
                                    value={subtitle}
                                    onChange={this.onSubtitleChange}
                                />
                            ) : (
                                subtitle || <i>Empty</i>
                            )}
                        </FieldWrapper>
                        <FieldGroupWrapper>
                            <FlattenedH4>Website</FlattenedH4>
                            <FieldWrapper>
                                <FlattenedH5>URL</FlattenedH5>
                                {listEditable ? (
                                    <Input
                                        value={webUrl}
                                        placeholder="https://"
                                        width={160}
                                        onChange={this.onWebUrlChange}
                                    />
                                ) : (
                                    webUrl || <i>Empty</i>
                                )}
                            </FieldWrapper>
                            <FieldWrapper>
                                <FlattenedH5>Title</FlattenedH5>
                                {listEditable ? (
                                    <Input
                                        value={webTitle}
                                        width={160}
                                        onChange={this.onWebTitleChange}
                                    />
                                ) : (
                                    webTitle || <i>Empty</i>
                                )}
                            </FieldWrapper>
                        </FieldGroupWrapper>
                        <FieldWrapper>
                            <FlattenedH4>Headshot Image</FlattenedH4>
                            {collectionImage && (
                                <CircleImageWrapper>
                                    <S3Image imgKey={`collections/${collectionImage}`} />
                                </CircleImageWrapper>
                            )}
                            <FileInput
                                type="file"
                                accept="image/*"
                                width={172}
                                onChange={this.onCollectionImageChange}
                            />
                            {collectionImageUploading ? (
                                <HintText>Uploading...</HintText>
                            ) : (
                                <HintText>
                                    360 x 360 pixels
                                    <br />
                                    jpg recommended
                                </HintText>
                            )}
                        </FieldWrapper>
                        <FieldWrapper>
                            <FlattenedH4>Header Image</FlattenedH4>
                            {headerImage && (
                                <ImageWrapper>
                                    <S3Image imgKey={`headers/${headerImage}`} />
                                </ImageWrapper>
                            )}
                            <FileInput
                                type="file"
                                accept="image/*"
                                width={172}
                                onChange={this.onHeaderImageChange}
                            />
                            {headerImageUploading && <HintText>Uploading...</HintText>}
                        </FieldWrapper>
                    </StyleEditorWrapper>
                )}
            </div>
        );
    }
}

export default StyleEditor;
