import React, { Component } from 'react';

export default class FeaturedIcon extends Component {
    render() {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.4162 22.2105C15.1519 22.0895 14.8481 22.0895 14.5839 22.2105L8.97206 24.7789C8.2637 25.1031 7.47307 24.5286 7.56251 23.7548L8.27106 17.624C8.30443 17.3352 8.21055 17.0463 8.01386 16.8324L3.83702 12.2889C3.30979 11.7154 3.61179 10.786 4.37542 10.6319L10.4251 9.41122C10.71 9.35374 10.9558 9.17517 11.0985 8.92199L14.1289 3.54557C14.5114 2.86693 15.4887 2.86693 15.8712 3.54557L18.9016 8.92199C19.0443 9.17517 19.29 9.35374 19.5749 9.41122L25.6246 10.6319C26.3883 10.786 26.6903 11.7154 26.163 12.2889L21.9862 16.8324C21.7895 17.0463 21.6956 17.3353 21.729 17.624L22.4375 23.7548C22.527 24.5286 21.7364 25.1031 21.028 24.7789L15.4162 22.2105Z"
                    stroke="currentColor"
                />
            </svg>
        );
    }
}
