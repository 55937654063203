import styled from 'styled-components';
import {
    B80,
    BC80,
    coolGrey50,
    green50,
    gridSize,
    H40,
    H60,
    H80,
    orange50,
} from 'common/pocket-casts-styles';

export const BulkAnalyticsTableWrapper = styled.div``;

export const StyledTable = styled.table`
    width: 100%;
    border-spacing: 0;
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};

    table-layout: fixed;
`;

export const PublishedCol = styled.col`
    width: 280px;
`;

export const ActionsCol = styled.col`
    width: 80px;
`;

export const TrHeader = styled.tr``;

export const Th = styled.th`
    border-top: 1px solid ${props => props.theme.primaryUi05()};
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    padding: ${2 * gridSize}px ${gridSize}px;

    text-align: left;
    text-transform: uppercase;
`;

export const ThText = styled(BC80)`
    color: ${coolGrey50};
`;

export const Tr = styled.tr`
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.primaryUi04()};
    }
`;

export const Td = styled.td`
    padding: ${2 * gridSize}px ${gridSize}px;
`;

export const TdBigText = styled(H40)``;

export const TdMidText = styled(H60)`
    text-align: left;
    margin-right: ${gridSize}px;
    margin-bottom: ${gridSize}px;
`;

export const TdTwoRows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TdSmallColumns = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TdSmallText = styled(H80)`
    display: inline-block;
    color: ${coolGrey50};

    margin-right: ${gridSize}px;
`;

export const TdGreenText = styled(H80)`
    display: inline-block;
    color: ${green50};
`;

export const TdOrangeText = styled(B80)`
    display: inline-block;
    color: ${orange50};
`;
