import React, { Component } from 'react';

class Meatball extends Component {
    render() {
        const { width, height } = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 100 100" fill="currentColor">
                <circle cx="23" cy="50" r="9" />
                <circle cx="50" cy="50" r="9" />
                <circle cx="77" cy="50" r="9" />
            </svg>
        );
    }
}

export default Meatball;
