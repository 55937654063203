import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ListAnalyticsPage from './view';
import { requestList } from '../../../../../state/actions/lists';
import {
    getCurrentList,
    getCurrentListIsLoading,
    getCurrentListType,
} from '../../../../../state/selectors/currentList';
import { requestPublishListToRegions } from '../../../../../state/actions/publish';
import { fetchListAnalytics } from '../../../../../state/actions/analytics';
import {
    getCurrentListAnalyticsData,
    getCurrentListAnalyticsDataIsLoading,
} from '../../../../../state/selectors/analytics';

const mapStateToProps = state => ({
    isLoading: getCurrentListIsLoading(state) || getCurrentListAnalyticsDataIsLoading(state),
    list: getCurrentList(state),
    listType: getCurrentListType(state),
    analytics: getCurrentListAnalyticsData(state),
});

const mapDispatchToProps = dispatch => ({
    requestList: listId => dispatch(requestList(listId)),
    requestListAnalytics: listId => dispatch(fetchListAnalytics(listId)),
    publishListToRegions: (listId, regions) =>
        dispatch(requestPublishListToRegions(listId, regions)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(ListAnalyticsPage),
);
