import styled, { css } from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

const light = '#B8C3C9';
const blue = '#03A9F4';
const red = '#F43E37';

const lightBackground = '#FFFFFF';

const lightBackgroundBlurred = '#FBFCFC';

const backgroundColorMixin = css`
    background-color: ${props => {
        if (props.focused) {
            return `${lightBackground}`;
        }

        return `${lightBackgroundBlurred}`;
    }};
`;

const borderMixin = css`
    border: 1px solid
        ${props => {
            if (!props.isValid) {
                return red;
            }

            if (props.focused) {
                return blue;
            }

            return light;
        }};
`;

export const StyledInput = styled.input`
    width: ${props => props.width}px;
    color: ${props => (props.dark ? 'white' : 'black')};

    appearance: none;
    font-size: 14px;
    height: ${5 * gridSize}px;

    box-sizing: border-box;
    padding: 0 ${2 * gridSize}px;

    ${backgroundColorMixin};
    ${borderMixin};

    border-radius: 8px;

    &:required {
        box-shadow: none;
    }

    &:invalid {
        box-shadow: none;
    }
`;
