/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { STATIC_URL } from 'settings';
import { EPISODE_LIST, PODCAST_LIST } from 'app/state/constants';
import { DateText, DurationText } from 'common/format';
import {
    PodcastListWrapper,
    DroppableContainer,
    DraggableContainer,
    NumberWrapper,
    GrabbyWrapper,
    InformationWrapper,
    ImageWrapper,
    PodcastAuthor,
    PodcastTitle,
    TitleAuthorWrapper,
    DeleteButtonWrapper,
    ItemInfo,
} from './styled';
import DeletePodcastIcon from './delete-podcast-icon.svg';
import GrabbyIcon from './grabby.svg';

const staticImageUrlForUuid = uuid => `${STATIC_URL}/discover/images/140/${uuid}.jpg`;

// A helper function to reorder the podcast list when a podcast is dragged
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export class PodcastList extends Component {
    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.object).isRequired,
        listType: PropTypes.oneOf([EPISODE_LIST, PODCAST_LIST]).isRequired,

        setEpisodes: PropTypes.func.isRequired,
        setPodcasts: PropTypes.func.isRequired,
        deleteEpisodeFromList: PropTypes.func.isRequired,
        deletePodcastFromList: PropTypes.func.isRequired,
    };

    onDragEnd = result => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(
            this.props.items,
            result.source.index,
            result.destination.index,
        );

        this.props.listType === EPISODE_LIST
            ? this.props.setEpisodes(reorderedItems)
            : this.props.setPodcasts(reorderedItems);
    };

    deletePodcastFromListCallback = uuid => () =>
        this.props.listType === EPISODE_LIST
            ? this.props.deleteEpisodeFromList(uuid)
            : this.props.deletePodcastFromList(uuid);

    renderPodcastInfo = podcast => (
        <>
            <ImageWrapper>
                <img width={64} height={64} src={staticImageUrlForUuid(podcast.uuid)} />
            </ImageWrapper>
            <TitleAuthorWrapper>
                <PodcastTitle>{podcast.title}</PodcastTitle>
                <PodcastAuthor>{podcast.author}</PodcastAuthor>
            </TitleAuthorWrapper>
        </>
    );

    renderEpisodeInfo = episode => (
        <>
            <ImageWrapper>
                <img width={64} height={64} src={staticImageUrlForUuid(episode.podcast_uuid)} />
            </ImageWrapper>
            <TitleAuthorWrapper>
                <PodcastTitle>{episode.title}</PodcastTitle>
                <PodcastAuthor>{episode.podcast_title}</PodcastAuthor>
            </TitleAuthorWrapper>
            <ItemInfo>
                <DateText date={episode.published} />
            </ItemInfo>
            <ItemInfo>
                <DurationText duration={episode.duration} />
            </ItemInfo>
        </>
    );

    render() {
        return (
            <PodcastListWrapper>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <DroppableContainer ref={provided.innerRef}>
                                {this.props.items.map((item, index) => (
                                    <Draggable
                                        key={item.uuid}
                                        draggableId={item.uuid}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <DraggableContainer
                                                ref={provided.innerRef}
                                                isDragging={snapshot.isDragging}
                                                style={provided.draggableProps.style}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <InformationWrapper>
                                                    <NumberWrapper>{index + 1}</NumberWrapper>
                                                    <GrabbyWrapper>
                                                        <img
                                                            width={9}
                                                            height={27}
                                                            src={GrabbyIcon}
                                                        />
                                                    </GrabbyWrapper>
                                                    {this.props.listType === EPISODE_LIST
                                                        ? this.renderEpisodeInfo(item)
                                                        : this.renderPodcastInfo(item)}
                                                </InformationWrapper>
                                                <DeleteButtonWrapper
                                                    onClick={this.deletePodcastFromListCallback(
                                                        item.uuid,
                                                    )}
                                                >
                                                    <img
                                                        width="16px"
                                                        height="16px"
                                                        src={DeletePodcastIcon}
                                                    />
                                                </DeleteButtonWrapper>
                                            </DraggableContainer>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </DroppableContainer>
                        )}
                    </Droppable>
                </DragDropContext>
            </PodcastListWrapper>
        );
    }
}

export default PodcastList;
