import styled from 'styled-components';
import { coolGrey60, gridSize, H70 } from 'common/pocket-casts-styles';
import Link from 'common/pocket-casts-components/Link';

export const ListAnalyticsPageWrapper = styled.div``;

export const HeroRowWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ListNameWrapper = styled.div`
    flex-grow: 1;
`;

export const ListActionsWrapper = styled.div`
    display: flex;
    align-items: center;

    position: relative;

    @media print {
        display: none;
    }
`;

export const MetadataWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: ${gridSize}px;
`;

export const NumberWrapper = styled(H70)`
    color: ${coolGrey60};
    margin-right: ${gridSize}px;
`;

export const LinkWrapper = styled(Link)``;

export const DatePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: ${2 * gridSize}px;
`;

export const TableWrapper = styled.div`
    margin-bottom: ${3 * gridSize}px;
`;

/* Meatball button */

export const MeatballButtonWrapper = styled.div``;

export const MeatballWrapper = styled.div`
    display: flex;
    width: ${4 * gridSize}px;
    height: ${5 * gridSize}px;
    align-items: center;
    justify-content: center;
`;

export const MeatballPopoverTriangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;

    right: ${props => (props.discoverButtonExists ? 300 : 135)}px;

    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid grey;
`;

export const MeatballPopoverWrapper = styled.div`
    display: flex;
    position: absolute;
    width: 160px;

    top: 56px;
    right: ${props => (props.discoverButtonExists ? 228 : 63)}px;

    flex-direction: column;

    background-color: white;
    border-radius: ${gridSize}px;
    border 1px solid gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export const PopoverInset = styled.div`
    padding: ${gridSize / 2}px;
`;

export const CopyListLinkItem = styled.div`
    display: flex;
    height: 32px;

    justify-content: center;
    border-radius: ${gridSize / 2}px;
    cursor: pointer;

    &:hover {
        background-color: lightgray;
    }
`;

export const PopoverItemImageWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${gridSize}px;
`;

export const PopoverItemTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
