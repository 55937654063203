import { API } from 'aws-amplify';
import { from } from 'rxjs';

export const fetchLiveAnalytics = () =>
    from(API.get('contentcurator', '/lists/analytics/live', {}));

export const fetchSummaryAnayltics = () =>
    from(API.get('contentcurator', '/lists/analytics/summary', {}));

export const fetchListAnalytics = listId =>
    from(API.get('contentcurator', `/lists/analytics/list/${listId}`, {}));
