import styled from 'styled-components';
import { black } from 'common/pocket-casts-styles';

export const BulletWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${props => props.size}px;
    height: ${props => props.size}px;
    min-width: ${props => props.size}px;
    min-height: ${props => props.size}px;
    max-width: ${props => props.size}px;
    max-height: ${props => props.size}px;

    border: 2px solid ${props => props.color || black};
    border-radius: 50%;
    box-sizing: content-box;
`;

export const BulletInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${props => props.size}px;
    height: ${props => props.size}px;
    min-width: ${props => props.size}px;
    min-height: ${props => props.size}px;
    max-width: ${props => props.size}px;
    max-height: ${props => props.size}px;

    background-color: ${props => props.color || black};
    border-radius: 50%;
`;
