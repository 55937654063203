import React, { Component } from 'react';
import Moment from 'moment';
import Bullet from 'common/pocket-casts-components/Bullet';
import { getThemeFromId, THEME } from 'common/pocket-casts-styles/themes';
import { HeaderWrapper, Left, Right, StatusText } from './styled';
import BackButton from './BackButton';

const dmy = 'D MMM YYYY';

class Header extends Component {
    getBulletColor = () => {
        const {
            list: { publishDate, unpublishDate },
        } = this.props;

        if (!publishDate) {
            return getThemeFromId(THEME.light).primaryText02();
        }

        if (!unpublishDate) {
            return getThemeFromId(THEME.light).support02();
        }

        return getThemeFromId(THEME.light).support04();
    };

    getStatus1Text = () => {
        const {
            list: { publishDate, unpublishDate },
        } = this.props;

        if (!publishDate) {
            return 'Never published';
        }

        const publishMoment = Moment(new Date(publishDate));

        if (!unpublishDate) {
            return publishMoment.fromNow();
        }

        const unpublishMoment = Moment(new Date(unpublishDate));
        const diff = Math.round(unpublishMoment.diff(publishMoment, 'days', true));

        return `${diff} days`;
    };

    getStatus2Text = () => {
        const {
            list: { publishDate, unpublishDate },
        } = this.props;

        const publishMoment = Moment(new Date(publishDate));

        if (!unpublishDate) {
            return `Published ${publishMoment.format(dmy)}`;
        }

        const unpublishMoment = Moment(new Date(unpublishDate));

        return `Published from ${publishMoment.format(dmy)} - ${unpublishMoment.format(dmy)}`;
    };

    renderStatus2IfNecessary() {
        const {
            list: { publishDate },
        } = this.props;

        if (!publishDate) {
            return null;
        }

        return (
            <StatusText color={getThemeFromId(THEME.light).primaryText02()}>
                {this.getStatus2Text()}
            </StatusText>
        );
    }

    renderStatus1() {
        return <StatusText color={this.getBulletColor()}>{this.getStatus1Text()}</StatusText>;
    }

    renderBullet() {
        return <Bullet color={this.getBulletColor()} />;
    }

    render() {
        return (
            <HeaderWrapper>
                <Left>
                    <BackButton />
                </Left>
                <Right>
                    {this.renderStatus2IfNecessary()}
                    {this.renderStatus1()}
                    {this.renderBullet()}
                </Right>
            </HeaderWrapper>
        );
    }
}

export default Header;
