import styled from 'styled-components';
import { BC80, gridSize } from '../../pocket-casts-styles';

export const DateRangeInputsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    input:focus {
        outline: 0;
    }
`;

export const Dash = styled(BC80)`
    color: ${props => props.theme.primaryText02()};
    margin: 0 ${gridSize}px;
`;
