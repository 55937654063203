import React, { Component } from 'react';
import PropTypes from 'prop-types';
import zipObject from 'lodash/zipObject';
import RegionFlag from 'common/pocket-casts-components/RegionFlag';
import Button from 'common/pocket-casts-components/Button';
import Checkbox from 'common/pocket-casts-components/Checkbox';
import { REGIONS } from 'app/state/constants';
import Link from 'common/pocket-casts-components/Link';
import {
    Overlay,
    VerticallyCenter,
    DialogWrapper,
    ButtonsWrapper,
    RegionGrid,
    RegionWrapper,
    CheckboxWrapper,
    SelectionLinkWrapper,
} from './styled';

export class LayoutCopyDialog extends Component {
    static propTypes = {
        region: PropTypes.string.isRequired,
        onCancel: PropTypes.func.isRequired,
        onCopy: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        const allRegionsButThis = REGIONS.filter(r => r !== this.props.region);
        const regions = zipObject(allRegionsButThis, allRegionsButThis.map(() => true));

        this.state = {
            regions,
        };
    }

    isChecked = region => this.state.regions[region];

    uncheck = region => {
        this.setState({
            regions: {
                ...this.state.regions,
                [`${region}`]: false,
            },
        });
    };

    check = region => {
        this.setState({
            regions: {
                ...this.state.regions,
                [`${region}`]: true,
            },
        });
    };

    toggle = region => {
        if (this.isChecked(region)) {
            this.uncheck(region);
        } else {
            this.check(region);
        }
    };

    selectAll = () => {
        const { regions } = this.state;
        const regionKeys = Object.keys(regions);
        const newRegions = zipObject(regionKeys, regionKeys.map(() => true));

        this.setState({
            regions: newRegions,
        });
    };

    unselectAll = () => {
        const { regions } = this.state;
        const regionKeys = Object.keys(regions);
        const newRegions = zipObject(regionKeys, regionKeys.map(() => false));

        this.setState({
            regions: newRegions,
        });
    };

    render() {
        const { regions } = this.state;
        const otherRegions = Object.keys(regions);
        const selectedRegions = otherRegions.filter(region => regions[region]);

        return (
            <Overlay>
                <VerticallyCenter>
                    <DialogWrapper>
                        <ButtonsWrapper>
                            <Button onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={() => this.props.onCopy(selectedRegions)}>
                                Copy to {selectedRegions.length} regions
                            </Button>
                        </ButtonsWrapper>
                        <RegionGrid>
                            {otherRegions.map(region => (
                                <RegionWrapper key={region}>
                                    <CheckboxWrapper>
                                        <Checkbox
                                            checked={this.isChecked(region)}
                                            onChange={() => this.toggle(region)}
                                        />
                                    </CheckboxWrapper>
                                    <RegionFlag
                                        key={`${region}-flag`}
                                        region={region}
                                        onClick={() => this.toggle(region)}
                                    />
                                </RegionWrapper>
                            ))}
                            <SelectionLinkWrapper>
                                <Link onClick={this.selectAll}>Select All</Link>
                            </SelectionLinkWrapper>
                            <SelectionLinkWrapper>
                                <Link onClick={this.unselectAll}>Unselect All</Link>
                            </SelectionLinkWrapper>
                        </RegionGrid>
                    </DialogWrapper>
                </VerticallyCenter>
            </Overlay>
        );
    }
}

export default LayoutCopyDialog;
