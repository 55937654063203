import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { coolGrey50, B60, H60, gridSize } from 'common/pocket-casts-styles';

export const NavigationOptionWrapper = styled.div`
    display: flex;
    height: ${6 * gridSize}px;
    align-items: center;
`;

export const DisabledNavigationOptionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NotchWrapper = styled.div`
    width: ${gridSize}px;
    height: 46px;
`;

export const NavigationOptionLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;

    color: ${props => props.color};

    margin-left: ${props => (props.selected ? 0 : `${-gridSize}px`)};
    transition: margin-left 0.1s ease-in-out;

    &:hover {
        margin-left: 0;
    }
`;

export const IconWrapper = styled.div`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    margin-left: ${3 * gridSize}px;
    margin-right: ${2 * gridSize}px;
`;

export const SelectedTextWrapper = styled(B60)``;
export const UnselectedTextWrapper = styled(H60)``;

export const DisabledIconWrapper = styled(IconWrapper)`
    color: ${coolGrey50};
`;

export const DisabledTextWrapper = styled(H60)`
    color: ${coolGrey50};
`;
