import { connect } from 'react-redux';
import {
    editListSetDiscoverStyle,
    editListSetFullPageStyle,
    editListSetSponsored,
    editListSetSubtitle,
    editListSetWebUrl,
    editListSetWebTitle,
    editListSetColorOnLightBackground,
    editListSetColorOnDarkBackground,
    editListSetCollectionImage,
    editListSetHeaderImage,
    editListSetAllowedRegions,
    editCollectionImageUploading,
    editHeaderImageUploading,
    editListSetPlatform,
} from 'app/state/actions/currentList';
import {
    getCurrentListId,
    getCurrentListDiscoverStyle,
    getCurrentListFullPageStyle,
    getCurrentListIsSponsored,
    getCurrentListTitle,
    getCurrentListSubtitle,
    getCurrentListColorOnLightBackground,
    getCurrentListColorOnDarkBackground,
    getCurrentListCollectionImageUploading,
    getCurrentListHeaderImageUploading,
    getCurrentListWebUrl,
    getCurrentListWebTitle,
    getCurrentListCollectionImage,
    getCurrentListHeaderImage,
    getCurrentListPlatform,
    getCurrentListAllowedRegions,
    getCurrentListListEditable,
    getCurrentListType,
} from 'app/state/selectors/currentList';
import { requestUpdateList } from 'app/state/actions/lists';
import StyleEditor from './view';

const mapStateToProps = state => ({
    listId: getCurrentListId(state),
    selectedDiscoverStyle: getCurrentListDiscoverStyle(state),
    selectedFullPageStyle: getCurrentListFullPageStyle(state),
    isSponsored: getCurrentListIsSponsored(state),
    title: getCurrentListTitle(state),
    subtitle: getCurrentListSubtitle(state),
    colorOnLightBackground: getCurrentListColorOnLightBackground(state),
    colorOnDarkBackground: getCurrentListColorOnDarkBackground(state),
    webUrl: getCurrentListWebUrl(state),
    webTitle: getCurrentListWebTitle(state),
    allowedRegions: getCurrentListAllowedRegions(state),
    collectionImageUploading: getCurrentListCollectionImageUploading(state),
    headerImageUploading: getCurrentListHeaderImageUploading(state),
    collectionImage: getCurrentListCollectionImage(state),
    headerImage: getCurrentListHeaderImage(state),
    platform: getCurrentListPlatform(state),
    listEditable: getCurrentListListEditable(state),
    listType: getCurrentListType(state),
});

const mapDispatchToProps = dispatch => ({
    saveDiscoverStyle: discoverStyle => dispatch(editListSetDiscoverStyle(discoverStyle)),
    saveFullPageStyle: fullPageStyle => dispatch(editListSetFullPageStyle(fullPageStyle)),
    saveAllowedRegions: allowedRegions => dispatch(editListSetAllowedRegions(allowedRegions)),
    saveSponsored: sponsored => dispatch(editListSetSponsored(sponsored)),
    saveSubtitle: subtitle => dispatch(editListSetSubtitle(subtitle)),
    saveWebUrl: webUrl => dispatch(editListSetWebUrl(webUrl)),
    saveWebTitle: webTitle => dispatch(editListSetWebTitle(webTitle)),
    saveColorOnLightBackground: colorOnLightBackground =>
        dispatch(editListSetColorOnLightBackground(colorOnLightBackground)),
    saveColorOnDarkBackground: colorOnDarkBackground =>
        dispatch(editListSetColorOnDarkBackground(colorOnDarkBackground)),
    saveCurrentList: () => dispatch(requestUpdateList()),
    saveCollectionImage: collectionImage => dispatch(editListSetCollectionImage(collectionImage)),
    saveHeaderImage: headerImage => dispatch(editListSetHeaderImage(headerImage)),
    setCollectionImageUploading: collectionImageUploading =>
        dispatch(editCollectionImageUploading(collectionImageUploading)),
    setHeaderImageUploading: headerImageUploading =>
        dispatch(editHeaderImageUploading(headerImageUploading)),
    savePlatform: (platform, checked) => dispatch(editListSetPlatform(platform, checked)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyleEditor);
