import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const Overlay = styled.div`
    display: table;
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    z-index: 100;

    background-color: rgba(0, 0, 0, 0.75);
`;

export const VerticallyCenter = styled.div`
    display: table-cell;
    vertical-align: middle;
`;

export const DialogWrapper = styled.div`
    display: block;
    width: 700px;

    background-color: white;
    border-radius: 4px;
    margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    height: ${5 * gridSize}px;

    justify-content: space-between;
    padding: ${gridSize}px;
`;

export const RegionWrapper = styled.div`
    display: flex;
    align-items: center;

    padding-bottom: ${gridSize}px;
`;

export const CheckboxWrapper = styled.div`
    padding-right: ${gridSize}px;
`;

export const RegionGrid = styled.div`
    display: flex;
    height: ${gridSize * 42}px;

    flex-direction: column;
    flex-wrap: wrap;

    align-items: stretch;

    padding: ${gridSize}px;
`;

export const SelectionLinkWrapper = styled.div`
    padding-top: ${gridSize}px;
    padding-left: ${9.5 * gridSize}px;
`;
