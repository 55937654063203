import PropTypes from 'prop-types';
import Modal from './view';

Modal.propTypes = {
    horizontalInsetPx: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    blurred: PropTypes.bool,
};

Modal.defaultProps = {
    blurred: false,
};

export default Modal;
