import { connect } from 'react-redux';
import { requestList, requestUpdateList } from 'app/state/actions/lists';
import {
    getCurrentListIsLoading,
    getCurrentListListEditable,
} from 'app/state/selectors/currentList';
import { requestPublishListToRegions } from 'app/state/actions/publish';
import EditListPage from './view';

const mapStateToProps = state => ({
    isLoading: getCurrentListIsLoading(state),
    listEditable: getCurrentListListEditable(state),
});

const mapDispatchToProps = dispatch => ({
    requestList: listId => dispatch(requestList(listId)),
    saveCurrentList: () => dispatch(requestUpdateList(true)), // Immediately send the request
    publishListToRegions: (listId, regions) =>
        dispatch(requestPublishListToRegions(listId, regions)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditListPage);
