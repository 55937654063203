import styled from 'styled-components';
import { gridSize, hoverStateTransitionMixin } from '../../pocket-casts-styles';

export const ModalWrapper = styled.div`
    position: fixed !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    max-width: none !important;
`;

export const ModalDialog = styled.div`
    display: flex;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
    border-radius: 6px;
    z-index: 1002;
    background-color: ${props => props.theme.primaryUi01()};

    ${props => props.blurred && `filter: blur(5px);`}
`;

export const ModalCoverBackground = styled.div`
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: ${props => props.theme.overlay()};
    z-index: 1001;
`;

export const Pad = styled.div`
    width: 100%;
    height: ${2 * gridSize}px;
`;

export const CrossWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
`;

export const Cross = styled.div`
    width: 26px;
    height: 26px;
    cursor: pointer;
    margin-right: ${2 * gridSize}px;

    color: ${props => props.theme.primaryIcon02()};
    &:hover {
        color: ${props => props.theme.primaryIcon02Active()};
    }

    ${hoverStateTransitionMixin};
`;

export const ChildrenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: ${props => props.horizontalInset}px;
    margin-right: ${props => props.horizontalInset}px;
    margin-top: ${gridSize}px;
`;
