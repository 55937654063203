import React, { Component } from 'react';
import { BulletWrapper, BulletInner } from './styled';

class Bullet extends Component {
    render() {
        const { size, color } = this.props;

        return (
            <BulletWrapper color={color} size={size - 2}>
                <BulletInner color={color} size={size - 6} />
            </BulletWrapper>
        );
    }
}

export default Bullet;
