// Register locale data with react-intl. More supported languages can be added by
// importing more locale data and passing that data to the addLocaleData function.

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

let added = false;

export default () => {
    if (added) {
        return;
    }

    addLocaleData([...en]);

    added = true;
};
