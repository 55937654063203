import React, { Component } from 'react';
import Button from 'common/pocket-casts-components/Button/view';
import SearchBar from 'common/pocket-casts-components/SearchBar';
import LoadingIndicator from 'common/pocket-casts-components/LoadingIndicator';
import { EPISODE_LIST, PODCAST_LIST } from 'app/state/constants';
import { ActionsWrapper, SearchBarWrapper, ButtonText, ButtonSeparator } from './styled';
import ImportModal from '../ImportModal';

class Actions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showImportModal: false,
        };
    }

    importList = () => {
        this.setState({ showImportModal: true });
    };

    onCloseImportModal = () => {
        this.setState({ showImportModal: false });
    };

    onImport = bundleUuid => {
        this.setState({ showImportModal: false });
        this.props.importList(bundleUuid);
    };

    render() {
        const { listsAreLoading, isCreatingList } = this.props;
        const { showImportModal } = this.state;

        return (
            <ActionsWrapper>
                <SearchBarWrapper>
                    <SearchBar onSearchTermChange={this.props.onSearchTermChange} />
                </SearchBarWrapper>
                {listsAreLoading && <LoadingIndicator size={40} />}
                <Button width={160} onClick={this.importList}>
                    <ButtonText>Import</ButtonText>
                </Button>
                <ButtonSeparator />
                <Button
                    width={160}
                    loading={isCreatingList}
                    onClick={() => this.props.createNewList(PODCAST_LIST)}
                >
                    <ButtonText>New Podcast List</ButtonText>
                </Button>
                <ButtonSeparator />
                <Button
                    width={160}
                    loading={isCreatingList}
                    onClick={() => this.props.createNewList(EPISODE_LIST)}
                >
                    <ButtonText>New Episode List</ButtonText>
                </Button>
                {showImportModal && (
                    <ImportModal onClose={this.onCloseImportModal} onImport={this.onImport} />
                )}
            </ActionsWrapper>
        );
    }
}

export default Actions;
