import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const DatePickerWrapper = styled.div`
    input {
        appearance: none;
        display: inline-block;

        width: 160px;
        height: 36px;

        box-sizing: border-box;

        background-color: ${props => props.theme.primaryField02()};
        border: 1px solid ${props => props.theme.primaryField03()};
        border-radius: ${gridSize}px;

        color: ${props => props.theme.primaryText02()};

        font-size: 14px; /* H70 */

        padding-left: 11px;
        padding-right: 8px;

        outline: none;

        &[disabled] {
            border-style: dashed;
            background-color: transparent;
        }
    }
`;
