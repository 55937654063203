import React, { Component } from 'react';
import Switcher from 'common/pocket-casts-components/Switcher';
import {
    HeaderWrapper,
    Left,
    Right,
    RefreshDetailsAvailable,
    RefreshDetailsUnavailable,
} from './styled';

export class Header extends Component {
    onSelectSwitcherItem = index => {
        if (index === 0 && this.props.managePageSelected) {
            this.props.managePageSelected();
        }

        if (index === 1 && this.props.analyticsPageSelected) {
            this.props.analyticsPageSelected();
        }
    };

    getDataRefreshedDate = () => {
        if (this.props.dateUpdatedMoment) {
            return this.props.dateUpdatedMoment.format('DD MMM YYYY');
        }
        return '';
    };

    getDataRefreshedHowLongAgo = () => {
        if (this.props.dateUpdatedMoment) {
            return this.props.dateUpdatedMoment.fromNow();
        }
        return '';
    };

    render() {
        const { hasLoadedAnalyticsData, dateUpdatedMoment, selectedListTab } = this.props;
        const tabIndex = selectedListTab === 'analytics' ? 1 : 0;
        return (
            <HeaderWrapper>
                <Left>
                    <h1>Lists</h1>
                </Left>
                <Right>
                    {!hasLoadedAnalyticsData && !dateUpdatedMoment && (
                        <RefreshDetailsUnavailable>
                            <p className="refresh-date">Data Refreshed</p>
                            <p className="refresh-duration">N/A</p>
                        </RefreshDetailsUnavailable>
                    )}

                    {!hasLoadedAnalyticsData && dateUpdatedMoment && (
                        <RefreshDetailsAvailable>
                            <p className="refresh-date">
                                Data Refreshed {this.getDataRefreshedDate()}
                            </p>
                            <p className="refresh-duration">{this.getDataRefreshedHowLongAgo()}</p>
                        </RefreshDetailsAvailable>
                    )}
                    <Switcher
                        items={['Manage Lists', 'Analytics']}
                        defaultSelectedIndex={tabIndex}
                        selectedItemAtIndex={this.onSelectSwitcherItem}
                    />
                </Right>
            </HeaderWrapper>
        );
    }
}

export default Header;
