import React, { Component } from 'react';
import Modal from 'common/pocket-casts-components/Modal';
import Button from 'common/pocket-casts-components/Button';
import { Spacer, ButtonsWrapper, Title } from './styled';
import Input from '../../../../../../../../common/pocket-casts-components/Input';

class ImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bundleUuid: null,
        };
    }

    onBundleUuidChange = event => {
        this.setState({
            bundleUuid: event.target.value,
        });
    };

    onImportClick = () => {
        const { bundleUuid } = this.state;
        if (bundleUuid != null && bundleUuid.length > 0) {
            this.props.onImport(bundleUuid);
        }
    };

    render() {
        const { onClose } = this.props;
        return (
            <Modal onClose={onClose} horizontalInsetPx={32}>
                <Title>Bundle UUID</Title>
                <Input onChange={this.onBundleUuidChange} width={400} />
                <Spacer px={16} />
                <ButtonsWrapper>
                    <Button kind="secondary" width={120} onClick={onClose}>
                        Cancel
                    </Button>
                    <Spacer px={16} />
                    <Button width={120} onClick={this.onImportClick}>
                        Import
                    </Button>
                </ButtonsWrapper>
            </Modal>
        );
    }
}

export default ImportModal;
