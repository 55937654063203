import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { EPISODE_LIST, PODCAST_LIST } from 'app/state/constants';
import { DateText, DurationText } from 'common/format';
import LoadingIndicator from 'common/pocket-casts-components/LoadingIndicator';
import { PODCAST_CACHE_URL, STATIC_URL } from 'settings';
import {
    PodcastSearchWrapper,
    PodcastSearchBarWrapper,
    PodcastSuggestionBoxMarker,
    PodcastSearchBox,
    SearchSuggestionsBox,
    SearchErrorWrapper,
    SearchIconWrapper,
    NoResultsWrapper,
    EpisodeSuggestion,
    EpisodeTitle,
    EpisodeCell,
    LoadingEpisodes,
} from './styled';

import PodcastSuggestion from './PodcastSuggestion';
import SearchIcon from './search-icon.svg';

// TODO: This component is now handling both Podcast and Episode lists. It would be good in
// a cleanup spike to rename this all to be more generic. (There may also be a better way to
// compose these into different components without duplicating too much.)

export class PodcastSearch extends Component {
    static propTypes = {
        listType: PropTypes.oneOf([PODCAST_LIST, EPISODE_LIST]).isRequired,
        podcastSearchResults: PropTypes.any.isRequired,
        podcastSearchIsLoading: PropTypes.bool.isRequired,
        podcastSearchError: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            selectedPodcast: null,
            podcastDetails: {},
            popoverVisible: false,
        };

        this.debouncedFetch = debounce(term => {
            this.props.fetchSuggestions(term);
        }, 250);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.selectedPodcast &&
            prevState.selectedPodcast !== this.state.selectedPodcast &&
            !this.state.podcastDetails[this.state.selectedPodcast]
        ) {
            const uuid = this.state.selectedPodcast;
            fetch(`${PODCAST_CACHE_URL}/mobile/podcast/full/${uuid}`)
                .then(response => response.json())
                .then(podcast =>
                    this.setState({
                        podcastDetails: {
                            ...this.state.podcastDetails,
                            [uuid]: podcast,
                        },
                    }),
                );
        }
    }

    onFocus = () => {
        const { searchTerm } = this.state;
        if (searchTerm) {
            this.setState({ popoverVisible: true });
        }
    };

    selectPodcast = podcast => {
        if (this.props.listType === EPISODE_LIST) {
            this.setState({ selectedPodcast: podcast.uuid });
        } else {
            this.addPodcastToList(podcast.uuid, podcast.title, podcast.author);
        }
    };

    clearSelectedPodcast = () => {
        this.setState({ selectedPodcast: null });
    };

    searchForPodcasts = term => {
        if (term.trim()) {
            this.debouncedFetch(term);
        }
    };

    handleChange = event => {
        this.searchForPodcasts(event.target.value);

        this.setState({
            searchTerm: event.target.value,
            selectedPodcast: null,
            popoverVisible: !!event.target.value.length,
        });
    };

    handleEpisodeClick = (podcast, episode) => {
        // eslint-disable-next-line camelcase
        const { uuid, url, published, duration, file_type, title, file_size, type } = episode;
        const episodeRecord = {
            uuid,
            url,
            published,
            duration,
            file_type,
            title,
            size: file_size,
            podcast_uuid: podcast.uuid,
            podcast_title: podcast.title,
            type,
            // season,
            // number,
        };
        this.props.addEpisodeToList(episodeRecord);
        this.dismissPopover();
    };

    // This is a bit of hack because if a user clicks on a suggestion, the input field blurs, but
    // if we hide the dropdown immediately on blur, we can't click on any of the suggestions!
    dismissPopover = () => {
        this.setState({ popoverVisible: false });
    };

    maybeDismissPopover = () => {
        if (this.props.listType === PODCAST_LIST) {
            setTimeout(this.dismissPopover.bind(this), 1);
        }
    };

    handleKeyPress = event => {
        if (event.key === 'Enter') {
            this.searchForPodcasts();
        }
    };

    addPodcastToList = (uuid, title, author) =>
        this.props.addPodcastToList({ uuid, title, author });

    renderEpisodes(podcast, episodes) {
        return (
            <div>
                {episodes.map(episode => (
                    <EpisodeSuggestion
                        key={episode.uuid}
                        onClick={() => this.handleEpisodeClick(podcast, episode)}
                    >
                        <EpisodeTitle>{episode.title}</EpisodeTitle>
                        <EpisodeCell>
                            <DateText date={episode.published} />
                        </EpisodeCell>
                        <EpisodeCell>
                            <DurationText duration={episode.duration} />
                        </EpisodeCell>
                    </EpisodeSuggestion>
                ))}
            </div>
        );
    }

    renderEpisodeSuggestions() {
        const { podcastSearchResults } = this.props;
        const { selectedPodcast, podcastDetails } = this.state;
        const podcast = podcastSearchResults.find(({ uuid }) => uuid === selectedPodcast);
        const details = podcastDetails[selectedPodcast];

        if (!podcast) {
            console.log({ podcastSearchResults, selectedPodcast, podcastDetails });
            return 'Unexpected error...';
        }

        return (
            <div>
                <PodcastSuggestion
                    title={podcast.title}
                    author={podcast.author}
                    imageUrl={`${STATIC_URL}/discover/images/140/${podcast.uuid}.jpg`}
                    onMouseDown={() => this.clearSelectedPodcast()}
                    isSelected={true}
                    showBackButton={true}
                />
                {!details ? (
                    <LoadingEpisodes>
                        <LoadingIndicator size={50} />
                        Loading episodes...
                    </LoadingEpisodes>
                ) : (
                    this.renderEpisodes(podcast, details.podcast.episodes)
                )}
            </div>
        );
    }

    renderSuggestions() {
        const {
            listType,
            podcastSearchIsLoading,
            podcastSearchError,
            podcastSearchResults,
        } = this.props;
        const { searchTerm, selectedPodcast } = this.state;

        if (selectedPodcast) {
            return this.renderEpisodeSuggestions();
        }

        if (
            podcastSearchIsLoading ||
            (searchTerm.trim().length < 2 && podcastSearchResults.length === 0)
        ) {
            return null;
        }

        if (podcastSearchError) {
            return <SearchErrorWrapper>{`Error: ${podcastSearchError}`}</SearchErrorWrapper>;
        }

        if (podcastSearchResults.length === 0) {
            return <NoResultsWrapper>No results.</NoResultsWrapper>;
        }

        return podcastSearchResults.map(result => (
            <PodcastSuggestion
                key={result.uuid}
                title={result.title}
                author={result.author}
                imageUrl={`${STATIC_URL}/discover/images/140/${result.uuid}.jpg`}
                onMouseDown={() => this.selectPodcast(result)}
                selectText={listType === EPISODE_LIST ? 'Show episodes' : null}
            />
        ));
    }

    render() {
        const { listType, podcastSearchIsLoading, podcastSearchError } = this.props;
        const { searchTerm, popoverVisible } = this.state;

        return (
            <PodcastSearchWrapper>
                <PodcastSearchBarWrapper>
                    <SearchIconWrapper>
                        {podcastSearchIsLoading || podcastSearchError ? (
                            <LoadingIndicator />
                        ) : (
                            <img width="24px" height="24px" src={SearchIcon} />
                        )}
                    </SearchIconWrapper>
                    <PodcastSearchBox
                        value={searchTerm}
                        placeholder={
                            listType === EPISODE_LIST
                                ? 'Search for Podcasts to find Episodes'
                                : 'Search for Podcasts'
                        }
                        onFocus={this.onFocus}
                        onChange={this.handleChange}
                        onBlur={this.maybeDismissPopover}
                        onKeyPress={this.handleKeyPress}
                    />
                </PodcastSearchBarWrapper>
                <PodcastSuggestionBoxMarker>
                    <SearchSuggestionsBox visible={popoverVisible}>
                        {this.renderSuggestions()}
                    </SearchSuggestionsBox>
                </PodcastSuggestionBoxMarker>
            </PodcastSearchWrapper>
        );
    }
}

export default PodcastSearch;
