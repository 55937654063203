import styled from 'styled-components';

export const StyledSelect = styled.select`
    /*
    appearance: none;
    border: 1px solid gray;
    border-radius: 2px;

    font-size: 18px;
    */
`;

export const StyledOption = styled.option`
    /*
    background-color: yellow;
    */
`;
