import { freeze, setIn } from 'icepick';
import {
    LISTS_REQUEST,
    LISTS_REQUEST_SUCCESS,
    LISTS_REQUEST_FAILURE,
    LIST_ARCHIVE_REQUEST_SUCCESS,
    LIST_ARCHIVE_REQUEST_FAILURE,
} from 'app/state/actions/lists';

export const INITIAL_STATE = freeze({
    podcastLists: [],
    isLoading: true,
    isError: false,
    sortField: 'createdDate',
    sortOrder: 'descending',
});

// lists reducer
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case LISTS_REQUEST:
            return freeze({
                podcastLists: [],
                isLoading: true,
                isError: false,
                sortField: state.sortField,
                sortOrder: state.sortOrder,
            });
        case LISTS_REQUEST_SUCCESS:
            return freeze({
                podcastLists: payload.lists,
                isLoading: false,
                isError: false,
                sortField: state.sortField,
                sortOrder: state.sortOrder,
            });
        case LISTS_REQUEST_FAILURE:
            return freeze({
                podcastLists: [],
                isLoading: false,
                isError: true,
                sortField: state.sortField,
                sortOrder: state.sortOrder,
            });
        case LIST_ARCHIVE_REQUEST_SUCCESS: {
            const { listId, archive } = payload;

            const lists = state.podcastLists.slice();
            const index = lists.findIndex(list => list.id === listId);

            if (index === -1) {
                return state;
            }

            const newLists = lists.map((list, idx) =>
                idx !== index ? list : { ...list, archived: archive },
            );

            return setIn(state, ['podcastLists'], newLists);
        }
        case LIST_ARCHIVE_REQUEST_FAILURE:
            return state;
        default:
            return state;
    }
};
