import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'common/pocket-casts-components/LoadingIndicator';
import debounce from 'lodash/debounce';
import {
    ListSearchWrapper,
    SearchIconWrapper,
    ListSearchBarWrapper,
    ListSearchBox,
    ListSuggestionBoxMarker,
    SearchSuggestionsBox,
    NoResultsWrapper,
} from './styled';
import SearchIcon from './search-icon.svg';
import ListSuggestion from './ListSuggestion';
import { SearchErrorWrapper } from '../../../Lists/EditListPage/PodcastSearch/styled';
import { DIFF_ATTRIBUTES } from '../../../../../../state/constants';

export class ListSearch extends Component {
    static propTypes = {
        listSearchResults: PropTypes.any.isRequired,
        listSearchIsLoading: PropTypes.bool.isRequired,
        listSearchError: PropTypes.string,
        listsInLayout: PropTypes.any.isRequired,
        layoutRegion: PropTypes.string.isRequired,

        addListToLayout: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            popoverVisible: false,
        };

        this.debouncedFetch = debounce(() => {
            this.props.fetchSuggestions(this.state.searchTerm);
        }, 500);
    }

    onFocus = () => {
        const { searchTerm } = this.state;
        if (searchTerm) {
            this.setState({ popoverVisible: true });
        }
    };

    searchForLists = () => {
        if (this.state.searchTerm.trim()) {
            this.debouncedFetch();
        }
    };

    handleChange = event => {
        this.searchForLists();

        this.setState({
            searchTerm: event.target.value,
            popoverVisible: !!event.target.value.length,
        });
    };

    // This is a bit of hack because if a user clicks on a suggestion, the input field blurs, but
    // if we hide the dropdown immediately on blur, we can't click on any of the suggestions!
    dismissPopoverImpl = () => {
        this.setState({ popoverVisible: false });
    };

    dismissPopover = () => {
        setTimeout(this.dismissPopoverImpl.bind(this), 1);
    };

    handleKeyPress = event => {
        if (event.key === 'Enter') {
            this.props.fetchSuggestions(this.state.searchTerm);
        }
    };

    renderSuggestions() {
        const {
            listSearchResults,
            listSearchIsLoading,
            listSearchError,
            listsInLayout,
            layoutRegion,
        } = this.props;

        const { searchTerm } = this.state;

        const visibleResults = listSearchResults.filter(result => {
            const indexOf = listsInLayout.findIndex(list => list.id === result.id);
            return indexOf === -1;
        });

        if (listSearchIsLoading || (searchTerm.trim().length < 2 && visibleResults.length === 0)) {
            return null;
        }

        if (listSearchError) {
            return <SearchErrorWrapper>{`Error: ${listSearchError}`}</SearchErrorWrapper>;
        }

        if (visibleResults.length === 0) {
            return <NoResultsWrapper>No results.</NoResultsWrapper>;
        }

        return visibleResults.map(list => (
            <ListSuggestion
                key={list.title + list.creator}
                title={list.title}
                author={list.creator}
                onMouseDown={() =>
                    this.props.addListToLayout(
                        { ...list, diff: DIFF_ATTRIBUTES.added },
                        layoutRegion,
                    )
                }
            />
        ));
    }

    render() {
        const { listSearchIsLoading, listSearchError } = this.props;
        const { searchTerm, popoverVisible } = this.state;

        return (
            <ListSearchWrapper>
                <ListSearchBarWrapper>
                    <SearchIconWrapper>
                        {listSearchIsLoading || listSearchError ? (
                            <LoadingIndicator />
                        ) : (
                            <img width="24px" height="24px" src={SearchIcon} />
                        )}
                    </SearchIconWrapper>
                    <ListSearchBox
                        placeholder="Find lists"
                        value={searchTerm}
                        onFocus={this.onFocus}
                        onChange={this.handleChange}
                        onBlur={this.dismissPopover}
                        onKeyPress={this.handleKeyPress}
                    />
                </ListSearchBarWrapper>
                <ListSuggestionBoxMarker>
                    <SearchSuggestionsBox visible={popoverVisible}>
                        {this.renderSuggestions()}
                    </SearchSuggestionsBox>
                </ListSuggestionBoxMarker>
            </ListSearchWrapper>
        );
    }
}

export default ListSearch;
