import { combineReducers } from 'redux';
import liveReducer from './live/reducer';
import summaryReducer from './summary/reducer';
import currentListAnalyticsReducer from './currentList/reducer';

export default combineReducers({
    live: liveReducer,
    summary: summaryReducer,
    currentList: currentListAnalyticsReducer,
});
