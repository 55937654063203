import React, { Component } from 'react';
import { SwitcherWrapper, Item, ItemText, SelectedItemText } from './styled';

class Switcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: props.defaultSelectedIndex,
        };
    }

    onSelectIndex = index => {
        if (this.props.selectedItemAtIndex) {
            this.props.selectedItemAtIndex(index);
        }

        this.setState({
            selectedIndex: index,
        });
    };

    render() {
        const { items, height } = this.props;
        const { selectedIndex } = this.state;

        return (
            <SwitcherWrapper height={height}>
                {items.map((item, index) => {
                    const selected = index === selectedIndex;

                    return (
                        <Item
                            key={`${item}-${index}`}
                            selected={selected}
                            tabIndex={selected ? -1 : 0}
                            onClick={selected ? () => {} : () => this.onSelectIndex(index)}
                        >
                            {selected ? (
                                <SelectedItemText>{item}</SelectedItemText>
                            ) : (
                                <ItemText>{item}</ItemText>
                            )}
                        </Item>
                    );
                })}
            </SwitcherWrapper>
        );
    }
}

export default Switcher;
