import PropTypes from 'prop-types';
import SearchBar from './view';

SearchBar.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,

    onSearchTermChange: PropTypes.func,
};

SearchBar.defaultProps = {
    height: 48,
};

export default SearchBar;
