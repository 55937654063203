import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
    PODCAST_SEARCH_REQUEST,
    podcastSearchRequestSuccess,
    podcastSearchRequestFailure,
} from 'app/state/actions/currentList';
import fetchPodcastSearchResults from 'app/services/podcastSearch';

export default (action$, state$) =>
    action$.pipe(
        ofType(PODCAST_SEARCH_REQUEST),
        withLatestFrom(state$),
        switchMap(([action, state]) =>
            fetchPodcastSearchResults(state, action.payload.searchTerm).pipe(
                map(serviceResult => podcastSearchRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in podcastSearch epic: ', e);
                    return of(podcastSearchRequestFailure());
                }),
            ),
        ),
    );
