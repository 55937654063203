import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const DetailWrapper = styled.div`
    height: 100vh;
    width: calc(100vw - ${8 * gridSize}px);
    box-sizing: border-box;

    overflow-y: auto;

    @media print {
        width: 100%;
        overflow-y: initial;
    }

    margin-left: ${gridSize * 4}px;
    padding-right: ${gridSize * 4}px;
`;
