/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5641900e-34be-4e0c-99ba-0389725c71ef",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7u8LOC7fp",
    "aws_user_pools_web_client_id": "6hdakcgkbkf75agh8j6g158v25",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ContentCuratorLists-production",
            "region": "us-east-1"
        },
        {
            "tableName": "ContentCuratorLayouts-production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "contentcurator-images-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "contentcurator",
            "endpoint": "https://yes8vfnbna.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "curation.pocketcasts.com-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1kk59q6vdflc5.cloudfront.net"
};


export default awsmobile;
