import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import intlStrings from '../intl/intl-strings';

import addSupportedLocaleData from '../intl/add-supported-locale-data';
import getLanguageFromLocale from '../intl/get-language-from-locale';

export default class AppBase extends Component {
    constructor(props) {
        super(props);
        addSupportedLocaleData();
    }

    renderChildrenWithStore() {
        if (!this.props.store) {
            return this.props.children;
        }

        return <ReduxProvider store={this.props.store}>{this.props.children}</ReduxProvider>;
    }

    render() {
        return (
            <IntlProvider
                messages={intlStrings[this.props.locale]}
                locale={getLanguageFromLocale(this.props.locale)}
            >
                {this.renderChildrenWithStore()}
            </IntlProvider>
        );
    }
}

AppBase.propTypes = {
    locale: PropTypes.string.isRequired,
    store: PropTypes.any.isRequired,
    children: PropTypes.any,
};
