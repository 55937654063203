import { connect } from 'react-redux';
import {
    editListAddEpisode,
    editListAddPodcast,
    requestPodcastSearch,
} from 'app/state/actions/currentList';
import { getCurrentListType } from 'app/state/selectors/currentList';
import {
    getPodcastSearchError,
    getPodcastSearchResults,
    isPodastSearchLoading,
} from 'app/state/selectors/podcastSearch';
import PodcastSearch from './view';

const mapStateToProps = state => ({
    podcastSearchResults: getPodcastSearchResults(state),
    podcastSearchIsLoading: isPodastSearchLoading(state),
    podcastSearchError: getPodcastSearchError(state),
    listType: getCurrentListType(state),
});

const mapDispatchToProps = dispatch => ({
    fetchSuggestions: searchTerm => dispatch(requestPodcastSearch(searchTerm)),
    addEpisodeToList: episode => dispatch(editListAddEpisode(episode)),
    addPodcastToList: podcast => dispatch(editListAddPodcast(podcast)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PodcastSearch);
