import PropTypes from 'prop-types';
import Input from './view';

Input.propTypes = {
    width: PropTypes.number,
    placeholder: PropTypes.string,
    isValid: PropTypes.bool,

    value: PropTypes.string,
    onChange: PropTypes.func,
};

Input.defaultProps = {
    width: 120,
    placeholder: '',
    isValid: true,
};

export default Input;
