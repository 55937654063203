import { map } from 'rxjs/operators';
import {
    fetchListAnalytics,
    fetchLiveAnalytics,
    fetchSummaryAnayltics,
} from '../../rest/analytics';

export const fetchLiveAnalyticsTransformer = analytics => analytics;

export const fetchLiveAnalyticsData = () =>
    fetchLiveAnalytics().pipe(map(fetchLiveAnalyticsTransformer));

export const fetchSummaryAnalyticsTransformer = analytics => analytics;

export const fetchSummaryAnalyticsData = () =>
    fetchSummaryAnayltics().pipe(map(fetchSummaryAnalyticsTransformer));

export const fetchListAnalyticsTransformer = analytics => analytics;

export const fetchListAnalyticsData = listId =>
    fetchListAnalytics(listId).pipe(map(fetchListAnalyticsTransformer));
