import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { SINGLE_REGION_MODE } from 'common/feature-flags';
import RegionSelector from './RegionSelector';
import LayoutEditor from './LayoutEditor';
import { REGION_CODE } from '../../../../state/constants';

export class RegionLayouts extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    render() {
        const { match } = this.props;

        return (
            <Switch>
                <Route path={`${match.url}/:region`} component={LayoutEditor} />

                {SINGLE_REGION_MODE ? (
                    <Route
                        path={`${match.url}/`}
                        render={() => <Redirect to={`${match.url}/${REGION_CODE.global}`} />}
                    />
                ) : (
                    <Route path={`${match.url}/`} component={RegionSelector} />
                )}

                <Route render={() => <Redirect to={`${match.url}/`} />} />
            </Switch>
        );
    }
}

export default RegionLayouts;
