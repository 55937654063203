import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles/index';

export const RegionRowWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-left: ${gridSize}px;
`;

export const RegionWrapper = styled.div`
    display: flex;

    margin-left: -${gridSize}px;
`;
