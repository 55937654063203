import { freeze } from 'icepick';
import {
    PODCAST_SEARCH_REQUEST,
    PODCAST_SEARCH_REQUEST_SUCCESS,
    PODCAST_SEARCH_REQUEST_FAILURE,
} from 'app/state/actions/currentList';

export const INITIAL_STATE = freeze({
    isLoading: false,
    error: '',
    results: [],
});

// podcastSearch reducer
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case PODCAST_SEARCH_REQUEST:
            return {
                isLoading: true,
                isError: false,
                results: [],
            };
        case PODCAST_SEARCH_REQUEST_SUCCESS:
            return {
                isLoading: false,
                isError: false,
                results: payload.results,
            };
        case PODCAST_SEARCH_REQUEST_FAILURE:
            return {
                isLoading: false,
                isError: true,
                results: [],
            };
        default:
            return state;
    }
};
