import React from 'react';
import { FormattedDate, injectIntl } from 'react-intl';
import { isThisYear, isToday, isYesterday, parseISO } from 'date-fns';

function DateText({ date = null, intl, showToday = true }) {
    const formatDate = dateStr => {
        if (!dateStr || dateStr.length === 0) {
            return '';
        }

        const date = new Date(dateStr);
        if (showToday) {
            if (
                isToday(parseISO(date.toISOString())) ||
                isYesterday(parseISO(date.toISOString()))
            ) {
                const desc = intl.formatRelative(dateStr);
                return desc.length <= 1
                    ? desc
                    : desc.charAt(0).toUpperCase() + desc.slice(1).toLowerCase();
            }
        }
        if (isThisYear(parseISO(date.toISOString()))) {
            return <FormattedDate value={dateStr} day="numeric" month="long" />;
        }

        return <FormattedDate value={dateStr} day="numeric" month="short" year="numeric" />;
    };
    return <span className="date-text">{formatDate(date)}</span>;
}

export default injectIntl(DateText);
