import { API } from 'aws-amplify';
import { from } from 'rxjs';

export default (baseUrl, searchTerm) =>
    from(
        API.post('contentcurator', '/podcasts/search', {
            body: {
                term: searchTerm,
            },
        }),
    );
