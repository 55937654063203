import React, { Component } from 'react';
import { SearchBarInput } from './styled';

class SearchBar extends Component {
    onChange = event => {
        if (this.props.onSearchTermChange) {
            this.props.onSearchTermChange(event.target.value);
        }
    };

    render() {
        const { width, height } = this.props;

        return (
            <SearchBarInput
                id="abc"
                height={height}
                width={width}
                placeholder="Search"
                onChange={this.onChange}
            />
        );
    }
}

export default SearchBar;
