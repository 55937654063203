import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const Spacer = styled.div`
    width: ${props => props.px}px;
    height: ${props => props.px}px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    align-self: flex-end;

    margin-bottom: ${4 * gridSize}px;
`;

export const Title = styled.h3`
    margin: 0 auto 20px auto;
`;
