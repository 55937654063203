import React, { Component } from 'react';
import Switcher from 'common/pocket-casts-components/Switcher/view';
import { H40 } from 'common/pocket-casts-styles';
import SearchBar from 'common/pocket-casts-components/SearchBar';
import { getDateRanges, getFilterFunctions } from 'common/DateRangeSwitcher/model';
import DateRangeInputs from 'common/DateRangeSwitcher/DateRangeInputs';
import Spacer from 'common/pocket-casts-components/Spacer';
import BulkAnalyticsTable from './BulkAnalyticsTable';
import {
    BulkAnalyticsPageWrapper,
    LiveActionsWrapper,
    RightWrapper,
    TitleWrapper,
    LiveTableWrapper,
    OtherActionsWrapper,
    OtherTableWrapper,
    ShowSponsoredCheckboxWrapper,
} from './styled';

class BulkAnalyticsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Applies only to Live lists
            filterFunctionIndex: getDateRanges().length - 1,
            fromDate: null,
            toDate: null,

            // Applies only to Out of Rotation lists
            searchTerm: '',
            showOnlySponsored: false,
        };
    }

    processLiveData = liveData => {
        const { fromDate, toDate, filterFunctionIndex } = this.state;
        const dayFilterFunction = getFilterFunctions(fromDate, toDate)[filterFunctionIndex];

        return liveData.map(liveList => {
            const { days, podcasts } = liveList;

            let daysInFilterFromList = [];
            if (days) {
                daysInFilterFromList = days.filter(dayFilterFunction);
            }

            let daysInFilterFromPodcasts = [];
            if (podcasts && podcasts.length > 0) {
                daysInFilterFromPodcasts = podcasts.reduce((result, current) => {
                    const { days } = current;
                    const theseDays = days ? days.filter(dayFilterFunction) : [];
                    return result.concat(theseDays);
                }, []);
            }

            const allDaysData = daysInFilterFromList.concat(daysInFilterFromPodcasts);

            const aggregatedData = allDaysData.reduce(
                (result, current) => ({
                    impressions: result.impressions + (current.impressions || 0),
                    uniqueImpressions: result.uniqueImpressions + (current.uniqueImpressions || 0),
                    engagements: result.engagements + (current.engagements || 0),
                    plays: result.plays + (current.plays || 0),
                    subscribes: result.subscribes + (current.subscribes || 0),
                }),
                {
                    impressions: 0,
                    uniqueImpressions: 0,
                    engagements: 0,
                    plays: 0,
                    subscribes: 0,
                },
            );

            return {
                ...liveList,
                ...aggregatedData,
            };
        });
    };

    processNonLiveData = (nonLiveData, showOnlySponsored = false) => {
        const { searchTerm } = this.state;
        const term = searchTerm.toLowerCase();
        const matchesSearchTerm = list =>
            list.searchTitle.includes(term) || list.creator.toLowerCase().includes(term);

        return nonLiveData.filter(list => {
            if (showOnlySponsored) {
                return list.sponsored === true ? matchesSearchTerm(list) : false;
            }
            return matchesSearchTerm(list);
        });
    };

    selectedSwitcherItem = index => {
        this.setState({
            filterFunctionIndex: index,
        });
    };

    onSearchTermChange = searchTerm => {
        this.setState({
            searchTerm,
        });
    };

    updateFromDate = fromDate => {
        this.setState({
            fromDate,
        });
    };

    updateToDate = toDate => {
        this.setState({
            toDate,
        });
    };

    setShowOnlySponsored = () => {
        this.setState({
            showOnlySponsored: !this.state.showOnlySponsored,
        });
    };

    render() {
        const { liveData, nonLiveData } = this.props;
        const { filterFunctionIndex } = this.state;

        const processedLiveData = this.processLiveData(liveData);
        const processedNonLiveData = this.processNonLiveData(
            nonLiveData,
            this.state.showOnlySponsored,
        );

        return (
            <BulkAnalyticsPageWrapper>
                <LiveActionsWrapper>
                    <TitleWrapper>
                        <H40>Currently Live</H40>
                    </TitleWrapper>
                    <RightWrapper>
                        {filterFunctionIndex === 0 && (
                            <DateRangeInputs
                                fromDateChanged={this.updateFromDate}
                                toDateChanged={this.updateToDate}
                            />
                        )}
                        {filterFunctionIndex === 0 && <Spacer />}
                        <Switcher
                            items={getDateRanges()}
                            defaultSelectedIndex={getDateRanges().length - 1}
                            selectedItemAtIndex={this.selectedSwitcherItem}
                        />
                    </RightWrapper>
                </LiveActionsWrapper>
                <LiveTableWrapper>
                    <BulkAnalyticsTable data={processedLiveData} />
                </LiveTableWrapper>
                <OtherActionsWrapper>
                    <TitleWrapper>
                        <H40>Out of Rotation</H40>
                    </TitleWrapper>
                    <RightWrapper>
                        <ShowSponsoredCheckboxWrapper>
                            <label htmlFor="show-only-sponsored">Show only sponsored</label>
                            <input
                                onChange={this.setShowOnlySponsored}
                                value={this.state.showOnlySponsored}
                                type="checkbox"
                                id="show-only-sponsored"
                            ></input>
                        </ShowSponsoredCheckboxWrapper>
                        <SearchBar onSearchTermChange={this.onSearchTermChange} />
                    </RightWrapper>
                </OtherActionsWrapper>
                <OtherTableWrapper>
                    <BulkAnalyticsTable data={processedNonLiveData} />
                </OtherTableWrapper>
            </BulkAnalyticsPageWrapper>
        );
    }
}

export default BulkAnalyticsPage;
