import { map } from 'rxjs/operators';
import { fetchRegionLayout, saveRegionLayoutListOrder } from 'app/rest/layouts';
import { getEditingLayoutLists } from 'app/state/selectors/editingLayout';
import { DIFF_ATTRIBUTES } from '../../state/constants';

export const fetchTransformer = lists => lists;

export const fetchLayoutData = (state, region) =>
    fetchRegionLayout(region).pipe(map(fetchTransformer));

export const saveTransformer = data => data;

export const saveLayoutData = (state, region) => {
    const lists = getEditingLayoutLists(state);

    const listsRequestPayload = lists
        .filter(({ diff }) => diff !== DIFF_ATTRIBUTES.deleted)
        .map(({ id, pinned }) => ({
            id,
            pinned,
        }));

    return saveRegionLayoutListOrder(region, listsRequestPayload).pipe(map(saveTransformer));
};
