export const CURRENT_LIST_SET_TITLE = 'CURRENT_LIST_SET_TITLE';
export const CURRENT_LIST_SET_DESCRIPTION = 'CURRENT_LIST_SET_DESCRIPTION';
export const CURRENT_LIST_SET_URL_PATH = 'CURRENT_LIST_SET_URL_PATH';
export const CURRENT_LIST_SET_EPISODES = 'CURRENT_LIST_SET_EPISODES';
export const CURRENT_LIST_SET_PODCASTS = 'CURRENT_LIST_SET_PODCASTS';
export const CURRENT_LIST_SET_REGIONS = 'CURRENT_LIST_SET_REGIONS';
export const CURRENT_LIST_SET_SPONSORED = 'CURRENT_LIST_SET_SPONSORED';
export const CURRENT_LIST_SET_SUBTITLE = 'CURRENT_LIST_SET_SUBTITLE';
export const CURRENT_LIST_SET_WEB_URL = 'CURRENT_LIST_SET_WEB_URL';
export const CURRENT_LIST_SET_WEB_TITLE = 'CURRENT_LIST_SET_WEB_TITLE';
export const CURRENT_LIST_SET_COLOR_ON_LIGHT_BACKGROUND =
    'CURRENT_LIST_SET_COLOR_ON_LIGHT_BACKGROUND';
export const CURRENT_LIST_SET_COLOR_ON_DARK_BACKGROUND =
    'CURRENT_LIST_SET_COLOR_ON_DARK_BACKGROUND';
export const CURRENT_LIST_SET_COLLECTION_IMAGE = 'CURRENT_LIST_SET_COLLECTION_IMAGE';
export const CURRENT_LIST_SET_HEADER_IMAGE = 'CURRENT_LIST_SET_HEADER_IMAGE';
export const CURRENT_LIST_SET_COLLECTION_IMAGE_UPLOADING =
    'CURRENT_LIST_SET_COLLECTION_IMAGE_UPLOADING';
export const CURRENT_LIST_SET_HEADER_IMAGE_UPLOADING = 'CURRENT_LIST_SET_HEADER_IMAGE_UPLOADING';
export const CURRENT_LIST_SET_DISCOVER_STYLE = 'CURRENT_LIST_SET_DISCOVER_STYLE';
export const CURRENT_LIST_SET_FULL_PAGE_STYLE = 'CURRENT_LIST_SET_FULL_PAGE_STYLE';
export const CURRENT_LIST_SET_ALLOWED_REGIONS = 'CURRENT_LIST_SET_ALLOWED_REGIONS';

export const CURRENT_LIST_ADD_EPISODE = 'CURRENT_LIST_ADD_EPISODE';
export const CURRENT_LIST_ADD_PODCAST = 'CURRENT_LIST_ADD_PODCAST';
export const CURRENT_LIST_REMOVE_EPISODE = 'CURRENT_LIST_REMOVE_EPISODE';
export const CURRENT_LIST_REMOVE_PODCAST = 'CURRENT_LIST_REMOVE_PODCAST';

export const PODCAST_SEARCH_REQUEST = 'PODCAST_SEARCH_REQUEST';
export const PODCAST_SEARCH_REQUEST_SUCCESS = 'PODCAST_SEARCH_REQUEST_SUCCESS';
export const PODCAST_SEARCH_REQUEST_FAILURE = 'PODCAST_SEARCH_REQUEST_FAILURE';
export const CURRENT_LIST_SET_PLATFORM = 'CURRENT_LIST_SET_PLATFORM';

export const editListSetTitle = title => ({
    type: CURRENT_LIST_SET_TITLE,
    payload: {
        title,
    },
});

export const editListSetDescription = description => ({
    type: CURRENT_LIST_SET_DESCRIPTION,
    payload: {
        description,
    },
});

export const editListSetUrlPath = path => ({
    type: CURRENT_LIST_SET_URL_PATH,
    payload: {
        path,
    },
});

export const editListSetEpisodes = episodes => ({
    type: CURRENT_LIST_SET_EPISODES,
    payload: {
        episodes,
    },
});

export const editListSetPodcasts = podcasts => ({
    type: CURRENT_LIST_SET_PODCASTS,
    payload: {
        podcasts,
    },
});

export const editListSetRegions = regions => ({
    type: CURRENT_LIST_SET_REGIONS,
    payload: {
        regions,
    },
});

export const editListSetSponsored = sponsored => ({
    type: CURRENT_LIST_SET_SPONSORED,
    payload: {
        sponsored,
    },
});

export const editListSetSubtitle = subtitle => ({
    type: CURRENT_LIST_SET_SUBTITLE,
    payload: {
        subtitle,
    },
});

export const editListSetWebUrl = webUrl => ({
    type: CURRENT_LIST_SET_WEB_URL,
    payload: {
        webUrl,
    },
});

export const editListSetWebTitle = webTitle => ({
    type: CURRENT_LIST_SET_WEB_TITLE,
    payload: {
        webTitle,
    },
});

export const editListSetColorOnLightBackground = colorOnLightBackground => ({
    type: CURRENT_LIST_SET_COLOR_ON_LIGHT_BACKGROUND,
    payload: {
        colorOnLightBackground,
    },
});

export const editListSetColorOnDarkBackground = colorOnDarkBackground => ({
    type: CURRENT_LIST_SET_COLOR_ON_DARK_BACKGROUND,
    payload: {
        colorOnDarkBackground,
    },
});

export const editListSetCollectionImage = collectionImage => ({
    type: CURRENT_LIST_SET_COLLECTION_IMAGE,
    payload: {
        collectionImage,
    },
});

export const editListSetHeaderImage = headerImage => ({
    type: CURRENT_LIST_SET_HEADER_IMAGE,
    payload: {
        headerImage,
    },
});

export const editCollectionImageUploading = collectionImageUploading => ({
    type: CURRENT_LIST_SET_COLLECTION_IMAGE_UPLOADING,
    payload: {
        collectionImageUploading,
    },
});

export const editHeaderImageUploading = headerImageUploading => ({
    type: CURRENT_LIST_SET_HEADER_IMAGE_UPLOADING,
    payload: {
        headerImageUploading,
    },
});

export const editListSetDiscoverStyle = discoverStyle => ({
    type: CURRENT_LIST_SET_DISCOVER_STYLE,
    payload: {
        discoverStyle,
    },
});

export const editListSetFullPageStyle = fullPageStyle => ({
    type: CURRENT_LIST_SET_FULL_PAGE_STYLE,
    payload: {
        fullPageStyle,
    },
});

export const editListSetAllowedRegions = allowedRegions => ({
    type: CURRENT_LIST_SET_ALLOWED_REGIONS,
    payload: {
        allowedRegions,
    },
});

export const editListAddEpisode = episode => ({
    type: CURRENT_LIST_ADD_EPISODE,
    payload: {
        episode,
    },
});

export const editListAddPodcast = podcast => ({
    type: CURRENT_LIST_ADD_PODCAST,
    payload: {
        podcast,
    },
});

export const editListRemoveEpisode = episodeUuid => ({
    type: CURRENT_LIST_REMOVE_EPISODE,
    payload: {
        episodeUuid,
    },
});

export const editListRemovePodcast = podcastUuid => ({
    type: CURRENT_LIST_REMOVE_PODCAST,
    payload: {
        podcastUuid,
    },
});

export const requestPodcastSearch = searchTerm => ({
    type: PODCAST_SEARCH_REQUEST,
    payload: {
        searchTerm,
    },
});

export const podcastSearchRequestSuccess = results => ({
    type: PODCAST_SEARCH_REQUEST_SUCCESS,
    payload: {
        results,
    },
});

export const podcastSearchRequestFailure = () => ({
    type: PODCAST_SEARCH_REQUEST_FAILURE,
    payload: {},
});

export const editListSetPlatform = (platform, checked) => ({
    type: CURRENT_LIST_SET_PLATFORM,
    payload: {
        platform,
        checked,
    },
});
