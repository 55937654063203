import React, { Component } from 'react';
import { STATIC_URL } from 'settings';
import { EPISODE_LIST } from '../../../../../../state/constants';
import {
    ListAnalyticsTableWrapper,
    StyledTable,
    ImpressionsCol,
    ShowAllsCol,
    StatsCol,
    ActionsCol,
    TrHeader,
    Th,
    ThText,
    Tr,
    Td,
    TdBigText,
    TdTwoRows,
    TdSmallColumns,
    TdSmallText,
    TdGreenText,
    PodcastWrapper,
    PodcastNumberWrapper,
    PodcastNumber,
    PodcastCoverWrapper,
    PodcastDetailsWrapper,
    PodcastName,
    PodcastAuthor,
} from './styled';

const staticImageUrlForUuid = uuid => `${STATIC_URL}/discover/images/140/${uuid}.jpg`;

class ListAnalyticsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            impressions: 0,
            uniqueImpressions: 0,
            showAlls: 0,
            uniqueShowAlls: 0,
            engagements: 0,
            plays: 0,
            subscribes: 0,
            podcastUuidToAnalyticsMap: {
                invalid: {
                    engagements: 0,
                    plays: 0,
                    subscribes: 0,
                },
            },
        };
    }

    componentDidMount() {
        this.processAnalytics();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.analytics !== this.props.analytics ||
            prevProps.dayFilterFunction !== this.props.dayFilterFunction
        ) {
            this.processAnalytics();
        }
    }

    getEngagementsForPodcastUuid = uuid => {
        const { podcastUuidToAnalyticsMap } = this.state;
        const stats = podcastUuidToAnalyticsMap[uuid];
        return (stats && stats.engagements) || 0;
    };

    getPlaysForPodcastUuid = uuid => {
        const { podcastUuidToAnalyticsMap } = this.state;
        const stats = podcastUuidToAnalyticsMap[uuid];
        return (stats && stats.plays) || 0;
    };

    getSubscribesForPodcastUuid = uuid => {
        const { podcastUuidToAnalyticsMap } = this.state;
        const stats = podcastUuidToAnalyticsMap[uuid];
        return (stats && stats.subscribes) || 0;
    };

    processAnalytics = () => {
        const { analytics, dayFilterFunction } = this.props;

        /* Process .days */

        const days = analytics.days || [];

        const filteredDays = days.filter(dayFilterFunction);

        const primaryData = filteredDays.reduce(
            (result, current) => ({
                impressions: result.impressions + (current.impressions || 0),
                uniqueImpressions: result.uniqueImpressions + (current.uniqueImpressions || 0),
                showAlls: result.showAlls + (current.showAlls || 0),
                uniqueShowAlls: result.uniqueShowAlls + (current.uniqueShowAlls || 0),
            }),
            {
                impressions: 0,
                uniqueImpressions: 0,
                showAlls: 0,
                uniqueShowAlls: 0,
            },
        );

        /* Process .podcasts */

        const podcasts = analytics.podcasts || [];

        const podcastToAnalyticsMap = {};
        let totalEngagements = 0;
        let totalPlays = 0;
        let totalSubscribes = 0;

        podcasts.forEach(podcast => {
            const uuid = podcast.uuid || 'invalid';
            podcastToAnalyticsMap[uuid] = {};

            const podcastDays = podcast.days || [];
            const filteredPodcastDays = podcastDays.filter(dayFilterFunction);

            const eps = filteredPodcastDays.reduce(
                (result, current) => ({
                    engagements: result.engagements + current.engagements || 0,
                    plays: result.plays + current.plays || 0,
                    subscribes: result.subscribes + current.subscribes || 0,
                }),
                {
                    engagements: 0,
                    plays: 0,
                    subscribes: 0,
                },
            );

            podcastToAnalyticsMap[uuid].engagements = eps.engagements;
            podcastToAnalyticsMap[uuid].plays = eps.plays;
            podcastToAnalyticsMap[uuid].subscribes = eps.subscribes;

            totalEngagements += eps.engagements;
            totalPlays += eps.plays;
            totalSubscribes += eps.subscribes;
        });

        /* Set state */

        this.setState({
            impressions: primaryData.impressions,
            uniqueImpressions: primaryData.uniqueImpressions,
            showAlls: primaryData.showAlls,
            uniqueShowAlls: primaryData.uniqueShowAlls,
            engagements: totalEngagements,
            plays: totalPlays,
            subscribes: totalSubscribes,
            podcastUuidToAnalyticsMap: podcastToAnalyticsMap,
        });
    };

    renderRowItem = ({ index, uuid, podcastUuid, title, subtitle }) => (
        <Tr key={`${uuid}-${index}`}>
            <Td>
                <PodcastWrapper>
                    <PodcastNumberWrapper>
                        <PodcastNumber>{index + 1}</PodcastNumber>
                    </PodcastNumberWrapper>
                    <PodcastCoverWrapper>
                        <img width={64} height={64} src={staticImageUrlForUuid(podcastUuid)} />
                    </PodcastCoverWrapper>
                    <PodcastDetailsWrapper>
                        <PodcastName>
                            {!title || title.length <= 40 ? title : `${title.slice(0, 40)}...`}
                        </PodcastName>
                        <PodcastAuthor>
                            {!subtitle || subtitle.length <= 50
                                ? subtitle
                                : `${subtitle.slice(0, 50)}...`}
                        </PodcastAuthor>
                    </PodcastDetailsWrapper>
                </PodcastWrapper>
            </Td>
            <Td />
            <Td>
                <TdBigText>
                    {Number(this.getEngagementsForPodcastUuid(podcastUuid)).toLocaleString()}
                </TdBigText>
            </Td>
            <Td>
                <TdBigText>
                    {Number(this.getPlaysForPodcastUuid(podcastUuid)).toLocaleString()}
                </TdBigText>
            </Td>
            <Td>
                <TdBigText>
                    {Number(this.getSubscribesForPodcastUuid(podcastUuid)).toLocaleString()}
                </TdBigText>
            </Td>
            <Td />
        </Tr>
    );

    render() {
        const { listType, items } = this.props;
        const {
            impressions,
            uniqueImpressions,
            showAlls,
            uniqueShowAlls,
            engagements,
            plays,
            subscribes,
        } = this.state;

        return (
            <ListAnalyticsTableWrapper>
                <StyledTable>
                    <colgroup>
                        <ImpressionsCol />
                        <ShowAllsCol />
                        <StatsCol />
                        <StatsCol />
                        <StatsCol />
                        <ActionsCol />
                    </colgroup>
                    <tbody>
                        <TrHeader>
                            <Th>
                                <ThText>Unique Impressions</ThText>
                            </Th>
                            <Th>
                                <ThText>Show Alls</ThText>
                            </Th>
                            <Th>
                                <ThText>Taps</ThText>
                            </Th>
                            <Th>
                                <ThText>Plays</ThText>
                            </Th>
                            <Th>
                                <ThText>Subscribes</ThText>
                            </Th>
                            <Th />
                        </TrHeader>
                        <Tr>
                            <Td>
                                <TdTwoRows>
                                    <TdBigText>
                                        {Number(uniqueImpressions).toLocaleString()}
                                    </TdBigText>
                                    <TdSmallColumns>
                                        <TdSmallText>
                                            {Number(impressions).toLocaleString()}
                                        </TdSmallText>
                                        <TdGreenText>
                                            (
                                            {(impressions / (uniqueImpressions || 1)).toPrecision(
                                                2,
                                            )}
                                            &nbsp;:&nbsp;1)
                                        </TdGreenText>
                                    </TdSmallColumns>
                                </TdTwoRows>
                            </Td>
                            <Td>
                                <TdTwoRows>
                                    <TdBigText>{Number(uniqueShowAlls).toLocaleString()}</TdBigText>
                                    <TdSmallColumns>
                                        <TdSmallText>
                                            {Number(showAlls).toLocaleString()}
                                        </TdSmallText>
                                        <TdGreenText>
                                            ({(showAlls / (uniqueShowAlls || 1)).toPrecision(2)}
                                            &nbsp;:&nbsp;1)
                                        </TdGreenText>
                                    </TdSmallColumns>
                                </TdTwoRows>
                            </Td>
                            <Td>
                                <TdBigText>{Number(engagements).toLocaleString()}</TdBigText>
                            </Td>
                            <Td>
                                <TdBigText>{Number(plays).toLocaleString()}</TdBigText>
                            </Td>
                            <Td>
                                <TdBigText>{Number(subscribes).toLocaleString()}</TdBigText>
                            </Td>
                            <Td />
                        </Tr>
                        {items.map((item, index) =>
                            listType === EPISODE_LIST
                                ? this.renderRowItem({
                                      index,
                                      uuid: item.uuid,
                                      podcastUuid: item.podcast_uuid,
                                      title: item.title,
                                      subtitle: item.podcast_title,
                                  })
                                : this.renderRowItem({
                                      index,
                                      uuid: item.uuid,
                                      podcastUuid: item.uuid,
                                      title: item.title,
                                      subtitle: item.author,
                                  }),
                        )}
                    </tbody>
                </StyledTable>
            </ListAnalyticsTableWrapper>
        );
    }
}

export default ListAnalyticsTable;
