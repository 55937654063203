import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Header from './view';
import {
    getLastUpdatedList,
    getLiveAnalyticsDataIsLoading,
} from '../../../../../../state/selectors/analytics';

Header.propTypes = {
    managePageSelected: PropTypes.func,
    analyticsPageSelected: PropTypes.func,
    selectedListTab: PropTypes.string,
};

const mapStateToProps = state => {
    const lastUpdatedList = getLastUpdatedList(state);
    return {
        dateUpdatedMoment: lastUpdatedList ? Moment(lastUpdatedList.updatedDate) : null,
        hasLoadedAnalyticsData: getLiveAnalyticsDataIsLoading(state),
    };
};

export default connect(mapStateToProps)(Header);
