/* eslint-disable import/extensions */
import 'rxjs'; // Redux-Observable does not import rxjs for us.
import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { GlobalStyles } from './common/pocket-casts-styles';
import App from './app';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

const rootDiv = document.createElement('div');
rootDiv.id = 'root';
document.body.appendChild(rootDiv);

ReactDOM.render(
    <div>
        <GlobalStyles />
        <App locale="en_US" />
    </div>,
    document.getElementById('root'),
);
