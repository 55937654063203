import { map } from 'rxjs/operators';
import {
    fetchLists,
    fetchList,
    postCreateList,
    postImportList,
    putUpdateList,
    fetchListSearchResults,
} from 'app/rest/lists';
import { getBaseUrl } from 'app/state/selectors/config';
import {
    getCurrentListDiscoverStyle,
    getCurrentListFullPageStyle,
    getCurrentListId,
    getCurrentListIsSponsored,
    getCurrentListEpisodesUuidAndPodcastUuid,
    getCurrentListPodcastUuids,
    getCurrentListTitle,
    getCurrentListSubtitle,
    getCurrentListColorOnLightBackground,
    getCurrentListColorOnDarkBackground,
    getCurrentListWebUrl,
    getCurrentListWebTitle,
    getCurrentListDescription,
    getCurrentListUrlPath,
    getCurrentListPlatform,
    getCurrentListAllowedRegions,
} from 'app/state/selectors/currentList';

export const fetchListsTransformer = data => data;

export const fetchListsData = state => {
    const baseUrl = getBaseUrl(state);
    return fetchLists(baseUrl).pipe(map(fetchListsTransformer));
};

export const fetchListTransformer = data => {
    const defaultProperties = {
        sponsored: false,
        platform: ['ios', 'android', 'web', 'automotive'],
    };
    return Object.assign({}, defaultProperties, data);
};

export const fetchListData = (state, listId) => {
    const baseUrl = getBaseUrl(state);
    return fetchList(baseUrl, listId).pipe(map(fetchListTransformer));
};

export const sendCreateListTransformer = data => data;

export const sendCreateList = listType =>
    postCreateList(listType).pipe(map(sendCreateListTransformer));

export const sendImportList = bundleUuid => postImportList(bundleUuid);

export const sendUpdateListTransformer = data => data;

export const sendUpdateList = state => {
    const listId = getCurrentListId(state);
    const payload = {
        title: getCurrentListTitle(state),
        subtitle: getCurrentListSubtitle(state),
        description: getCurrentListDescription(state),
        colorOnLightBackground: getCurrentListColorOnLightBackground(state),
        colorOnDarkBackground: getCurrentListColorOnDarkBackground(state),
        urlPath: getCurrentListUrlPath(state),
        webUrl: getCurrentListWebUrl(state),
        webTitle: getCurrentListWebTitle(state),
        allowedRegions: getCurrentListAllowedRegions(state),
        podcasts: getCurrentListPodcastUuids(state),
        episodes: getCurrentListEpisodesUuidAndPodcastUuid(state),
        sponsored: getCurrentListIsSponsored(state),
        discoverStyle: getCurrentListDiscoverStyle(state),
        fullPageStyle: getCurrentListFullPageStyle(state),
        platform: getCurrentListPlatform(state),
    };

    return putUpdateList(listId, payload).pipe(map(sendUpdateListTransformer));
};

export const listSearchTransformer = data => data;

export const getListSearchResults = searchTerm =>
    fetchListSearchResults(searchTerm).pipe(map(listSearchTransformer));

export const listArchiveTransformer = data => data;

export const sendListArchive = (listId, archive) =>
    putUpdateList(listId, { archived: archive }).pipe(map(listArchiveTransformer));
