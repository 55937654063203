import { API } from 'aws-amplify';
import { from } from 'rxjs';

export const postPublish = () =>
    from(API.post('contentcurator', '/publish/now', { body: { term: '' } }));

export const postPublishSchedule = date =>
    from(
        API.post('contentcurator', `/publish/schedule/add`, {
            body: {
                date,
            },
        }),
    );

export const postCancelPublishSchedule = () =>
    from(
        API.post('contentcurator', `/publish/schedule/cancel`, {
            body: {},
        }),
    );

export const postCopy = (region, regions) =>
    from(
        API.post('contentcurator', `/layouts/copy/${region}`, {
            body: regions,
        }),
    );

export const postPublishListToRegions = (listId, regions) =>
    from(
        API.post('contentcurator', `/lists/regions/${listId}`, {
            body: regions,
        }),
    );
