import PropTypes from 'prop-types';
import DatetimeInput from './view';

DatetimeInput.propTypes = {
    onDatetimeChange: PropTypes.func.isRequired,
    selected: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
};

DatetimeInput.defaultProps = {
    disabled: false,
};

export default DatetimeInput;
