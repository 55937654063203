import { connect } from 'react-redux';
import { getEditingLayoutLists, getLayoutRegion } from 'app/state/selectors/editingLayout';
import {
    editLayoutSetLists,
    editLayoutSetPinForList,
    requestUpdateLayout,
} from 'app/state/actions/editingLayout';
import Layout from './view';

const mapStateToProps = state => ({
    lists: getEditingLayoutLists(state),
    region: getLayoutRegion(state),
});

const mapDispatchToProps = dispatch => ({
    setLists: lists => dispatch(editLayoutSetLists(lists)),
    pinUnpinList: (listId, pinned) => dispatch(editLayoutSetPinForList(listId, pinned)),
    saveEditingLayout: region => dispatch(requestUpdateLayout(region)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
