import { CONFIG_SET } from '../../actions/config';

const INITIAL_STATE = {
    locale: '',
    baseUrl: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIG_SET:
            return {
                locale: action.payload.locale,
                baseUrl: action.payload.baseUrl,
            };
        default:
            return state;
    }
};
