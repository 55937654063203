import styled from 'styled-components';
import { green50, gridSize, H70, fontFamilyRetinaNarrow } from 'common/pocket-casts-styles';

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const EditableListName = styled.input`
    width: 600px;
    min-width: 300px;

    border: 0 solid;

    font-family: ${fontFamilyRetinaNarrow};

    margin-top: ${3 * gridSize}px;
    margin-bottom: 0;
    padding-right: ${gridSize}px;

    font-size: 30px;
    font-weight: 700;

    &:focus {
        outline: none;
    }
`;

export const ListNameReadOnly = styled.div`
    font-family: ${fontFamilyRetinaNarrow};

    margin-top: ${3 * gridSize}px;
    margin-bottom: 0;
    padding-right: ${gridSize}px;

    font-size: 30px;
    font-weight: 700;
`;

export const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const NonEditableLeadingSlash = styled.div`
    color: #555555;
    font-family: inherit;
    font-size: 18px;
    font-weight: 100;
`;

export const EditableListLink = styled.input`
    width: 428px;
    border: 0 solid;
    color: #555555;
    font-family: inherit;
    font-size: 18px;
    font-weight: 100;

    &:focus {
        outline: none;
    }
`;

export const ListLinkReadOnly = styled.div`
    color: #555555;
    font-size: 18px;
    font-weight: 100;
`;

export const Left = styled.div`
    display: flex;
    width: 620px;

    align-items: baseline;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: ${gridSize}px;
    margin-bottom: ${3 * gridSize}px;
`;

export const Right = styled.div`
    display: flex;
    width: 372px;

    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;

    justify-content: flex-end;
`;

export const StatusText = styled(H70)`
    display: flex;
    align-items: center;

    width: 180px;

    margin-right: 8px;

    color: ${green50};
`;

export const ButtonText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${10 * gridSize}px;
    height: ${5 * gridSize}px;
`;

/* Copy link meatball */

export const MeatballButtonWrapper = styled.div`
    position: relative;
    margin-right: ${gridSize}px;
`;

export const MeatballWrapper = styled.div`
    display: flex;
    width: ${4 * gridSize}px;
    height: ${5 * gridSize}px;
    align-items: center;
    justify-content: center;
`;

export const MeatballPopoverTriangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    right: 18px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid grey;
`;

export const MeatballPopoverWrapper = styled.div`
    display: flex;
    position: absolute;
    width: 160px;
    top: 56px;
    right: -53px;
    flex-direction: column;
    background-color: white;
    border-radius: ${gridSize}px;
    border 1px solid gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`;

export const PopoverInset = styled.div`
    padding: ${gridSize / 2}px;
`;

export const CopyListLinkItem = styled.div`
    display: flex;
    height: 32px;
    justify-content: center;
    border-radius: ${gridSize / 2}px;
    cursor: pointer;
    &:hover {
        background-color: lightgray;
    }
`;

export const PopoverItemImageWrapper = styled.div`
    display: flex;
    align-items: center;
    padding-right: ${gridSize}px;
`;

export const PopoverItemTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
