import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledSelect, StyledOption } from './styled';

export default class SingleSelect extends Component {
    static propTypes = {
        items: PropTypes.any,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        items: [],
        onChange: () => {},
    };

    render() {
        const { items } = this.props;

        const selectedItem = items.find(item => item.selected) || items[0];
        const selectedValue = selectedItem && selectedItem.value;

        return (
            <StyledSelect onChange={this.props.onChange} value={selectedValue}>
                {items.map(item => (
                    <StyledOption key={item.value} value={item.value}>
                        {item.text || item.value}
                    </StyledOption>
                ))}
            </StyledSelect>
        );
    }
}
