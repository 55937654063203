import Moment from 'moment';

export const getDateRanges = () => [
    'Custom Range',
    'Past Week',
    'Past Month',
    'From Publication Date',
];

export const getFilterFunctions = (rangeStartOpt, rangeEndOpt) => {
    const now = new Date();

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    const rangeStartMoment = rangeStartOpt
        ? Moment(rangeStartOpt)
              .subtract(1, 'days')
              .endOf('day')
        : null;
    const rangeEndMoment = rangeEndOpt
        ? Moment(rangeEndOpt)
              .add(1, 'days')
              .startOf('day')
        : null;

    const customRangeFunction = day =>
        rangeStartMoment && rangeEndMoment
            ? Moment(day.date).isBetween(rangeStartMoment, rangeEndMoment)
            : true;

    const pastWeekFunction = day => Moment(day.date).isBetween(oneWeekAgo, now);
    const pastMonthFunction = day => Moment(day.date).isBetween(oneMonthAgo, now);
    const fromPublicationDateFunction = () => true;

    return [customRangeFunction, pastWeekFunction, pastMonthFunction, fromPublicationDateFunction];
};
