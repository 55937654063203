import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import { ThemeProvider } from 'styled-components';
import AppBase from 'common/app-base';
import { getThemeFromId, THEME } from 'common/pocket-casts-styles/themes';
import rootEpic from './ops';
import createStore from './state';
import { setConfig } from './state/actions/config';
import View from './view';

class ContentCreator extends Component {
    constructor(props) {
        super(props);

        this.store = createStore(rootEpic);
        this.store.dispatch(setConfig(this.props));
    }

    store = {};

    render() {
        return (
            <AppBase locale={this.props.locale} store={this.store}>
                <ThemeProvider theme={getThemeFromId(THEME.light)}>
                    <BrowserRouter>
                        <View />
                    </BrowserRouter>
                </ThemeProvider>
            </AppBase>
        );
    }
}

export default withAuthenticator(ContentCreator);

ContentCreator.propTypes = {
    locale: PropTypes.string.isRequired,
};
