import { of, timer, EMPTY } from 'rxjs';
import { mergeMap, map, catchError, withLatestFrom, debounce } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
    fetchListsData,
    fetchListData,
    sendCreateList,
    sendUpdateList,
    sendImportList,
    sendListArchive,
    getListSearchResults,
} from 'app/services/lists';
import {
    LISTS_REQUEST,
    LIST_REQUEST,
    LIST_CREATE_REQUEST,
    LIST_UPDATE_REQUEST,
    LIST_SEARCH_REQUEST,
    LIST_ARCHIVE_REQUEST,
    LIST_IMPORT_REQUEST,
    listsRequestSuccess,
    listsRequestFailure,
    listRequestSuccess,
    listRequestFailure,
    listCreateRequestSuccess,
    listCreateRequestFailure,
    listImportRequestFailure,
    listUpdateRequestSuccess,
    listUpdateRequestFailure,
    listSearchRequestSuccess,
    listSearchRequestFailure,
    archiveListSuccess,
    archiveListFailure,
    requestLists,
} from 'app/state/actions/lists';

export const listsRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(LISTS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([, state]) =>
            fetchListsData(state).pipe(
                map(serviceResult => listsRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listsRequestEpic: ', e);
                    return of(listsRequestFailure());
                }),
            ),
        ),
    );

export const listRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fetchListData(state, action.payload.listId).pipe(
                map(serviceResult => listRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listRequestEpic: ', e);
                    return of(listRequestFailure());
                }),
            ),
        ),
    );

export const listCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action]) =>
            sendCreateList(action.payload.listType).pipe(
                map(serviceResult => listCreateRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listCreateEpic: ', e);
                    return of(listCreateRequestFailure());
                }),
            ),
        ),
    );

export const listImportEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_IMPORT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action]) =>
            sendImportList(action.payload.bundleUuid).pipe(
                map(() => requestLists()),
                catchError(e => {
                    console.error('Error in listImportEpic: ', e);
                    return of(listImportRequestFailure());
                }),
            ),
        ),
    );

export const listUpdateEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_UPDATE_REQUEST),
        debounce(action => (action.payload.now ? EMPTY : timer(2000))),
        withLatestFrom(state$),
        mergeMap(([, state]) =>
            sendUpdateList(state).pipe(
                map(serviceResult => listUpdateRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listUpdateEpic: ', e);
                    return of(listUpdateRequestFailure());
                }),
            ),
        ),
    );

export const listSearchEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_SEARCH_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action]) =>
            getListSearchResults(action.payload.searchTerm).pipe(
                map(serviceResult => listSearchRequestSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listSearch epic: ', e);
                    return of(listSearchRequestFailure());
                }),
            ),
        ),
    );

export const listArchiveEpic = (action$, state$) =>
    action$.pipe(
        ofType(LIST_ARCHIVE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([{ payload: { listId, archive } }]) =>
            sendListArchive(listId, archive).pipe(
                map(() => archiveListSuccess(listId, archive)),
                catchError(e => {
                    console.error('Error in listArchive epic: ', e);
                    return of(archiveListFailure(listId, archive));
                }),
            ),
        ),
    );

export default combineEpics(
    listsRequestEpic,
    listRequestEpic,
    listCreateEpic,
    listUpdateEpic,
    listSearchEpic,
    listArchiveEpic,
    listImportEpic,
);
