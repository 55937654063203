import React, { Component } from 'react';
import { StyledInput } from './styled';

class Input extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
        };
    }

    onFocus = () => {
        this.setState({
            focused: true,
        });
    };

    onBlur = () => {
        this.setState({
            focused: false,
        });
    };

    render() {
        const { width, placeholder, value, onChange, isValid } = this.props;
        const { focused } = this.state;

        return (
            <StyledInput
                width={width}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                isValid={isValid}
                focused={focused}
            />
        );
    }
}

export default Input;
