import React, { Component } from 'react';

export default class TrendingIcon extends Component {
    render() {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M4.5 5.5L4.5 25.5H24.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.73346 21.1508L11.8242 13.75C12.3599 12.781 13.7248 12.7063 14.3629 13.6112L16.0805 16.0468C16.7281 16.9651 18.1182 16.8715 18.6369 15.8747L23.2918 6.92883"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}
