import { connect } from 'react-redux';
import {
    getCurrentListItems,
    getCurrentListDescription,
    getCurrentListListEditable,
    getCurrentListType,
} from 'app/state/selectors/currentList';
import { requestUpdateList } from 'app/state/actions/lists';
import { editListSetDescription } from 'app/state/actions/currentList';
import MetadataEditor from './view';

const mapStateToProps = state => ({
    count: getCurrentListItems(state).length,
    description: getCurrentListDescription(state),
    listEditable: getCurrentListListEditable(state),
    listType: getCurrentListType(state),
});

const mapDispatchToProps = dispatch => ({
    updateDescription: description => dispatch(editListSetDescription(description)),
    saveCurrentList: () => dispatch(requestUpdateList()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MetadataEditor);
