import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    ANALYTICS_LIST_FETCH,
    ANALYTICS_LIVE_FETCH,
    ANALYTICS_SUMMARY_FETCH,
    listAnalyticsFetchFailure,
    listAnalyticsFetchSuccess,
    liveAnalyticsFetchFailure,
    liveAnalyticsFetchSuccess,
    summaryAnalyticsFetchFailure,
    summaryAnalyticsFetchSuccess,
} from '../../state/actions/analytics';
import {
    fetchListAnalyticsData,
    fetchLiveAnalyticsData,
    fetchSummaryAnalyticsData,
} from '../../services/analytics';

export const liveAnalyticsRequestEpic = action$ =>
    action$.pipe(
        ofType(ANALYTICS_LIVE_FETCH),
        mergeMap(() =>
            fetchLiveAnalyticsData().pipe(
                map(serviceResult => liveAnalyticsFetchSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in liveAnalyticsRequestEpic: ', e);
                    return of(liveAnalyticsFetchFailure(e));
                }),
            ),
        ),
    );

export const summaryAnalyticsRequestEpic = action$ =>
    action$.pipe(
        ofType(ANALYTICS_SUMMARY_FETCH),
        mergeMap(() =>
            fetchSummaryAnalyticsData().pipe(
                map(serviceResult => summaryAnalyticsFetchSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in summaryAnalyticsRequestEpic: ', e);
                    return of(summaryAnalyticsFetchFailure(e));
                }),
            ),
        ),
    );

export const listAnalyticsRequestEpic = action$ =>
    action$.pipe(
        ofType(ANALYTICS_LIST_FETCH),
        mergeMap(action =>
            fetchListAnalyticsData(action.payload.listId).pipe(
                map(serviceResult => listAnalyticsFetchSuccess(serviceResult)),
                catchError(e => {
                    console.error('Error in listAnalyticsRequestEpic: ', e);
                    return of(listAnalyticsFetchFailure(e));
                }),
            ),
        ),
    );

export default combineEpics(
    liveAnalyticsRequestEpic,
    summaryAnalyticsRequestEpic,
    listAnalyticsRequestEpic,
);
