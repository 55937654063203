// Enhances a redux store so it can be debugged in the Redux Dev Tools browser extension
// (https://github.com/zalmoxisus/redux-devtools-extension) if it is available.

import { isValidElement } from 'react';
import { compose } from 'redux';

// Subset of https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
export default devToolsConfig => {
    // eslint-disable-next-line no-underscore-dangle
    const devToolsEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    // This replacer function is necessary because the default serialization function can loop
    // infinitely if part of state or an action prop is a React element, and there's a circular
    // dependency within React - the same way logging a React element can fail for this reason.
    // It's only needed if you ever pass React objects in actions though.

    // eslint-disable-next-line no-unused-vars
    const serialize = {
        replacer: (key, value) => {
            if (isValidElement(value)) {
                if (value.type && value.type.displayName) {
                    return `React Element <${value.type.displayName}>`;
                }
                return 'React Element';
            }

            return value;
        },
    };

    return devToolsEnhancer ? devToolsEnhancer(devToolsConfig) : compose;
};
