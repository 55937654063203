const DurationText = ({ duration }) =>
    duration === 1
        ? '1 sec'
        : duration < 60
        ? `${duration} secs`
        : duration < 120
        ? '1 min'
        : duration < 3600
        ? `${Math.floor(duration / 60)} mins`
        : duration < 3660
        ? '1 hour'
        : duration < 3720
        ? '1h 1m'
        : duration < 7200
        ? `1h ${Math.floor((duration - 3600) / 60)}`
        : duration % 3600 < 60
        ? `${Math.floor(duration / 3600)} hours`
        : duration % 3600 < 120
        ? `${Math.floor(duration / 3600)}h 1m`
        : `${Math.floor(duration / 3600)}h ${Math.floor((duration % 3600) / 60)}m`;

export default DurationText;
