import { freeze } from 'icepick';
import {
    ANALYTICS_LIST_FETCH,
    ANALYTICS_LIST_FETCH_FAILURE,
    ANALYTICS_LIST_FETCH_SUCCESS,
} from '../../../../actions/analytics';

export const INITIAL_STATE = freeze({
    isLoading: false,
    error: '',
    data: null,
});

export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case ANALYTICS_LIST_FETCH: {
            return freeze({
                isLoading: true,
                error: '',
                data: null,
            });
        }

        case ANALYTICS_LIST_FETCH_SUCCESS: {
            return freeze({
                isLoading: false,
                error: '',
                data: payload,
            });
        }

        case ANALYTICS_LIST_FETCH_FAILURE: {
            return freeze({
                isLoading: false,
                error: payload.error,
                data: null,
            });
        }
        default:
            return state;
    }
};
