import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import BulkAnalyticsTable from './view';

BulkAnalyticsTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

BulkAnalyticsTable.defaultProps = {
    data: [],
};

export default withRouter(BulkAnalyticsTable);
