export const PUBLISH_REQUEST = 'PUBLISH_REQUEST';
export const requestPublish = () => ({
    type: PUBLISH_REQUEST,
    payload: {},
});

export const PUBLISH_REQUEST_SUCCESS = 'PUBLISH_REQUEST_SUCCESS';
export const publishRequestSuccess = () => ({
    type: PUBLISH_REQUEST_SUCCESS,
    payload: {},
});

export const PUBLISH_REQUEST_FAILURE = 'PUBLISH_REQUEST_FAILURE';
export const publishRequestFailure = () => ({
    type: PUBLISH_REQUEST_FAILURE,
    payload: {},
});

export const PUBLISH_SCHEDULE_REQUEST = 'PUBLISH_SCHEDULE_REQUEST';
export const requestPublishSchedule = date => ({
    type: PUBLISH_SCHEDULE_REQUEST,
    payload: {
        date,
    },
});

export const PUBLISH_SCHEDULE_REQUEST_SUCCESS = 'PUBLISH_SCHEDULE_REQUEST_SUCCESS';
export const requestPublishScheduleSuccess = () => ({
    type: PUBLISH_SCHEDULE_REQUEST_SUCCESS,
    payload: {},
});

export const PUBLISH_SCHEDULE_REQUEST_FAILURE = 'PUBLISH_SCHEDULE_REQUEST_FAILURE';
export const requestPublishScheduleFailure = () => ({
    type: PUBLISH_SCHEDULE_REQUEST_FAILURE,
    payload: {},
});

export const PUBLISH_CANCEL_SCHEDULE_REQUEST = 'PUBLISH_CANCEL_SCHEDULE_REQUEST';
export const requestCancelPublishSchedule = () => ({
    type: PUBLISH_CANCEL_SCHEDULE_REQUEST,
    payload: {},
});

export const PUBLISH_CANCEL_SCHEDULE_REQUEST_FAILURE = 'PUBLISH_CANCEL_SCHEDULE_REQUEST_FAILURE';
export const requestCancelSPublishScheduleFailure = () => ({
    type: PUBLISH_CANCEL_SCHEDULE_REQUEST_FAILURE,
    payload: {},
});

export const PUBLISH_CANCEL_SCHEDULE_REQUEST_SUCCESS = 'PUBLISH_CANCEL_SCHEDULE_REQUEST_SUCCESS';
export const requestCancelSPublishScheduleSuccess = () => ({
    type: PUBLISH_CANCEL_SCHEDULE_REQUEST_SUCCESS,
    payload: {},
});

//

export const COPY_LAYOUT_REQUEST = 'COPY_LAYOUT_REQUEST';
export const requestCopyLayout = (regionToCopy, toRegions) => ({
    type: COPY_LAYOUT_REQUEST,
    payload: {
        regionToCopy,
        toRegions,
    },
});

export const COPY_LAYOUT_REQUEST_SUCCESS = 'COPY_LAYOUT_REQUEST_SUCCESS';
export const copyLayoutRequestSuccess = (regionCopied, toRegions) => ({
    type: COPY_LAYOUT_REQUEST_SUCCESS,
    payload: {
        regionCopied,
        toRegions,
    },
});

export const COPY_LAYOUT_REQUEST_FAILURE = 'COPY_LAYOUT_REQUEST_FAILURE';
export const copyLayoutRequestFailure = (regionToCopy, toRegions) => ({
    type: COPY_LAYOUT_REQUEST_FAILURE,
    payload: {
        regionToCopy,
        toRegions,
    },
});

export const PUBLISH_EDIT_SCHEDULE_PUBLISH_DATE = 'PUBLISH_EDIT_SCHEDULE_PUBLISH_DATE';
export const editScheduledPublishDate = scheduledPublishDate => ({
    type: PUBLISH_EDIT_SCHEDULE_PUBLISH_DATE,
    payload: {
        scheduledPublishDate,
    },
});

export const PUBLISH_SCHEDULE = 'PUBLISH_SCHEDULE';
export const publishSchedule = date => ({
    type: PUBLISH_SCHEDULE,
    payload: {
        date,
    },
});

export const PUBLISH_LIST_TO_REGIONS = 'PUBLISH_LIST_TO_REGIONS';
export const requestPublishListToRegions = (listId, regions) => ({
    type: PUBLISH_LIST_TO_REGIONS,
    payload: {
        listId,
        regions,
    },
});

export const PUBLISH_LIST_TO_REGIONS_SUCCESS = 'PUBLISH_LIST_TO_REGIONS_SUCCESS';
export const publishListToRegionsSuccess = (listId, regions) => ({
    type: PUBLISH_LIST_TO_REGIONS_SUCCESS,
    payload: {
        listId,
        regions,
    },
});

export const PUBLISH_LIST_TO_REGIONS_FAILURE = 'PUBLISH_LIST_TO_REGIONS_FAILURE';
export const publishListToRegionsFailure = (listId, regions) => ({
    type: PUBLISH_LIST_TO_REGIONS_FAILURE,
    payload: {
        listId,
        regions,
    },
});
