import PropTypes from 'prop-types';
import Bullet from './view';

Bullet.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
};

Bullet.defaultProps = {
    size: 16,
};

export default Bullet;
