export const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    // eslint-disable-next-line
    const { type, payload } = action;

    switch (type) {
        default:
            return state;
    }
};
