import styled from 'styled-components';
import { hoverStateTransitionMixin } from '../../pocket-casts-styles';

export const StyledLink = styled.a`
    color: ${props => props.theme.primaryInteractive01()};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.primaryInteractive01Hover()};
    }

    ${hoverStateTransitionMixin};
`;
