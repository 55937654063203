import styled from 'styled-components';
import { coolGrey60, gridSize, H70 } from 'common/pocket-casts-styles';
import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Left = styled.div`
    display: flex;

    align-items: center;
    flex-grow: 1;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;

    flex-grow: 0;
`;

// Some workarounds for sane text baseline alignment
export const WorkingStateText = styled(H70)`
    color: ${props => props.color};
    margin-right: ${3 * gridSize}px;
`;

export const LastPublishedText = styled(H70)`
    color: ${coolGrey60};
    margin-right: ${2 * gridSize}px;
`;

export const LastPublishedDateText = styled(H70)`
    color: ${props => props.theme.primaryText01()};
    margin-right: ${2 * gridSize}px;
`;

export const CopyButtonText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${12 * gridSize}px;
    height: ${5 * gridSize}px;
`;

export const Space = styled.div`
    width: ${2 * gridSize}px;
    height: ${5 * gridSize}px;
`;

export const PublishButtonText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${18 * gridSize}px;
    height: ${5 * gridSize}px;
`;

export const BackLink = styled(Link)`
    display: flex;
    width: 25px;

    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: ${2 * gridSize}px;
`;
