import { css } from 'styled-components';

export const userSelectNoneMixin = css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const hoverStateTransitionMixin = css`
    transition: all 0.1s ease;
`;
