import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, createStore, compose } from 'redux';
import composeWithDevTools from 'common/redux-util/compose-with-dev-tools';
import rootReducer from './reducers/reducer';

export default rootEpic => {
    const epicMiddleware = createEpicMiddleware();

    const middlewares = [epicMiddleware];

    // eslint-disable-next-line no-unused-vars
    const enhancers = [];

    const initialState = undefined;

    const store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools({
            name: 'Content Curator',
        })(compose(applyMiddleware(...middlewares))),
    );

    epicMiddleware.run(rootEpic);

    return store;
};
