import { connect } from 'react-redux';
import { getCurrentListItems, getCurrentListType } from 'app/state/selectors/currentList';
import {
    editListRemoveEpisode,
    editListRemovePodcast,
    editListSetEpisodes,
    editListSetPodcasts,
} from 'app/state/actions/currentList';
import { requestUpdateList } from 'app/state/actions/lists';
import PodcastList from './view';

const mapStateToProps = state => ({
    items: getCurrentListItems(state),
    listType: getCurrentListType(state),
});

const mapDispatchToProps = dispatch => ({
    setEpisodes: episodes => dispatch(editListSetEpisodes(episodes)),
    setPodcasts: podcasts => dispatch(editListSetPodcasts(podcasts)),
    deleteEpisodeFromList: uuid => dispatch(editListRemoveEpisode(uuid)),
    deletePodcastFromList: uuid => dispatch(editListRemovePodcast(uuid)),
    saveCurrentList: () => dispatch(requestUpdateList()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PodcastList);
