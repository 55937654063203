import styled from 'styled-components';
import { BC80, coolGrey50, coolGrey60, gridSize, H70, orange50 } from 'common/pocket-casts-styles';

export const PodcastListTable = styled.table`
    width: 100%;
    border-spacing: 0;
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
`;

export const BasicCellText = styled(H70)``;

export const GreyCellText = styled(H70)`
    color: ${coolGrey60};
`;

export const OrangeCellText = styled(H70)`
    color: ${orange50};
`;

export const TrHeader = styled.tr``;

export const Tr = styled.tr`
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.primaryUi04()};
    }
`;

export const Td = styled.td`
    text-align: left;
    padding: ${2 * gridSize}px ${gridSize}px;
`;

export const LastTd = styled.td`
    text-align: center;
    width: ${10 * gridSize}px;
`;

export const Th = styled.th`
    border-top: 1px solid ${props => props.theme.primaryUi05()};
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    padding: ${2 * gridSize}px ${gridSize}px;

    text-align: left;
    text-transform: uppercase;
`;

export const ThText = styled(BC80)`
    color: ${coolGrey50};
`;

export const ArchivedArea = styled.div`
    display: flex;

    flex-direction: row-reverse;
    padding: ${2 * gridSize}px 0;
`;

export const ActionWrapper = styled.div`
    display: flex;
    width: 40px;
    float: right;
    justify-content: space-between;
    padding-right: ${gridSize}px;
`;

export const ArchiveUnarchiveWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10;
`;
