import styled from 'styled-components';
import { gridSize, H80 } from 'common/pocket-casts-styles';

export const PodcastListWrapper = styled.div`
    width: 100%;
`;

export const DroppableContainer = styled.div`
    padding: 0;
    width: 798px;
`;

export const DraggableContainer = styled.div`
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // some basic styles to make the items look a bit nicer
    user-select: none;
    padding: ${gridSize * 2}px ${gridSize * 2}px ${gridSize * 2}px 0;
    margin: 0;

    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    background-color: ${props => (props.isDragging ? props.theme.primaryUi04() : 'none')};

    &:hover {
        background-color: ${props => props.theme.primaryUi04()};
    }
`;

export const NumberWrapper = styled(H80)`
    color: ${props => props.theme.primaryText02()};
    margin-right: ${2 * gridSize}px;
`;

export const GrabbyWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 9px;
    height: 27px;

    margin-right: ${2 * gridSize}px;
`;

export const InformationWrapper = styled.div`
    flex: 1;
    display: flex;

    flex-direction: row;
    align-items: center;
    justify-content: left;
`;

export const DeleteButtonWrapper = styled.div`
    cursor: pointer !important;
    padding-top: 4px;
`;

export const ImageWrapper = styled.div`
    height: ${8 * gridSize}px;
    width: ${8 * gridSize}px;
    border-radius: 2px;
    overflow: hidden;
`;

export const TitleAuthorWrapper = styled.div`
    flex: 1;
    display: flex;

    flex-direction: column;
    padding-left: ${gridSize}px;
`;

export const PodcastTitle = styled.div`
    font-weight: 700;
`;

export const PodcastAuthor = styled.div`
    font-weight: 100;
    color: gray;
`;

export const ItemInfo = styled.div`
    font-size: 14px;
    color: ${props => props.theme.secondaryText02()};
    padding: 15px 30px 15px 0;
    min-width: 60px;
    text-align: right;
    white-space: nowrap;
`;
