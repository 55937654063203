import styled from 'styled-components';
import { gridSize, H40, H60 } from 'common/pocket-casts-styles';

export const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${4 * gridSize}px;
    height: ${4 * gridSize}px;

    margin-bottom: ${3 * gridSize}px;
`;

export const Title = styled(H40)`
    text-align: center;
    margin-bottom: ${3 * gridSize}px;
`;

export const Body = styled(H60)`
    text-align: center;
    max-width: 480px;

    margin-bottom: ${4 * gridSize}px;
`;

export const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    margin-bottom: ${3 * gridSize}px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    align-self: flex-end;

    margin-bottom: ${4 * gridSize}px;
`;

export const Spacer = styled.div`
    width: ${props => props.px}px;
    height: ${props => props.px}px;
`;
