import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const StyleEditorWrapper = styled.div`
    border: 1px solid lightgrey;
    border-radius: 2px;

    margin-left: ${gridSize}px;
    margin-bottom: ${gridSize}px;
    padding-bottom: ${gridSize}px;
`;

export const FlattenedH4 = styled.h4`
    margin-top: ${gridSize}px;
    margin-bottom: ${gridSize}px;

    span {
        color: red;
    }
`;

export const FieldWrapper = styled.div`
    margin-left: ${gridSize}px;
    margin-bottom: ${gridSize * 2}px;
`;

export const FieldGroupWrapper = styled.div`
    margin-left: ${gridSize}px;
`;

export const FlattenedH5 = styled.h5`
    margin-top: ${gridSize * 2}px;
    margin-bottom: ${gridSize}px;
    font-weight: 400;
`;

export const TitleErrorDiv = styled.div`
    color: red;
    padding-top: ${gridSize}px;
    padding-right: ${gridSize}px;
`;

export const HintText = styled.p`
    color: grey;
    font-size: 12px;
`;

export const CircleImageWrapper = styled.div`
    img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: 10px 26px;
    }
`;

export const ImageWrapper = styled.div`
    img {
        width: 172px;
        max-height: 172px;
        margin: 10px 0;
    }
`;

export const FileInput = styled.input`
    width: 172px;
`;
