import React, { Component } from 'react';

export default class DeleteIcon extends Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5355 8.46449C15.145 8.07396 14.5118 8.07396 14.1213 8.46449L12 10.5858L9.87868 8.46449C9.48816 8.07396 8.85499 8.07396 8.46447 8.46449C8.07394 8.85501 8.07394 9.48818 8.46447 9.8787L10.5858 12L8.46447 14.1213C8.07394 14.5119 8.07394 15.145 8.46447 15.5356C8.85499 15.9261 9.48816 15.9261 9.87868 15.5356L12 13.4142L14.1213 15.5356C14.5118 15.9261 15.145 15.9261 15.5355 15.5356C15.9261 15.145 15.9261 14.5119 15.5355 14.1213L13.4142 12L15.5355 9.8787C15.9261 9.48818 15.9261 8.85501 15.5355 8.46449Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
}
