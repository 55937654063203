import { connect } from 'react-redux';
import { requestLists, resetCreatingFlag, changeListTab } from 'app/state/actions/lists';
import { getCreatingFlag, listTabSelector } from 'app/state/selectors/lists';
import ListBrowser from './view';
import { fetchLiveAnalytics, fetchSummaryAnalytics } from '../../../../../state/actions/analytics';

const mapStateToProps = state => ({
    creatingFlag: getCreatingFlag(state),
    listTab: listTabSelector(state),
});

const mapDispatchToProps = dispatch => ({
    loadLists: () => dispatch(requestLists()),
    loadLiveAnalytics: () => dispatch(fetchLiveAnalytics()),
    loadSummaryAnalytics: () => dispatch(fetchSummaryAnalytics()),
    resetCreatingFlag: () => dispatch(resetCreatingFlag()),
    changeListTab: listTab => dispatch(changeListTab(listTab)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ListBrowser);
