import PropTypes from 'prop-types';
import PublishConfirmationModal from './view';

PublishConfirmationModal.propTypes = {
    onPublish: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    buttonText: PropTypes.string,
};

export default PublishConfirmationModal;
