import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import RegionFlag from 'common/pocket-casts-components/RegionFlag';
import Button from 'common/pocket-casts-components/Button';
import DatetimeInput from 'common/pocket-casts-components/DatetimeInput';
import { SINGLE_REGION_MODE } from 'common/feature-flags';
import { DateText } from 'common/format';
import BackArrow from './back-arrow.svg';
import {
    HeaderWrapper,
    WorkingStateText,
    LastPublishedText,
    LastPublishedDateText,
    Space,
    PublishButtonText,
    Left,
    Right,
    BackLink,
} from './styled';
import PublishConfirmationModal from './PublishConfirmationModal';

const MODAL_ACTION_PUBLISH = 'MODAL_ACTION_PUBLISH';
const MODAL_ACTION_SCHEDULE = 'MODAL_ACTION_SCHEDULE';

export class Header extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        isLayoutLoading: PropTypes.bool.isRequired,
        workingState: PropTypes.object.isRequired,
        onPublishNow: PropTypes.func.isRequired,
        onPublishSchedule: PropTypes.func.isRequired,
        onCancelPublishSchedule: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showConfirmationModal: false,
        };
    }

    showPublishNowConfirmationModal = () => {
        this.setState({
            showConfirmationModal: true,
            modalTitle: 'Publish Discover Layout',
            modalDescription:
                'All changes you have made to the discover layout will be published, and viewable in all apps.',
            modalButtonText: 'Publish',
            modalAction: MODAL_ACTION_PUBLISH,
        });
    };

    showSchedulePublishConfirmationModal = () => {
        const date = this.props.scheduledPublishDate;

        this.setState({
            showConfirmationModal: true,
            modalTitle: 'Schedule Publish',
            modalDescription: `The Discover layout will be published on ${date}`,
            modalButtonText: 'Schedule',
            modalAction: MODAL_ACTION_SCHEDULE,
        });
    };

    closeConfirmationModal = () => {
        this.setState({
            showConfirmationModal: false,
        });
    };

    onScheduledPublishDateChange = date => {
        this.props.updateScheduledPublishDate(date);
    };

    publish = () => {
        this.props.onPublishNow();
        this.closeConfirmationModal();
    };

    schedule = () => {
        this.props.onPublishSchedule(this.props.scheduledPublishDate);
        this.closeConfirmationModal();
    };

    cancelSchedule = () => {
        this.props.onCancelPublishSchedule();
    };

    renderLeft() {
        const { region } = this.props.match.params;

        return (
            <Left>
                {!SINGLE_REGION_MODE && (
                    <BackLink title="Back to Region Selector" to={'/discover/layouts'}>
                        <img src={BackArrow} height="20" width="20" />
                    </BackLink>
                )}
                {SINGLE_REGION_MODE ? <h1>Discover</h1> : <RegionFlag region={region} asHeading />}
            </Left>
        );
    }

    renderRight() {
        const {
            isLayoutLoading,
            workingState,
            lastPublishedDate,
            scheduledPublishDate,
            isPublishScheduled,
        } = this.props;

        return (
            <Right>
                <WorkingStateText color={workingState.color}>
                    {workingState.message}
                </WorkingStateText>
                {!!lastPublishedDate && <LastPublishedText>Last published</LastPublishedText>}
                {!!lastPublishedDate && (
                    // eslint-disable-next-line
                    <LastPublishedDateText>
                        <DateText date={lastPublishedDate} />
                    </LastPublishedDateText>
                )}

                <Space />
                <DatetimeInput
                    onDatetimeChange={this.onScheduledPublishDateChange}
                    selected={scheduledPublishDate}
                    disabled={!!isLayoutLoading || isPublishScheduled}
                />
                <Space />
                {isPublishScheduled ? (
                    <Button width={136} onClick={this.cancelSchedule} loading={isLayoutLoading}>
                        <PublishButtonText>Cancel schedule</PublishButtonText>
                    </Button>
                ) : (
                    <Button
                        width={136}
                        onClick={this.showSchedulePublishConfirmationModal}
                        disabled={!scheduledPublishDate}
                        loading={isLayoutLoading}
                    >
                        <PublishButtonText>Schedule</PublishButtonText>
                    </Button>
                )}

                <Space />
                <Button
                    width={136}
                    onClick={this.showPublishNowConfirmationModal}
                    disabled={isPublishScheduled}
                    loading={isLayoutLoading}
                >
                    <PublishButtonText>Publish now</PublishButtonText>
                </Button>
            </Right>
        );
    }

    render() {
        const {
            showConfirmationModal,
            modalTitle,
            modalDescription,
            modalButtonText,
            modalAction,
        } = this.state;

        const onPublish = () => {
            if (modalAction === MODAL_ACTION_PUBLISH) {
                this.publish();
            } else if (modalAction === MODAL_ACTION_SCHEDULE) {
                this.schedule();
            }
        };
        return (
            <HeaderWrapper>
                {this.renderLeft()}
                {this.renderRight()}
                {showConfirmationModal && (
                    <PublishConfirmationModal
                        onClose={this.closeConfirmationModal}
                        onPublish={onPublish}
                        title={modalTitle}
                        description={modalDescription}
                        buttonText={modalButtonText}
                    />
                )}
            </HeaderWrapper>
        );
    }
}

export default withRouter(Header);
