import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    getCurrentListId,
    getCurrentListIsSponsored,
    getCurrentListTitle,
    getCurrentListUrlPath,
    getCurrentListIsInDiscover,
    getCurrentListPlatform,
    getCurrentListListEditable,
    getCurrentListDiscoverStyle,
    getCurrentListSubtitle,
} from 'app/state/selectors/currentList';
import { editListSetTitle, editListSetUrlPath } from 'app/state/actions/currentList';
import { requestUpdateList } from 'app/state/actions/lists';
import Header from './view';

const mapStateToProps = state => ({
    listId: getCurrentListId(state),
    title: getCurrentListTitle(state),
    subtitle: getCurrentListSubtitle(state) || '',
    urlPath: getCurrentListUrlPath(state),
    isSponsored: getCurrentListIsSponsored(state),
    isInDiscover: getCurrentListIsInDiscover(state),
    discoverStyle: getCurrentListDiscoverStyle(state),
    platform: getCurrentListPlatform(state),
    listEditable: getCurrentListListEditable(state),
});

const mapDispatchToProps = dispatch => ({
    updateTitle: title => dispatch(editListSetTitle(title)),
    updateUrlPath: shortLink => dispatch(editListSetUrlPath(shortLink)),
    saveCurrentList: () => dispatch(requestUpdateList(true)),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Header),
);
