import styled from 'styled-components';

export const NavigationWithDetailWrapper = styled.div`
    display: flex;
    height: 100%;
`;

export const NavigationWrapper = styled.div`
    width: 240px;
    flex-shrink: 0;

    @media print {
        display: none;
    }
`;
