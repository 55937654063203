import { API } from 'aws-amplify';
import { from } from 'rxjs';
import {
    EPISODE_LIST,
    DISCOVER_STYLES_EPISODES,
    DISCOVER_STYLES_PODCASTS,
    FULL_PAGE_STYLES_EPISODES,
    FULL_PAGE_STYLES_PODCASTS,
} from 'app/state/constants';

export const fetchLists = () => from(API.get('contentcurator', '/lists', {}));

export const fetchList = (baseUrl, listId) =>
    from(API.get('contentcurator', `/lists/${listId}`, {}));

export const postCreateList = listType =>
    from(
        API.post('contentcurator', '/lists', {
            body: {
                listType,
                podcasts: [],
                episodes: [],
                title: '',
                subtitle: '',
                publishDate: new Date().toISOString(),
                regions: [],
                archived: false,
                sponsored: false,
                discoverStyle:
                    listType === EPISODE_LIST
                        ? DISCOVER_STYLES_EPISODES.collection.key
                        : DISCOVER_STYLES_PODCASTS.largeList.key,
                fullPageStyle:
                    listType === EPISODE_LIST
                        ? FULL_PAGE_STYLES_EPISODES.plainList.key
                        : FULL_PAGE_STYLES_PODCASTS.plainList.key,
            },
        }),
    );

export const postImportList = bundleUuid =>
    from(
        API.post('contentcurator', '/lists/import', {
            body: {
                bundleUuid,
            },
        }),
    );

export const putUpdateList = (listId, payload) =>
    from(
        API.put('contentcurator', `/lists/${listId}`, {
            body: {
                ...payload,
            },
        }),
    );

export const fetchListSearchResults = term =>
    from(
        API.post('contentcurator', '/lists/search', {
            body: {
                term,
            },
        }),
    );
