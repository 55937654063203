import styled from 'styled-components';
import {
    BC80,
    coolGrey50,
    coolGrey60,
    green50,
    gridSize,
    H20,
    H40,
    H60,
    H80,
} from 'common/pocket-casts-styles';

export const ListAnalyticsTableWrapper = styled.div``;

export const StyledTable = styled.table`
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
`;

export const ImpressionsCol = styled.col`
    width: ${35 * gridSize}px;
`;

export const ShowAllsCol = styled.col`
    width: ${35 * gridSize}px;
`;

export const StatsCol = styled.col`
    width: ${20 * gridSize}px;
`;

export const ActionsCol = styled.col`
    width: ${10 * gridSize}px;
`;

export const TrHeader = styled.tr``;

export const Th = styled.th`
    border-top: 1px solid ${props => props.theme.primaryUi05()};
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
    padding: ${2 * gridSize}px ${gridSize}px;

    text-align: left;
    text-transform: uppercase;
`;

export const ThText = styled(BC80)`
    color: ${coolGrey50};
`;

export const Tr = styled.tr`
    &:hover {
        background-color: ${props => props.theme.primaryUi04()};
    }

    @media print {
        page-break-inside: avoid;
    }
`;

export const Td = styled.td`
    padding: ${2 * gridSize}px ${gridSize}px;
    border-bottom: 1px solid ${props => props.theme.primaryUi05()};
`;

export const TdBigText = styled(H20)`
    font-weight: 400;
`;

export const TdTwoRows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const TdSmallColumns = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TdSmallText = styled(H60)`
    display: inline-block;
    color: ${coolGrey50};

    margin-right: ${gridSize}px;
`;

export const TdGreenText = styled(H60)`
    display: inline-block;
    color: ${green50};
`;

export const PodcastWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PodcastNumberWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: ${2 * gridSize}px;
`;

export const PodcastNumber = styled(H80)`
    opacity: 0.4;
`;

export const PodcastCoverWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: ${8 * gridSize}px;
    min-height: ${8 * gridSize}px;

    width: ${8 * gridSize}px;
    height: ${8 * gridSize}px;

    margin-right: ${2 * gridSize}px;

    border-radius: 2px;
    overflow: hidden;
`;

export const PodcastDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const PodcastName = styled(H40)`
    font-weight: 500;
    white-space: nowrap;
`;

export const PodcastAuthor = styled(H60)`
    color: ${coolGrey60};
    white-space: nowrap;
`;
