import React, { Component } from 'react';
import { StyledLink } from './styled';

class Link extends Component {
    render() {
        return (
            // eslint-disable-next-line no-script-url
            <StyledLink {...this.props} children="">
                {this.props.children}
            </StyledLink>
        );
    }
}

export default Link;
