import { of } from 'rxjs';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
    LAYOUT_REQUEST,
    LAYOUT_UPDATE_REQUEST,
    layoutRequestSuccess,
    layoutRequestFailure,
    layoutUpdateRequestSuccess,
    layoutUpdateRequestFailure,
} from 'app/state/actions/editingLayout';
import { fetchLayoutData, saveLayoutData } from 'app/services/layouts';

const layoutRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(LAYOUT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            fetchLayoutData(state, action.payload.region).pipe(
                map(layout => layoutRequestSuccess(action.payload.region, layout)),
                catchError(e => {
                    console.error('Error in layoutRequestEpic: ', e);
                    return of(layoutRequestFailure(action.payload.region));
                }),
            ),
        ),
    );

const layoutSaveEpic = (action$, state$) =>
    action$.pipe(
        ofType(LAYOUT_UPDATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            saveLayoutData(state, action.payload.region).pipe(
                map(() => layoutUpdateRequestSuccess(action.payload.region)),
                catchError(e => {
                    console.error('Error in layoutSaveEpic: ', e);
                    return of(layoutUpdateRequestFailure(action.payload.region));
                }),
            ),
        ),
    );

export default combineEpics(layoutRequestEpic, layoutSaveEpic);
