import React, { Component } from 'react';
import { ButtonWrapper, LoadingIcon } from './styled';
import LoadingCircle from './loading-circle.svg';

export class Button extends Component {
    render() {
        const { type, kind, width, small, onClick, disabled, loading, children } = this.props;

        if (kind === 'text') {
            return (
                <button type={type} disabled={disabled} onClick={onClick}>
                    {children}
                </button>
            );
        }

        const height = small ? 37 : 48;

        const disabledProp = loading
            ? {
                  disabled: loading,
              }
            : {};

        return (
            <ButtonWrapper
                kind={kind}
                type={type}
                width={width}
                height={height}
                disabled={disabled}
                onClick={onClick}
                {...disabledProp}
            >
                {loading ? (
                    <LoadingIcon>
                        <img width={24} height={24} src={LoadingCircle} />
                    </LoadingIcon>
                ) : (
                    children
                )}
            </ButtonWrapper>
        );
    }
}

export default Button;
