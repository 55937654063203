import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    ImageWrapper,
    PodcastAuthor,
    PodcastTitle,
    SuggestionRow,
    SuggestionWrapper,
    TitleAuthorWrapper,
} from './styled';
import ListIcon from './list-icon.png';

export class PodcastSuggestion extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,

        onMouseDown: PropTypes.func.isRequired,
    };

    render() {
        const { title, author, onMouseDown } = this.props;

        const shortTitle = title.substring(0, 100);
        const displayTitle = shortTitle === title ? title : `${shortTitle}...`;

        const shortAuthor = author.substring(0, 100);
        const displayAuthor = shortAuthor === author ? author : `${shortAuthor}...`;

        return (
            <SuggestionWrapper onMouseDown={onMouseDown}>
                <SuggestionRow>
                    <ImageWrapper>
                        <img width="24px" height="24px" src={ListIcon} />
                    </ImageWrapper>
                    <TitleAuthorWrapper>
                        <PodcastTitle>{displayTitle}</PodcastTitle>
                        <PodcastAuthor>({displayAuthor})</PodcastAuthor>
                    </TitleAuthorWrapper>
                </SuggestionRow>
            </SuggestionWrapper>
        );
    }
}

export default PodcastSuggestion;
