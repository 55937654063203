import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'common/pocket-casts-components/LoadingIndicator';
import { SINGLE_REGION_MODE } from 'common/feature-flags';
import Header from './Header';
import MetadataEditor from './MetadataEditor';
import PodcastSearch from './PodcastSearch';
import RegionEditor from './RegionDisplay';
import StyleEditor from './StyleEditor';
import PodcastList from './PodcastList';
import ListPublishDialog from './ListPublishDialog';
import {
    CreateListPageLayout,
    PodcastListAndMetadataLayout,
    LowerLeftWrapper,
    LowerRightWrapper,
    Side,
} from './styled';

export class EditListPage extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        match: PropTypes.object.isRequired,

        requestList: PropTypes.func.isRequired,
        publishListToRegions: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.props.requestList(this.props.match.params.listId);

        this.state = {
            showPublishDialog: false,
            headerMessage: '',
        };
    }

    onCancelPublish = () => {
        this.setState({
            showPublishDialog: false,
        });
    };

    onPublish = regions => {
        this.props.publishListToRegions(this.props.match.params.listId, regions);

        this.setState({
            showPublishDialog: false,
        });
    };

    renderLoadingState() {
        return (
            <CreateListPageLayout>
                <LoadingIndicator size={100} />
            </CreateListPageLayout>
        );
    }

    renderWorkingState() {
        const { showPublishDialog } = this.state;
        const { listEditable } = this.props;

        return (
            <CreateListPageLayout>
                <Header />
                <MetadataEditor />
                <PodcastListAndMetadataLayout>
                    <LowerLeftWrapper>
                        <Side>
                            {listEditable && <PodcastSearch />}
                            <PodcastList />
                        </Side>
                    </LowerLeftWrapper>
                    <LowerRightWrapper>
                        <Side>
                            <StyleEditor />
                            {!SINGLE_REGION_MODE && <RegionEditor />}
                        </Side>
                    </LowerRightWrapper>
                </PodcastListAndMetadataLayout>
                {showPublishDialog && !SINGLE_REGION_MODE && (
                    <ListPublishDialog onPublish={this.onPublish} onCancel={this.onCancelPublish} />
                )}
            </CreateListPageLayout>
        );
    }

    render() {
        const { isLoading } = this.props;

        return isLoading ? this.renderLoadingState() : this.renderWorkingState();
    }
}

export default EditListPage;
