import PropTypes from 'prop-types';
import NavigationOption from './view';

NavigationOption.propTypes = {
    location: PropTypes.object,
    navigationItem: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default NavigationOption;
