import { coolGrey50, red50 } from './colors';

/*
 * Internal state functions for the theme system.
 */

const internalThemeVariables = {
    currentFilterColor: red50,
    currentPodcastColor: coolGrey50,
};

export const getCurrentFilterColor = () => internalThemeVariables.currentFilterColor;

export const setCurrentFilterColor = color => {
    internalThemeVariables.currentFilterColor = color;
};

export const getCurrentPodcastColor = () => internalThemeVariables.currentPodcastColor;

export const setCurrentPodcastColor = color => {
    internalThemeVariables.currentPodcastColor = color;
};

/*
 * Internal helper functions for theme system.
 * https://stackoverflow.com/a/5624139
 */

export const hexToRgb = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

const componentToHex = c => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
};

export const rgbToHex = (r, g, b) =>
    `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

export const getCommaSeparatedStringOfRGB = color => {
    const asRgb = hexToRgb(color);

    if (asRgb) {
        return `${asRgb.r}, ${asRgb.g}, ${asRgb.b}`;
    }

    // TODO: Implement other matchers to convert (e.g) "rgb(1, 2, 3)" to "1, 2, 3".
    // For now we don't anticipate sending non-hex strings to this function.

    return '0, 0, 0';
};
