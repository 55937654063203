import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import {
    sendPublish,
    sendPublishSchedule,
    sendCancelPublishSchedule,
    sendCopy,
    sendPublishListToRegions,
} from 'app/services/publish';
import {
    PUBLISH_REQUEST,
    PUBLISH_SCHEDULE_REQUEST,
    PUBLISH_CANCEL_SCHEDULE_REQUEST,
    COPY_LAYOUT_REQUEST,
    PUBLISH_LIST_TO_REGIONS,
    publishRequestSuccess,
    publishRequestFailure,
    requestPublishScheduleSuccess,
    requestPublishScheduleFailure,
    requestCancelSPublishScheduleSuccess,
    requestCancelSPublishScheduleFailure,
    copyLayoutRequestSuccess,
    copyLayoutRequestFailure,
    publishListToRegionsSuccess,
    publishListToRegionsFailure,
} from 'app/state/actions/publish';
import { requestList } from 'app/state/actions/lists';

const publishEpic = action$ =>
    action$.pipe(
        ofType(PUBLISH_REQUEST),
        mergeMap(() =>
            sendPublish().pipe(
                map(() => publishRequestSuccess()),
                catchError(e => {
                    console.error('Error in publish epic: ', e);
                    return of(publishRequestFailure());
                }),
            ),
        ),
    );

const publishScheduleEpic = action$ =>
    action$.pipe(
        ofType(PUBLISH_SCHEDULE_REQUEST),
        mergeMap(action =>
            sendPublishSchedule(action.payload.date).pipe(
                map(() => requestPublishScheduleSuccess()),
                catchError(e => {
                    console.error('Error in publish schedule epic: ', e);
                    return of(requestPublishScheduleFailure());
                }),
            ),
        ),
    );

const cancelPublishScheduleEpic = action$ =>
    action$.pipe(
        ofType(PUBLISH_CANCEL_SCHEDULE_REQUEST),
        mergeMap(() =>
            sendCancelPublishSchedule().pipe(
                map(() => requestCancelSPublishScheduleSuccess()),
                catchError(e => {
                    console.error('Error in cancel publish schedule epic: ', e);
                    return of(requestCancelSPublishScheduleFailure());
                }),
            ),
        ),
    );

const copyEpic = action$ =>
    action$.pipe(
        ofType(COPY_LAYOUT_REQUEST),
        mergeMap(action =>
            sendCopy(action.payload.regionToCopy, action.payload.toRegions).pipe(
                map(() =>
                    copyLayoutRequestSuccess(action.payload.regionToCopy, action.payload.toRegions),
                ),
                catchError(e => {
                    console.error('Error in copy epic: ', e);
                    return of(
                        copyLayoutRequestFailure(
                            action.payload.regionToCopy,
                            action.payload.toRegions,
                        ),
                    );
                }),
            ),
        ),
    );

const publishListToRegionsEpic = action$ =>
    action$.pipe(
        ofType(PUBLISH_LIST_TO_REGIONS),
        mergeMap(action =>
            sendPublishListToRegions(action.payload.listId, action.payload.regions).pipe(
                mergeMap(() =>
                    of(
                        publishListToRegionsSuccess(action.payload.listId, action.payload.regions),
                        requestList(action.payload.listId),
                    ),
                ),
                catchError(e => {
                    console.error('Error in publish-list-to-regions epic: ', e);
                    return of(
                        publishListToRegionsFailure(action.payload.listId, action.payload.regions),
                    );
                }),
            ),
        ),
    );

export default combineEpics(
    publishEpic,
    publishScheduleEpic,
    cancelPublishScheduleEpic,
    copyEpic,
    publishListToRegionsEpic,
);
