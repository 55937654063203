export const getLiveAnalyticsData = state => state.entities.analytics.live.data;
export const getLiveAnalyticsDataIsLoading = state => state.entities.analytics.live.isLoading;
export const getLiveAnalyticsDataError = state => state.entities.analytics.live.error;

export const getSummaryAnalyticsData = state => state.entities.analytics.summary.data;
export const getSummaryAnalyticsDataIsLoading = state => state.entities.analytics.summary.isLoading;
export const getSummaryAnalyticsDataError = state => state.entities.analytics.summary.error;

export const getCurrentListAnalyticsData = state => state.entities.analytics.currentList.data;
export const getCurrentListAnalyticsDataIsLoading = state =>
    state.entities.analytics.currentList.isLoading;
export const getCurrentListAnalyticsDataError = state => state.entities.analytics.currentList.error;

/* Filter the list and find the one that was updated last */
export const getLastUpdatedList = state => {
    const analyticsData = getLiveAnalyticsData(state);
    const filteredData =
        analyticsData && analyticsData.length > 0
            ? analyticsData.filter(x => x.updatedDate != null)
            : [];
    if (filteredData.length > 0) {
        return filteredData.reduce((a, b) => (a.updatedDate > b.updatedDate ? a : b));
    }
    return null;
};
