import { freeze, setIn, chain } from 'icepick';

import {
    CURRENT_LIST_SET_TITLE,
    CURRENT_LIST_SET_DESCRIPTION,
    CURRENT_LIST_SET_EPISODES,
    CURRENT_LIST_SET_PODCASTS,
    CURRENT_LIST_SET_REGIONS,
    CURRENT_LIST_SET_SPONSORED,
    CURRENT_LIST_SET_SUBTITLE,
    CURRENT_LIST_SET_WEB_URL,
    CURRENT_LIST_SET_WEB_TITLE,
    CURRENT_LIST_SET_COLOR_ON_LIGHT_BACKGROUND,
    CURRENT_LIST_SET_COLOR_ON_DARK_BACKGROUND,
    CURRENT_LIST_SET_COLLECTION_IMAGE,
    CURRENT_LIST_SET_HEADER_IMAGE,
    CURRENT_LIST_SET_COLLECTION_IMAGE_UPLOADING,
    CURRENT_LIST_SET_HEADER_IMAGE_UPLOADING,
    CURRENT_LIST_SET_DISCOVER_STYLE,
    CURRENT_LIST_SET_FULL_PAGE_STYLE,
    CURRENT_LIST_SET_ALLOWED_REGIONS,
    CURRENT_LIST_ADD_EPISODE,
    CURRENT_LIST_ADD_PODCAST,
    CURRENT_LIST_REMOVE_EPISODE,
    CURRENT_LIST_REMOVE_PODCAST,
    CURRENT_LIST_SET_URL_PATH,
    CURRENT_LIST_SET_PLATFORM,
} from 'app/state/actions/currentList';
import {
    LIST_REQUEST,
    LIST_REQUEST_FAILURE,
    LIST_REQUEST_SUCCESS,
    LIST_UPDATE_REQUEST,
    LIST_UPDATE_REQUEST_SUCCESS,
    LIST_UPDATE_REQUEST_FAILURE,
} from 'app/state/actions/lists';
import { ALLOWED_REGIONS } from 'app/state/constants';
import { PODCAST_LIST } from '../../../constants';

export const INITIAL_STATE = freeze({
    id: '',
    title: '',
    subtitle: '',
    description: '',
    colorOnLightBackground: '',
    colorOnDarkBackground: '',
    webUrl: '',
    webTitle: '',
    allowedRegions: ALLOWED_REGIONS.worldwide.key,
    urlPath: '',
    episodes: [],
    podcasts: [],
    updatedDate: '',
    publishDate: '',
    createdDate: '',
    creator: '',
    regions: [],
    sponsored: false,
    discoverStyle: '',
    fullPageStyle: '',
    collectionImage: '',
    headerImage: '',
    isLoading: true,
    platform: ['ios'],
    listEditable: true,
    listType: PODCAST_LIST,
});

// currentList reducer
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case LIST_REQUEST:
            return setIn(state, ['isLoading'], true);
        case LIST_REQUEST_FAILURE:
            return setIn(state, ['isLoading'], true); // TODO: error state
        case LIST_REQUEST_SUCCESS: {
            const {
                id,
                title,
                description,
                urlPath,
                episodes,
                podcasts,
                publishDate,
                updatedDate,
                unpublishDate,
                createdDate,
                creator,
                regions,
                sponsored,
                discoverStyle,
                fullPageStyle,
                subtitle,
                colorOnLightBackground,
                colorOnDarkBackground,
                webUrl,
                webTitle,
                allowedRegions,
                collectionImage,
                headerImage,
                platform,
                listEditable,
                listType,
            } = payload.list;

            return freeze({
                id,
                title,
                description,
                urlPath,
                episodes: episodes || [],
                podcasts,
                updatedDate,
                publishDate,
                unpublishDate,
                createdDate,
                creator,
                regions,
                sponsored,
                discoverStyle,
                fullPageStyle,
                subtitle,
                colorOnLightBackground,
                colorOnDarkBackground,
                webUrl,
                webTitle,
                allowedRegions,
                collectionImage,
                headerImage,
                isLoading: false,
                platform,
                listEditable: listEditable !== false, // if null default to true
                listType,
            });
        }
        case LIST_UPDATE_REQUEST:
            // We don't want a 'Saving...' marker.
            return state;
        case LIST_UPDATE_REQUEST_SUCCESS:
            return state;
        case LIST_UPDATE_REQUEST_FAILURE:
            return state;
        case CURRENT_LIST_SET_TITLE:
            return chain(state)
                .setIn(['title'], payload.title)
                .value();
        case CURRENT_LIST_SET_DESCRIPTION:
            return chain(state)
                .setIn(['description'], payload.description)
                .value();
        case CURRENT_LIST_SET_URL_PATH:
            return chain(state)
                .setIn(['urlPath'], payload.path)
                .value();
        case CURRENT_LIST_SET_EPISODES:
            return chain(state)
                .setIn(['episodes'], payload.episodes)
                .value();
        case CURRENT_LIST_SET_PODCASTS:
            return chain(state)
                .setIn(['podcasts'], payload.podcasts)
                .value();
        case CURRENT_LIST_ADD_EPISODE: {
            const uuidToAdd = payload.episode.uuid;
            const index = state.episodes.findIndex(e => e.uuid === uuidToAdd);

            if (index === -1) {
                return chain(state)
                    .setIn(['episodes'], state.episodes.concat(payload.episode))
                    .value();
            }

            const newArray = state.episodes.slice();
            newArray.splice(index, 1);

            return chain(state)
                .setIn(['episodes'], newArray.concat(payload.episode))
                .value();
        }
        case CURRENT_LIST_ADD_PODCAST: {
            const uuidToAdd = payload.podcast.uuid;
            const index = state.podcasts.findIndex(p => p.uuid === uuidToAdd);

            if (index === -1) {
                return chain(state)
                    .setIn(['podcasts'], state.podcasts.concat(payload.podcast))
                    .value();
            }

            const newArray = state.podcasts.slice();
            newArray.splice(index, 1);

            return chain(state)
                .setIn(['podcasts'], newArray.concat(payload.podcast))
                .value();
        }
        case CURRENT_LIST_REMOVE_EPISODE: {
            const uuidToRemove = payload.episodeUuid;
            const index = state.episodes.findIndex(e => e.uuid === uuidToRemove);

            if (index === -1) {
                return state;
            }

            const newArray = state.episodes.slice();
            newArray.splice(index, 1);

            return chain(state)
                .setIn(['episodes'], newArray)
                .value();
        }
        case CURRENT_LIST_REMOVE_PODCAST: {
            const uuidToRemove = payload.podcastUuid;
            const index = state.podcasts.findIndex(p => p.uuid === uuidToRemove);

            if (index === -1) {
                return state;
            }

            const newArray = state.podcasts.slice();
            newArray.splice(index, 1);

            return chain(state)
                .setIn(['podcasts'], newArray)
                .value();
        }
        case CURRENT_LIST_SET_REGIONS:
            return chain(state)
                .setIn(['regions'], payload.regions)
                .value();
        case CURRENT_LIST_SET_SPONSORED:
            return chain(state)
                .setIn(['sponsored'], payload.sponsored === 'yes')
                .value();
        case CURRENT_LIST_SET_SUBTITLE:
            return chain(state)
                .setIn(['subtitle'], payload.subtitle)
                .value();
        case CURRENT_LIST_SET_WEB_URL:
            return chain(state)
                .setIn(['webUrl'], payload.webUrl)
                .value();
        case CURRENT_LIST_SET_WEB_TITLE:
            return chain(state)
                .setIn(['webTitle'], payload.webTitle)
                .value();
        case CURRENT_LIST_SET_COLOR_ON_LIGHT_BACKGROUND:
            return chain(state)
                .setIn(['colorOnLightBackground'], payload.colorOnLightBackground)
                .value();
        case CURRENT_LIST_SET_COLOR_ON_DARK_BACKGROUND:
            return chain(state)
                .setIn(['colorOnDarkBackground'], payload.colorOnDarkBackground)
                .value();
        case CURRENT_LIST_SET_COLLECTION_IMAGE:
            return chain(state)
                .setIn(['collectionImage'], payload.collectionImage)
                .value();
        case CURRENT_LIST_SET_HEADER_IMAGE:
            return chain(state)
                .setIn(['headerImage'], payload.headerImage)
                .value();
        case CURRENT_LIST_SET_COLLECTION_IMAGE_UPLOADING:
            return chain(state)
                .setIn(['collectionImageUploading'], payload.collectionImageUploading)
                .value();
        case CURRENT_LIST_SET_HEADER_IMAGE_UPLOADING:
            return chain(state)
                .setIn(['headerImageUploading'], payload.headerImageUploading)
                .value();
        case CURRENT_LIST_SET_DISCOVER_STYLE:
            return chain(state)
                .setIn(['discoverStyle'], payload.discoverStyle)
                .value();
        case CURRENT_LIST_SET_FULL_PAGE_STYLE:
            return chain(state)
                .setIn(['fullPageStyle'], payload.fullPageStyle)
                .value();
        case CURRENT_LIST_SET_ALLOWED_REGIONS:
            return chain(state)
                .setIn(['allowedRegions'], payload.allowedRegions)
                .value();
        case CURRENT_LIST_SET_PLATFORM: {
            if (payload.checked) {
                // add platform
                const index = state.platform.findIndex(p => p === payload.platform);
                if (index !== -1) {
                    return state;
                }
                return chain(state)
                    .setIn(['platform'], state.platform.concat(payload.platform))
                    .value();
            }

            // remove platform
            const newArray = state.platform.filter(platform => platform !== payload.platform);
            return chain(state)
                .setIn(['platform'], newArray)
                .value();
        }

        default:
            return state;
    }
};
