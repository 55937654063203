import React, { Component } from 'react';
import Moment from 'moment';
import { ListItemCount } from 'common/format';
import {
    BulkAnalyticsTableWrapper,
    StyledTable,
    PublishedCol,
    ActionsCol,
    TrHeader,
    Th,
    ThText,
    Tr,
    Td,
    TdBigText,
    TdMidText,
    TdSmallText,
    TdGreenText,
    TdOrangeText,
    TdTwoRows,
    TdSmallColumns,
} from './styled';

class BulkAnalyticsTable extends Component {
    navigateToListAnalytics = id => {
        const { match } = this.props;
        this.props.history.push(`${match.url}/stats/${id}`);
    };

    render() {
        const { data } = this.props;

        return (
            <BulkAnalyticsTableWrapper>
                <StyledTable>
                    <colgroup>
                        <col />
                        <PublishedCol />
                        <col />
                        <col />
                        <col />
                        <ActionsCol />
                    </colgroup>
                    <tbody>
                        <TrHeader>
                            <Th>
                                <ThText>List Details</ThText>
                            </Th>
                            <Th>
                                <ThText>Published</ThText>
                            </Th>
                            <Th>
                                <ThText>Unique Impressions</ThText>
                            </Th>
                            <Th>
                                <ThText>Taps</ThText>
                            </Th>
                            <Th>
                                <ThText>Plays</ThText>
                            </Th>
                            <Th>
                                <ThText>Subscribes</ThText>
                            </Th>
                            <Th />
                        </TrHeader>
                        {data.map((listData, index) => (
                            <Tr
                                key={`${listData.id}-${index}`}
                                onClick={() => this.navigateToListAnalytics(listData.id)}
                            >
                                <Td>
                                    <TdTwoRows>
                                        <TdMidText>{listData.title}</TdMidText>
                                        <div>
                                            <TdSmallText>
                                                <ListItemCount
                                                    count={
                                                        listData.itemCount || listData.podcastCount
                                                    }
                                                    listType={listData.listType}
                                                />
                                            </TdSmallText>
                                            {listData.sponsored && (
                                                <TdOrangeText>Sponsored</TdOrangeText>
                                            )}
                                        </div>
                                    </TdTwoRows>
                                </Td>
                                <Td>
                                    <TdTwoRows>
                                        <TdMidText>
                                            {listData.publishDate
                                                ? Moment(new Date(listData.publishDate)).format(
                                                      'D MMM YYYY, h:mm a',
                                                  )
                                                : '-'}
                                        </TdMidText>
                                        <TdSmallText>
                                            {listData.publishDate
                                                ? Moment(new Date(listData.publishDate)).fromNow()
                                                : '-'}
                                        </TdSmallText>
                                    </TdTwoRows>
                                </Td>
                                <Td>
                                    <TdTwoRows>
                                        <TdBigText>
                                            {Number(listData.uniqueImpressions).toLocaleString()}
                                        </TdBigText>
                                        <TdSmallColumns>
                                            <TdSmallText>
                                                {Number(listData.impressions).toLocaleString()}
                                            </TdSmallText>
                                            <TdGreenText>
                                                (
                                                {(
                                                    listData.impressions /
                                                    (listData.uniqueImpressions || 1)
                                                ).toPrecision(2)}
                                                &nbsp;:&nbsp;1)
                                            </TdGreenText>
                                        </TdSmallColumns>
                                    </TdTwoRows>
                                </Td>
                                <Td>
                                    <TdTwoRows>
                                        <TdBigText>
                                            {listData.engagements.toLocaleString()}
                                        </TdBigText>
                                        <TdSmallText>&nbsp;</TdSmallText>
                                    </TdTwoRows>
                                </Td>
                                <Td>
                                    <TdTwoRows>
                                        <TdBigText>{listData.plays.toLocaleString()}</TdBigText>
                                        <TdSmallText>&nbsp;</TdSmallText>
                                    </TdTwoRows>
                                </Td>
                                <Td>
                                    <TdTwoRows>
                                        <TdBigText>
                                            {listData.subscribes.toLocaleString()}
                                        </TdBigText>
                                        <TdSmallText>&nbsp;</TdSmallText>
                                    </TdTwoRows>
                                </Td>
                                <Td />
                            </Tr>
                        ))}
                    </tbody>
                </StyledTable>
            </BulkAnalyticsTableWrapper>
        );
    }
}

export default BulkAnalyticsTable;
