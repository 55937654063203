import { connect } from 'react-redux';
import { editScheduledPublishDate } from 'app/state/actions/publish';
import Header from './view';
import {
    getEditingLayoutLastPublishedDate,
    getEditingLayoutScheduledPublishDate,
    getEditingLayoutIsPublishScheduled,
} from '../../../../../../state/selectors/editingLayout';

const mapStateToProps = state => ({
    lastPublishedDate: getEditingLayoutLastPublishedDate(state),
    scheduledPublishDate: getEditingLayoutScheduledPublishDate(state),
    isPublishScheduled: getEditingLayoutIsPublishScheduled(state),
});

const mapDispatchToProps = dispatch => ({
    updateScheduledPublishDate: title => dispatch(editScheduledPublishDate(title)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Header);
