import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EPISODE_LIST, PODCAST_LIST } from 'app/state/constants';
import { ListItemCount } from 'common/format';
import {
    MetadataWrapper,
    NumPodcastsWrapper,
    DescriptionWrapper,
    DescriptionTextArea,
    DescriptionReadOnly,
} from './styled';

export class MetadataEditor extends Component {
    static propTypes = {
        count: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        listType: PropTypes.oneOf([EPISODE_LIST, PODCAST_LIST]).isRequired,

        updateDescription: PropTypes.func.isRequired,
    };

    updateDescriptionNoPush = event => {
        this.props.updateDescription(event.target.value);
    };

    render() {
        const { count, description, listEditable, listType } = this.props;

        const visibleDescription = description.trim() === '' ? '' : description;

        return (
            <MetadataWrapper>
                <NumPodcastsWrapper>
                    <ListItemCount count={count} listType={listType} />
                </NumPodcastsWrapper>
                <DescriptionWrapper>
                    {listEditable ? (
                        <DescriptionTextArea
                            placeholder="Add description..."
                            value={visibleDescription}
                            onChange={this.updateDescriptionNoPush}
                        />
                    ) : (
                        <DescriptionReadOnly>{visibleDescription}</DescriptionReadOnly>
                    )}
                </DescriptionWrapper>
            </MetadataWrapper>
        );
    }
}

export default MetadataEditor;
