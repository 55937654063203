import React, { Component } from 'react';

export default class ListIcon extends Component {
    render() {
        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    d="M4.5 24.5H13.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 18.5H13.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 12.5H24.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 6.5H24.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.5 26.5C18.7386 26.5 16.5 24.2614 16.5 21.5C16.5 18.7386 18.7386 16.5 21.5 16.5C24.2614 16.5 26.5 18.7386 26.5 21.5M21.5 23.5C20.3954 23.5 19.5 22.6046 19.5 21.5C19.5 20.3954 20.3954 19.5 21.5 19.5C22.6046 19.5 23.5 20.3954 23.5 21.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                />
            </svg>
        );
    }
}
