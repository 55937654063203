import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { LAYOUT_WORKING_STATE_MESSAGES } from 'app/state/constants';
import Header from './Header';
import ListSearch from './ListSearch';
import Layout from './Layout';
import LayoutCopyDialog from './LayoutCopyDialog';
import { LayoutEditorWrapper } from './styled';

export class LayoutEditor extends Component {
    static propTypes = {
        isLayoutLoading: PropTypes.bool.isRequired,
        layoutRegion: PropTypes.string.isRequired,
        layoutWorkingState: PropTypes.object.isRequired,

        match: PropTypes.object.isRequired,
        fetchLayout: PropTypes.func.isRequired,
        copyLayout: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { showCopyDialog: false };
        this.props.fetchLayout(this.props.match.params.region);
    }

    componentDidUpdate(prevProps) {
        const { layoutWorkingState } = this.props;

        // When a publish is successful, fetch the layout again to update the diff view
        if (
            prevProps.layoutWorkingState !== LAYOUT_WORKING_STATE_MESSAGES.published &&
            layoutWorkingState === LAYOUT_WORKING_STATE_MESSAGES.published
        ) {
            setTimeout(() => this.props.fetchLayout(this.props.match.params.region), 1500);
        }
    }

    onCopyToClicked = () => {
        this.setState({
            showCopyDialog: true,
        });
    };

    onCopyCancelled = () => {
        this.setState({
            showCopyDialog: false,
        });
    };

    onCopy = regions => {
        this.props.copyLayout(this.props.layoutRegion, regions);

        this.setState({
            showCopyDialog: false,
        });
    };

    onPublishNow = () => {
        // In SINGLE_REGION_MODE, this will always be the global layout, and
        // the server handles pushing that to all other layouts on publish.
        this.props.publishLayout();
    };

    onPublishSchedule = date => {
        this.props.publishSchedule(date);
    };

    onCancelPublishSchedule = () => {
        this.props.cancelPublishSchedule();
    };

    render() {
        const { isLayoutLoading, layoutWorkingState, layoutRegion } = this.props;
        const { showCopyDialog } = this.state;

        return (
            <LayoutEditorWrapper>
                <Header
                    isLayoutLoading={isLayoutLoading}
                    onCopy={this.onCopyToClicked}
                    onPublishNow={this.onPublishNow}
                    onPublishSchedule={this.onPublishSchedule}
                    onCancelPublishSchedule={this.onCancelPublishSchedule}
                    workingState={layoutWorkingState}
                />
                {!isLayoutLoading && <ListSearch />}
                {!isLayoutLoading && <Layout />}
                {showCopyDialog && (
                    <LayoutCopyDialog
                        region={layoutRegion}
                        onCopy={this.onCopy}
                        onCancel={this.onCopyCancelled}
                    />
                )}
            </LayoutEditorWrapper>
        );
    }
}

export default withRouter(LayoutEditor);
