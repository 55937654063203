import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { black, blue50, coolGrey50, darkGrey10, darkGrey30 } from 'common/pocket-casts-styles';
import { NavigationItems } from '../model';
import { featuredIcon, filterIcon, layoutsIcon, notchIcon, podcastListsIcon } from '../icons';
import {
    NavigationOptionWrapper,
    DisabledNavigationOptionWrapper,
    NavigationOptionLink,
    NotchWrapper,
    IconWrapper,
    SelectedTextWrapper,
    UnselectedTextWrapper,
    DisabledIconWrapper,
    DisabledTextWrapper,
} from './styled';

class NavigationOption extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
        };
    }

    onMouseEnter = () => {
        this.setState({
            hover: true,
        });
    };

    onMouseLeave = () => {
        this.setState({
            hover: false,
        });
    };

    renderIcon = (id, size) => {
        switch (id) {
            case NavigationItems.LAYOUTS.id:
                return layoutsIcon(size);
            case NavigationItems.FEATURED.id:
                return featuredIcon(size);
            case NavigationItems.LISTS.id:
                return podcastListsIcon(size);
            default:
                return filterIcon(size);
        }
    };

    calculateIconColor = (selected, disabled) => {
        if (disabled) {
            return coolGrey50;
        }

        if (selected) {
            return darkGrey30;
        }
        return coolGrey50;
    };

    calculateTextColor = (selected, disabled) => {
        if (disabled) {
            return coolGrey50;
        }

        if (selected) {
            return black;
        }
        return darkGrey10;
    };

    render() {
        const {
            location,
            navigationItem: { id, path, text, disabled },
        } = this.props;

        const selected = location ? location.pathname.startsWith(path) : false;

        const iconColor = this.calculateIconColor(selected, disabled);
        const textColor = this.calculateTextColor(selected, disabled);
        const notchColor = selected ? blue50 : coolGrey50;

        const TextWrapper = selected ? SelectedTextWrapper : UnselectedTextWrapper;

        // This value is the line-height of TextWrapper.
        const lineHeight = 18 * 1.44;

        return (
            <NavigationOptionWrapper
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {disabled ? (
                    <DisabledNavigationOptionWrapper>
                        <DisabledIconWrapper size={lineHeight}>
                            {this.renderIcon(id, lineHeight)}
                        </DisabledIconWrapper>
                        <DisabledTextWrapper>{text}</DisabledTextWrapper>
                    </DisabledNavigationOptionWrapper>
                ) : (
                    <NavigationOptionLink to={path} color={textColor} selected={selected}>
                        <NotchWrapper>{notchIcon(notchColor)}</NotchWrapper>
                        <IconWrapper color={iconColor} size={lineHeight}>
                            {this.renderIcon(id, lineHeight)}
                        </IconWrapper>
                        <TextWrapper>{text}</TextWrapper>
                    </NavigationOptionLink>
                )}
            </NavigationOptionWrapper>
        );
    }
}

export default withRouter(NavigationOption);
