export const TOGGLE_SHOW_ARCHIVED_LISTS = 'TOGGLE_SHOW_ARCHIVED_LISTS';
export const toggleShowArchivedLists = () => ({
    type: TOGGLE_SHOW_ARCHIVED_LISTS,
    payload: {},
});

export const LIST_BROWSER_RESET_CREATING_FLAG = 'LIST_BROWSER_RESET_CREATING_FLAG';
export const resetCreatingFlag = () => ({
    type: LIST_BROWSER_RESET_CREATING_FLAG,
    payload: {},
});

//

export const LISTS_REQUEST = 'LISTS_REQUEST';
export const requestLists = () => ({
    type: LISTS_REQUEST,
    payload: {},
});

export const LISTS_REQUEST_SUCCESS = 'LISTS_REQUEST_SUCCESS';
export const listsRequestSuccess = lists => ({
    type: LISTS_REQUEST_SUCCESS,
    payload: {
        lists,
    },
});

export const LISTS_REQUEST_FAILURE = 'LISTS_REQUEST_FAILURE';
export const listsRequestFailure = () => ({
    type: LISTS_REQUEST_FAILURE,
    payload: {},
});

//

export const LIST_REQUEST = 'LIST_REQUEST';
export const requestList = listId => ({
    type: LIST_REQUEST,
    payload: {
        listId,
    },
});

export const LIST_REQUEST_SUCCESS = 'LIST_REQUEST_SUCCESS';
export const listRequestSuccess = list => ({
    type: LIST_REQUEST_SUCCESS,
    payload: {
        list,
    },
});

export const LIST_REQUEST_FAILURE = 'LIST_REQUEST_FAILURE';
export const listRequestFailure = () => ({
    type: LIST_REQUEST_FAILURE,
    payload: {},
});

//

export const LIST_CREATE_REQUEST = 'LIST_CREATE_REQUEST';
export const requestCreateList = listType => ({
    type: LIST_CREATE_REQUEST,
    payload: { listType },
});

export const LIST_CREATE_REQUEST_SUCCESS = 'LIST_CREATE_REQUEST_SUCCESS';
export const listCreateRequestSuccess = list => ({
    type: LIST_CREATE_REQUEST_SUCCESS,
    payload: {
        list,
    },
});

export const LIST_CREATE_REQUEST_FAILURE = 'LIST_CREATE_REQUEST_FAILURE';
export const listCreateRequestFailure = () => ({
    type: LIST_CREATE_REQUEST_FAILURE,
    payload: {},
});

export const LIST_IMPORT_REQUEST = 'LIST_IMPORT_REQUEST';
export const requestImportList = bundleUuid => ({
    type: LIST_IMPORT_REQUEST,
    payload: {
        bundleUuid,
    },
});

export const LIST_IMPORT_REQUEST_FAILURE = 'LIST_IMPORT_REQUEST_FAILURE';
export const listImportRequestFailure = () => ({
    type: LIST_IMPORT_REQUEST_FAILURE,
    payload: {},
});

//

export const LIST_UPDATE_REQUEST = 'LIST_UPDATE_REQUEST';
export const requestUpdateList = (now = false) => ({
    type: LIST_UPDATE_REQUEST,
    payload: {
        now, // Debounce override
    },
});

export const LIST_UPDATE_REQUEST_SUCCESS = 'LIST_UPDATE_REQUEST_SUCCESS';
export const listUpdateRequestSuccess = list => ({
    type: LIST_UPDATE_REQUEST_SUCCESS,
    payload: {
        list,
    },
});

export const LIST_UPDATE_REQUEST_FAILURE = 'LIST_UPDATE_REQUEST_FAILURE';
export const listUpdateRequestFailure = () => ({
    type: LIST_UPDATE_REQUEST_FAILURE,
    payload: {},
});

//

export const LIST_SEARCH_REQUEST = 'LIST_SEARCH_REQUEST';
export const requestSearchLists = searchTerm => ({
    type: LIST_SEARCH_REQUEST,
    payload: {
        searchTerm,
    },
});

export const LIST_SEARCH_REQUEST_SUCCESS = 'LIST_SEARCH_REQUEST_SUCCESS';
export const listSearchRequestSuccess = results => ({
    type: LIST_SEARCH_REQUEST_SUCCESS,
    payload: {
        results,
    },
});

export const LIST_SEARCH_REQUEST_FAILURE = 'LIST_SEARCH_REQUEST_FAILURE';
export const listSearchRequestFailure = () => ({
    type: LIST_SEARCH_REQUEST_FAILURE,
    payload: {},
});

// This is a little difficult to name, but the LIST_ARCHIVE_REQUEST* takes care of both
// archiving and unarchiving through use of the 'archive' parameter.

export const LIST_ARCHIVE_REQUEST = 'LIST_ARCHIVE_REQUEST';
export const requestArchiveList = (listId, archive) => ({
    type: LIST_ARCHIVE_REQUEST,
    payload: {
        listId,
        archive,
    },
});

export const LIST_ARCHIVE_REQUEST_SUCCESS = 'LIST_ARCHIVE_REQUEST_SUCCESS';
export const archiveListSuccess = (listId, archive) => ({
    type: LIST_ARCHIVE_REQUEST_SUCCESS,
    payload: {
        listId,
        archive,
    },
});

export const LIST_ARCHIVE_REQUEST_FAILURE = 'LIST_ARCHIVE_REQUEST_FAILURE';
export const archiveListFailure = (listId, archive) => ({
    type: LIST_ARCHIVE_REQUEST_FAILURE,
    payload: {
        listId,
        archive,
    },
});

export const CHANGE_LIST_TAB = 'CHANGE_LIST_TAB';
export const changeListTab = listTab => ({
    type: CHANGE_LIST_TAB,
    payload: {
        listTab,
    },
});
