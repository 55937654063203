import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

export const DraggableContainer = styled.div`
    display: flex;
    height: ${7 * gridSize}px;

    flex-direction: row;
    align-items: center;

    user-select: none;
    margin: 0 0 ${gridSize}px 0;
`;

export const DroppableContainer = styled.div`
    padding: 0;
`;
