import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { gridSize, fontFamilyRetinaNarrow, blue50 } from 'common/pocket-casts-styles';

export const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    width: ${30 * gridSize}px;
    height: 100vh;

    background-color: ${props => props.theme.primaryUi01()};
    border-right: 1px solid ${props => props.theme.primaryUi05()};
`;

export const LogoWrapper = styled.div`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
`;

export const LogoEnvWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: ${3 * gridSize}px 0 0 ${3 * gridSize}px;
`;

export const EnvText = styled.div`
    font-family: ${fontFamilyRetinaNarrow};
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: ${blue50};
    margin-left: 10px;
`;

export const Spaces = styled.div`
    height: ${props => gridSize * (props.n || 1)}px;
`;

export const RoundelLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
`;
