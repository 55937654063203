import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router';
import EditListPage from './EditListPage';
import ListBrowser from './ListBrowser';
import ListAnalyticsPage from './ListAnalyticsPage';

export class Lists extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}/manage/edit/:listId`} component={EditListPage} />
                <Route path={`${match.url}/manage/stats/:listId`} component={ListAnalyticsPage} />
                <Route path={`${match.url}/manage`} component={ListBrowser} />

                <Route render={() => <Redirect to={`${match.url}/manage`} />} />
            </Switch>
        );
    }
}

export default Lists;
