import { combineReducers } from 'redux';

import config from './config/reducer';
import entities from './entities/reducer';
import ui from './ui/reducer';

export default combineReducers({
    config,
    entities,
    ui,
});
