import { map } from 'rxjs/operators';
import {
    postPublish,
    postPublishSchedule,
    postCancelPublishSchedule,
    postCopy,
    postPublishListToRegions,
} from 'app/rest/publish';

export const publishTransformer = data => data;

export const sendPublish = () => postPublish().pipe(map(publishTransformer));

export const publishScheduleTransformer = data => data;

export const sendPublishSchedule = date =>
    postPublishSchedule(date).pipe(map(publishScheduleTransformer));

export const cancelPublishScheduleTransformer = data => data;

export const sendCancelPublishSchedule = () =>
    postCancelPublishSchedule().pipe(map(cancelPublishScheduleTransformer));

export const copyTransformer = data => data;

export const sendCopy = (regionToCopy, toRegions) =>
    postCopy(regionToCopy, toRegions).pipe(map(copyTransformer));

export const publishListToRegionsTransformer = data => data;

export const sendPublishListToRegions = (listId, regions) =>
    postPublishListToRegions(listId, regions).pipe(map(publishListToRegionsTransformer));
