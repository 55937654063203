import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

const lhsWidth = 762;
const popoverWidth = 798;

export const PodcastSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PodcastSearchBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    height: 34px;
    width: 34px;

    align-items: center;
    justify-content: center;

    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
`;

export const PodcastSearchBox = styled.input`
    width: ${lhsWidth}px;
    height: 32px;

    border: 0;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;

    font-size: ${2 * gridSize}px;
    font-weight: 300px;

    &:focus {
        outline: none;
    }
`;

export const PodcastSuggestionBoxMarker = styled.div``;

export const SearchSuggestionsBox = styled.div`
    ${props => (props.visible ? 'display: block' : 'display: none')};
    position: absolute;
    width: ${popoverWidth}px;
    max-height: 400px;

    z-index: 2;

    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: white;
`;

export const SearchErrorWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    justify-content: center;
`;

export const NoResultsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 50px;

    justify-content: center;
    align-items: center;
`;

export const EpisodeSuggestion = styled.div`
    display: flex;
    align-items: baseline;
    border-top: 1px solid ${props => props.theme.primaryUi05()};
    cursor: pointer;
    &:hover {
        background: ${props => props.theme.primaryUi05()};
    }
`;

export const EpisodeTitle = styled.div`
    padding: 15px 20px;
    flex: 1;
`;

export const EpisodeCell = styled.div`
    font-size: 14px;
    color: ${props => props.theme.secondaryText02()};
    padding: 15px 30px 15px 0;
    min-width: 60px;
    text-align: right;
`;

export const LoadingEpisodes = styled.div`
    padding: 50px;
    text-align: center;
    line-height: 3;
    color: ${props => props.theme.secondaryText02()};
`;
