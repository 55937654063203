import React, { Component } from 'react';
import Moment from 'moment';
import Input from 'common/pocket-casts-components/Input';
import { DateRangeInputsWrapper, Dash } from './styled';

class DateRangeInputs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromString: '',
            toString: '',
        };
    }

    fromChanged = event => {
        this.setState({
            fromString: event.target.value,
        });

        const date = Moment(event.target.value, 'MM/DD/YY', true);

        this.props.fromDateChanged(date.isValid() ? date : null);
    };

    toChanged = event => {
        this.setState({
            toString: event.target.value,
        });

        const date = Moment(event.target.value, 'MM/DD/YY', true);

        this.props.toDateChanged(date.isValid() ? date : null);
    };

    render() {
        const { fromString, toString } = this.state;

        return (
            <DateRangeInputsWrapper>
                <Input
                    value={fromString}
                    width={160}
                    placeholder="From (MM/DD/YY)"
                    onChange={this.fromChanged}
                    isValid={Moment(fromString, 'MM/DD/YY', true).isValid() || fromString === ''}
                />
                <Dash>→</Dash>
                <Input
                    width={160}
                    placeholder="To (MM/DD/YY)"
                    value={toString}
                    onChange={this.toChanged}
                    isValid={Moment(toString, 'MM/DD/YY', true).isValid() || toString === ''}
                />
            </DateRangeInputsWrapper>
        );
    }
}

export default DateRangeInputs;
