import React, { Component } from 'react';
import { StyledSpacer } from './styled';

class Spacer extends Component {
    render() {
        return <StyledSpacer n={this.props.n || 1} />;
    }
}

export default Spacer;
