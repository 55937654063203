import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NavigationWithDetailWrapper, NavigationWrapper } from './styled';
import Navigation from './Navigation';
import Detail from './Detail';

export class DiscoverCurator extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
    };

    render() {
        const { match } = this.props;

        return (
            <NavigationWithDetailWrapper>
                <NavigationWrapper>
                    <Navigation />
                </NavigationWrapper>
                <Detail match={match} />
            </NavigationWithDetailWrapper>
        );
    }
}

export default DiscoverCurator;
