import { SINGLE_REGION_MODE } from 'common/feature-flags';
import { REGION_CODE } from '../../../state/constants';

export const NavigationItems = {
    LAYOUTS: {
        id: 1,
        path: SINGLE_REGION_MODE ? `/discover/layouts/${REGION_CODE.global}` : '/discover/layouts',
        text: SINGLE_REGION_MODE ? 'Discover' : 'Region Layouts',
    },
    FEATURED: {
        id: 2,
        path: '/discover/featured',
        text: 'Featured',
        disabled: true,
    },
    LISTS: {
        id: 3,
        path: '/discover/lists',
        text: 'Lists',
    },
};
