import styled from 'styled-components';
import { gridSize } from 'common/pocket-casts-styles';

const searchIconDataUri = height =>
    // eslint-disable-next-line
    `data:image/svg+xml,%3Csvg width='${height / 2}' height='${height /
        2}' opacity='0.4' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.5 16C5.91015 16 3 13.0899 3 9.5C3 5.91015 5.91015 3 9.5 3C13.0899 3 16 5.91015 16 9.5C16 10.9341 15.5356 12.2597 14.7489 13.3347L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L13.3347 14.7489C12.2597 15.5356 10.9341 16 9.5 16ZM9.5 14C11.9853 14 14 11.9853 14 9.5C14 7.01472 11.9853 5 9.5 5C7.01472 5 5 7.01472 5 9.5C5 11.9853 7.01472 14 9.5 14Z' fill='currentColor' /%3E%3C/svg%3E`;
export const SearchBarInput = styled.input`
    appearance: none;
    display: inline-block;

    ${props => (props.width ? `width: ${Math.max(props.width, 140)}px` : '100%')};

    height: ${props => props.height}px;
    min-height: ${props => props.height}px;
    max-height: ${props => props.height}px;

    background-repeat: no-repeat;
    background-position: ${props => props.height / 4}px;
    background-image: url("${props => searchIconDataUri(props.height)}");

    box-sizing: border-box;

    background-color: ${props => props.theme.primaryField02()};
    border: 1px solid ${props => props.theme.primaryField03()};
    border-radius: ${gridSize}px;

    color: ${props => props.theme.primaryText02()};

    font-size: 14px; /* H70 */

    padding-left: ${props => props.height}px;
    padding-right:${2 * gridSize}px;
`;
